import { useLocation } from 'react-router-dom';
import { EventInfo, pushActionLogs } from '~/services/kup/controllers/log';

export default function useBatchLogger() {
  const location = useLocation();
  const { pathname } = location;
  const hash = location.hash.split('?')[0] ?? '';
  const query = location.hash.split('?')[1] ?? '';

  const logAction = (eventInfo: EventInfo) => {
    pushActionLogs({
      eventInfo,
      pathInfo: { pathname, hash, query },
    }).catch((err) => console.error('Log error:', err));
  };

  return { logAction };
}
