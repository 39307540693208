import { RouterProvider } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';

import router from '~/router';
import { theme, GlobalCSS } from '~/theme';

import PWABadge from '~/components/PWABadge';
import { ErrorBoundary } from 'react-error-boundary';
import { SnackBarProvider } from '~/contexts/SnackBarContext.tsx';
import { KeepStateProvider } from '~/contexts/KeepStateContext.tsx';
import ErrorBoundaryFallBack from '~/components/ErrorFallBack/ErrorBoundaryFallBack.tsx';
import * as Sentry from '@sentry/react';

import './i18n';
import '~kup/firebase/analytics';
import './sentry.ts';

function App() {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallBack}
      onError={(e) => {
        Sentry.captureException(e);
      }}
    >
      <ThemeProvider theme={theme}>
        <GlobalCSS />
        <CssBaseline />
        <KeepStateProvider>
          <SnackBarProvider>
            <RouterProvider router={router} />
          </SnackBarProvider>
        </KeepStateProvider>
        <PWABadge />
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
