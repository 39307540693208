import ProductLimitedList from '~/components/ProductLimitedList';
import useProductCollections from '~/hooks/useProductCollections';
import Container from '~/components/Container';
import Typography from '~/components/Typography';
import ChipButtonGroup from '~/components/ChipButtonGroup';
import useProductCollectionsProducts from '~/hooks/useProductCollectionsProducts';
import { Product } from '~/services/kup/models/Product';
import { useState } from 'react';
import { extractProductEventData } from '~/utils/product';
import useBatchLogger from '~/hooks/useBatchLogger';
import { ProductCollection } from '~/services/kup/models/ProductCollection';

export default function MainRankingList() {
  const { logAction } = useBatchLogger();
  const { collectionGroup } = useProductCollections({
    slug: 'gift-recommendations-collection-group',
  });
  const { fetchProductCollection } = useProductCollectionsProducts();
  const [rankingProducts, setRankingProducts] = useState<Product[]>([]);
  const [rankingCollection, setRankingCollection] = useState<ProductCollection | null>(null);
  const buttonInfos = collectionGroup?.collections?.map((collection) => ({
    label: collection.name ?? '',
    key: collection.slug ?? '',
  }));
  const firstActiveRanking = buttonInfos?.[Math.floor(Math.random() * (buttonInfos?.length ?? 0))];

  const handleCollectionSelection = (key: string) => {
    fetchProductCollection(key)
      .then((productCollection) => {
        if (productCollection.collectionItems) {
          const products = productCollection.collectionItems.map((item) => item.product);
          setRankingCollection(productCollection);
          setRankingProducts(products.filter((product) => product !== undefined) as Product[]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleClickProductItem = (product: Product) => {
    logAction({
      eventType: 'CLICK',
      eventTarget: 'HOME_PRODUCT',
      eventCategory: 'product',
      eventData: extractProductEventData(product, {
        type: 'main-ranking',
        slug: rankingCollection?.slug ?? '',
        name: rankingCollection?.name ?? '',
      }),
    });
  };

  const handleLikeProduct = (product: Product) => {
    if (!product.isLiked) {
      logAction({
        eventType: 'CLICK',
        eventTarget: 'HOME_PRODUCT_LIKE',
        eventCategory: 'product',
        eventData: extractProductEventData(product, {
          type: 'main-ranking',
          slug: rankingCollection?.slug ?? '',
          name: rankingCollection?.name ?? '',
        }),
      });
    }
  };

  return (
    <>
      {collectionGroup && (
        <Container px={'20px'}>
          <Typography variant={'h5'}>{collectionGroup.name}</Typography>
          <Container sx={{ my: 1 }}>
            <ChipButtonGroup
              defaultSelectedKey={firstActiveRanking?.key ?? ''}
              buttonInfos={buttonInfos ?? []}
              onChange={handleCollectionSelection}
            />
          </Container>
        </Container>
      )}
      {rankingProducts && (
        <ProductLimitedList
          products={rankingProducts}
          row={4}
          column={2}
          numbering
          onClickProductItem={(product) => handleClickProductItem(product)}
          onLikeProductItem={(product) => handleLikeProduct(product)}
        />
      )}
    </>
  );
}
