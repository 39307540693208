import { SvgIcon, SvgIconProps } from '@mui/material';

interface CustomIconProps extends SvgIconProps {
  size: number | string;
}

export default function IconBorderedEmptyStar({ size, ...props }: CustomIconProps) {
  return (
    <SvgIcon {...props} sx={{ width: size, height: size, ...props.sx }}>
      <svg viewBox="0 0 35 33" fill="none">
        <path
          d="M15.7065 1.63397C16.4402 0.14748 18.5598 0.14748 19.2935 1.63397L22.9125 8.96695C23.2038 9.55723 23.767 9.96637 24.4184 10.061L32.5108 11.2369C34.1512 11.4753 34.8063 13.4912 33.6192 14.6483L27.7635 20.3562C27.2921 20.8157 27.077 21.4777 27.1883 22.1265L28.5707 30.1862C28.8509 31.82 27.136 33.066 25.6688 32.2946L18.4307 28.4893C17.848 28.183 17.152 28.183 16.5693 28.4893L9.33124 32.2946C7.86398 33.066 6.14912 31.82 6.42934 30.1862L7.81169 22.1265C7.92297 21.4777 7.70787 20.8157 7.2365 20.3562L1.38077 14.6483C0.193735 13.4912 0.848755 11.4753 2.4892 11.2369L10.5816 10.061C11.233 9.96637 11.7962 9.55723 12.0875 8.96695L15.7065 1.63397Z"
          fill="#A2B6D3"
          fill-opacity="0.1"
        />
        <path
          d="M16.1549 1.85525C16.7051 0.740386 18.2949 0.740386 18.8451 1.85525L22.4641 9.18823C22.8283 9.92609 23.5322 10.4375 24.3465 10.5558L32.4389 11.7317C33.6692 11.9105 34.1605 13.4225 33.2702 14.2903L27.4145 19.9982C26.8253 20.5725 26.5564 21.4 26.6955 22.211L28.0779 30.2707C28.288 31.4961 27.0019 32.4305 25.9014 31.852L18.6634 28.0467C17.935 27.6638 17.065 27.6638 16.3366 28.0467L9.09857 31.852C7.99813 32.4305 6.71198 31.4961 6.92214 30.2707L8.30449 22.211C8.44359 21.4 8.17472 20.5725 7.5855 19.9982L1.72977 14.2903C0.8395 13.4225 1.33076 11.9105 2.56109 11.7317L10.6535 10.5558C11.4678 10.4375 12.1717 9.92609 12.5359 9.18823L16.1549 1.85525Z"
          stroke="black"
          stroke-opacity="0.06"
        />
      </svg>
    </SvgIcon>
  );
}
