import { useEffect, useState } from 'react';
import { getProductBrands } from '~/services/kup/controllers/productBrand';
import { ProductBrand } from '~/services/kup/models/ProductBrand';

type UseProductBrands = {
  productBrands: ProductBrand[];
  loading: boolean;
};

export default function useProductBrands(size: number = 100): UseProductBrands {
  const [productBrands, setProductBrands] = useState<ProductBrand[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getProductBrands({ pageSize: size, page: 1 })
      .then((productBrands) => {
        setProductBrands(productBrands.splice(0, size).sort(() => 0.5 - Math.random()));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { productBrands, loading };
}
