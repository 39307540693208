import { Product, ProductResponse } from './Product';

export interface ProductCollectionGroupResponse {
  slug?: string;
  name?: string;
  description?: string;
  collections?: ProductCollectionResponse[];
}

export interface ProductCollectionResponse {
  slug?: string;
  collectionItems?: ProductCollectionItemResponse[];
  group?: ProductCollectionGroupResponse;
  name?: string;
  description?: string;
  orderWeight?: number;
}

export interface ProductCollectionItemResponse {
  product?: ProductResponse;
  collectionId?: string;
  productId?: string;
  orderWeight?: number;
}

export class ProductCollectionGroup {
  slug?: string;
  name?: string;
  description?: string;
  collections?: ProductCollection[];

  constructor(raw: ProductCollectionGroupResponse) {
    this.slug = raw.slug;
    this.name = raw.name;
    this.description = raw.description;
    this.collections = raw.collections?.map((c) => new ProductCollection(c));
  }
}

export class ProductCollection {
  slug?: string;
  collectionItems?: ProductCollectionItem[];
  group?: ProductCollectionGroup;
  name?: string;
  description?: string;

  constructor(raw: ProductCollectionResponse) {
    this.slug = raw.slug;
    this.collectionItems = raw.collectionItems?.map((c) => new ProductCollectionItem(c));
    this.group = raw.group ? new ProductCollectionGroup(raw.group) : undefined;
    this.name = raw.name;
    this.description = raw.description;
  }
}

export class ProductCollectionItem {
  product?: Product;
  collectionId?: string;
  productId?: string;
  orderWeight?: number;

  constructor(raw: ProductCollectionItemResponse) {
    this.product = raw.product ? new Product(raw.product) : undefined;
    this.collectionId = raw.collectionId;
    this.productId = raw.productId;
  }
}
