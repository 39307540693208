import Stack, { StackProps } from './Stack';
import { default as Typography, TypographyProps } from './Typography';

type HeadlineSize = 'large' | 'medium' | 'small';
type HeadlineTextAlign = 'left' | 'center' | 'right';
type HeadlineComponents = 'headline' | 'subHeadline' | 'description';
export type HeadlineProps = {
  strike?: boolean;
  disableStrike?: boolean;
  strikeSubHeadline?: boolean;
  strikeHeadline?: boolean;
  strikeDescription?: boolean;
  size?: HeadlineSize;
  textAlign?: HeadlineTextAlign;
  headline: string;
  subHeadline?: string;
  description?: string;
  caution?: string;
  headlineProps?: TypographyProps;
  subHeadlineProps?: TypographyProps;
  descriptionProps?: TypographyProps;
  onHeadlineClick?: () => void;
  onSubHeadlineClick?: () => void;
} & StackProps;

const defaultHeadlinePropsForSize: {
  [type in HeadlineSize]: { [headline in HeadlineComponents]: TypographyProps };
} = {
  large: {
    headline: {
      variant: 'TitleM_B',
    },
    subHeadline: {
      color: 'typography.secondary',
      variant: 'FootNote',
    },
    description: {
      color: 'typography.secondary',
      variant: 'FootNote',
    },
  },
  medium: {
    headline: {
      variant: 'BaseL_B',
    },
    subHeadline: {
      color: 'typography.secondary',
      variant: 'FootNote',
    },
    description: {
      color: 'typography.secondary',
      variant: 'FootNote',
    },
  },
  small: {
    headline: {
      variant: 'BaseS_B',
    },
    subHeadline: {
      color: 'typography.secondary',
      variant: 'Caption',
    },
    description: {
      color: 'typography.secondary',
      variant: 'Caption',
    },
  },
};

export default function Headline(props: HeadlineProps) {
  const {
    strike = false,
    strikeSubHeadline = false,
    strikeHeadline = false,
    strikeDescription = false,
    headline,
    subHeadline,
    description,
    caution,
    headlineProps = {},
    textAlign = 'left',
    size = 'small',
    subHeadlineProps = {},
    descriptionProps = {},
    onHeadlineClick = () => {},
    onSubHeadlineClick = () => {},
    ...stackProps
  } = props;
  const defaultHeadlineProps = defaultHeadlinePropsForSize[size];

  return (
    <Stack column {...stackProps}>
      {subHeadline && (
        <Typography
          {...defaultHeadlineProps.subHeadline}
          textAlign={textAlign}
          {...subHeadlineProps}
          strike={strike || strikeSubHeadline}
          onClick={onSubHeadlineClick}
        >
          {subHeadline}
        </Typography>
      )}
      <Typography
        {...defaultHeadlineProps.headline}
        textAlign={textAlign}
        {...headlineProps}
        strike={strike || strikeHeadline}
        onClick={onHeadlineClick}
      >
        {headline}
      </Typography>
      <Typography
        {...defaultHeadlineProps.description}
        textAlign={textAlign}
        {...descriptionProps}
        strike={strike || strikeDescription}
      >
        {description}
      </Typography>
      {caution && (
        <Typography
          {...defaultHeadlineProps.description}
          textAlign={textAlign}
          {...descriptionProps}
          strike={strike || strikeSubHeadline}
          color={'error'}
        >
          {caution}
        </Typography>
      )}
    </Stack>
  );
}
