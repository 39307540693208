import { useLayoutEffect, useState } from 'react';
import getTimeRemains from '~/utils/getTimeRemains.ts';

type TimeRemains = {
  hours: number;
  minutes: number;
  seconds: number;
  text: string;
};

type Remains = {
  cutOff: Date;
  remains: TimeRemains;
};

type UseTimeRemains = Remains;

type TimeRemainsProps = {
  targetDate: Date;
};

const calRemains = (cutOff: Date): Remains => {
  const { hours, seconds, minutes } = getTimeRemains(cutOff);
  return {
    cutOff: cutOff,
    remains: {
      hours,
      minutes,
      seconds,
      text: `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
    },
  };
};

export default function useTimeRemains(props: TimeRemainsProps): UseTimeRemains {
  const { targetDate } = props;
  const [remains, setRemains] = useState<Remains>(calRemains(targetDate));

  useLayoutEffect(() => {
    const interval = () => {
      setRemains(calRemains(targetDate));
    };

    const intervalId: ReturnType<typeof setInterval> = setInterval(interval, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [targetDate.getTime(), setRemains]);

  return remains;
}
