import { useLocation, useNavigate } from 'react-router-dom';
import Page from '~/components/Page';
import Container from '~/components/Container.tsx';
import { useProductCategoryTree } from '~/pages/shop/hooks/useProductCategories.ts';
import Tabs from '~/components/Tabs.tsx';
import ProductList from '~/components/ProductList.tsx';
import Typography from '~/components/Typography.tsx';
import useProductsKeepState from '~/hooks/useProductsKeepState.ts';
import { PRODUCT_ORDER_BY } from '../constants';
import i18n from '~/i18n';
import { useEffect, useState } from 'react';
import Select from '~/components/Select';
import { styled } from '@mui/material/styles';
import Stack from '~/components/Stack';
import { SelectChangeEvent } from '@mui/material';
import useBatchLogger from '~/hooks/useBatchLogger';
import { extractProductEventData } from '~/utils/product';
import { Product } from '~/services/kup/models/Product';
import BasketIconWithCount from '~/components/BasketIconWithCount.tsx';
import LikeIconWithCount from '~/components/LikeIconWithCount.tsx';

const StyledSelect = styled(Select)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});

export default function ShopCategoryPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { logAction } = useBatchLogger();
  const mainCategorySlug = location.hash.split('/').at(-2) ?? '';
  const subCategorySlug = location.hash.split('/').at(-1)?.split('?')[0] ?? '';

  const { categories } = useProductCategoryTree();

  const query = location.hash.split('?').at(-1);
  const orderBy = query?.split('&')[0]?.split('=')[1] ?? undefined;

  const [selectedCategorySlug, setSelectedCategorySlug] = useState(subCategorySlug);
  const [selectedOrderBy, setSelectedOrderBy] = useState(orderBy ?? 'recommendation');

  const { products, total, page, hasNextPage, next } = useProductsKeepState(
    `category:${mainCategorySlug}:${subCategorySlug}:${selectedOrderBy}`,
    {
      category: subCategorySlug === 'all' ? mainCategorySlug : subCategorySlug || 0,
      orderBy: selectedOrderBy?.split(':')[0] ?? undefined,
      orderMethod: selectedOrderBy?.split(':')[1] ?? undefined,
    },
    { pageSize: 8 }
  );
  const mainCategory = categories.find((c) => c.slug === mainCategorySlug);

  useEffect(() => {
    if (selectedCategorySlug !== subCategorySlug || selectedOrderBy !== orderBy) {
      const navigatePath =
        `/shop#category/${mainCategorySlug}/${selectedCategorySlug}` +
        (selectedOrderBy ? `?orderBy=${selectedOrderBy}` : '');
      navigate(navigatePath, { replace: true });
    }
  }, [navigate, mainCategorySlug, selectedCategorySlug, subCategorySlug, selectedOrderBy, orderBy]);

  const loadNext = () => {
    if (hasNextPage) {
      logAction({
        eventType: 'LOAD',
        eventTarget: 'CATEGORY_PRODUCT_LIST',
        eventCategory: 'product',
        eventData: {
          page: page + 1,
          orderBy: selectedOrderBy,
        },
      });
      next();
    }
  };

  const handleClickProductItem = (product: Product) => {
    logAction({
      eventType: 'CLICK',
      eventTarget: 'CATEGORY_PRODUCT',
      eventCategory: 'product',
      eventData: extractProductEventData(product, { type: 'category' }),
    });
  };

  const handleLikeProduct = (product: Product) => {
    if (!product.isLiked) {
      logAction({
        eventType: 'CLICK',
        eventTarget: 'CATEGORY_PRODUCT_LIKE',
        eventCategory: 'product',
        eventData: extractProductEventData(product, { type: 'category' }),
      });
    }
  };

  return (
    <Page
      type={'page'}
      name={mainCategory?.name}
      navigationBarProps={{
        trail: (
          <Stack height={'20px'} flexDirection={'row'} gap={'12px'}>
            <BasketIconWithCount />
            <LikeIconWithCount />
          </Stack>
        ),
      }}
    >
      <Container>
        <Container pb={'4px'}>
          <Tabs
            scrollable
            value={selectedCategorySlug}
            onChange={(_, tab) => setSelectedCategorySlug(tab)}
            sx={{
              px: '20px',
            }}
            tabs={[
              { label: i18n.t('common-all'), value: 'all' },
              ...(mainCategory?.children ?? []).map((category) => ({
                label: category.name,
                value: category.slug,
              })),
            ]}
          ></Tabs>
        </Container>
        <Stack pb={'4px'} row justifyContent={'flex-end'}>
          <StyledSelect
            menus={PRODUCT_ORDER_BY.map((orderBy) => ({
              label: i18n.t(orderBy.label),
              value: orderBy.value,
            }))}
            value={PRODUCT_ORDER_BY.find((orderBy) => orderBy.value === selectedOrderBy)?.value}
            size={'small'}
            sx={(theme) => ({
              color: theme.palette.text.primary,
            })}
            onChange={(e: SelectChangeEvent<unknown>) => {
              setSelectedOrderBy(e.target.value as string);
            }}
          />
        </Stack>
        <Container>
          <ProductList
            title={<Typography variant={'FootNote'}>{total} products</Typography>}
            products={products}
            type={'pair'}
            onInfiniteScroll={loadNext}
            onClickProductItem={handleClickProductItem}
            onLikeProductItem={handleLikeProduct}
          />
        </Container>
      </Container>
    </Page>
  );
}
