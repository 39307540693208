import { useNavigate } from 'react-router-dom';
import IconButton from '~/components/IconButton.tsx';
import useKupStore from '~kup/store';

export default function LikeIconWithCount({
  width = '24px',
  touchArea = '16px',
  color,
  displayOnlyNeeded,
}: {
  width?: string;
  touchArea?: string;
  color?: string;
  displayOnlyNeeded?: boolean;
}) {
  const navigate = useNavigate();
  const { brandLikeCount, productLikeCount } = useKupStore((state) => ({
    brandLikeCount: Object.keys(state.brandLikeDict).length,
    productLikeCount: Object.keys(state.productLikeDict).length,
  }));
  const likeItemCount: number | undefined =
    (productLikeCount ?? 0) + (brandLikeCount ?? 0) || undefined;
  const handleNavigate = () => {
    navigate('/shop/likes?tab=product');
  };

  if (displayOnlyNeeded && !likeItemCount) return null;

  return (
    <IconButton
      badge={likeItemCount}
      badgeColor={'error'}
      touchArea={touchArea}
      iconProps={{ width }}
      onClick={handleNavigate}
      sx={(theme) => ({ color: color ?? theme.palette.typography.tertiary })}
    >
      like
    </IconButton>
  );
}
