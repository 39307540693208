import { Contact, DeliveryAddress } from '~kup/models/types.ts';
import {
  OptionStock,
  OptionStockResponse,
  ProductOptionItem,
  ProductOptionItemResponse,
  ProductResponse,
} from '~kup/models/Product.ts';
import { IIssuedCoupon } from '~kup/models/Coupon.ts';
import { ORDER_STATUS, PAYMENT_STATUS } from '~/pages/shop/constants';

export interface TransactionResponse {
  id: string;
  status: string;
  amount: number;
  paymentMethod: string;
  confirmOfficerId: string;
  confirmOfficerName: string;
  transactionStartedAt: Date;
  transactionEndedAt: Date;
  appliedCoupon: IIssuedCoupon | null;
}

export interface OrderItemResponse {
  id: string;
  productId: ProductResponse['id'];
  productName: ProductResponse['name'];
  productBrand: string;
  productImageUrls: ProductResponse['imageUrls'];
  stock: OptionStockResponse;
  quantity: number;
  isInPromotion?: boolean;
  isReviewed?: boolean;
  // TODO: 추후 리펙토링 필요 (API 응답 형식 일관성이 떨어지는 문제로 인한 분기 처리)
  optionItemSelections?: ProductOptionItemResponse[];
  selectedOptions?: ProductOptionItemResponse[];
}

export interface OrderPostData {
  orderId?: string;
  customerEmail: string;
  customerName: string;
  inputAddress: string;
  deliveryAddress: DeliveryAddress;
  contact: Contact;
  paymentMethod: string;
  orderItems: OrderItemResponse[];
  orderAmount: number;
  deliveryFee: number;
  pgTransaction?: IIamportResponse;
  appliedCoupon: IIssuedCoupon | null;
}

export interface MerchantOrderIdResponse {
  orderId: string;
  merchantOrderId: string;
}

export interface OrderResponse extends OrderPostData {
  id: string;
  orderNumber: number;
  orderStatus: string;
  transaction: TransactionResponse;
}

export interface OrderHistoryResponse {
  orderId: string;
  orderedAt: Date;
  orderStatus: string;
  orderItems: OrderItemResponse[];
  orderAmount: number;
}

export class Order {
  id: string;
  orderNumber: number;
  orderStatus: string;
  customerEmail: string;
  customerName: string;
  inputAddress: string;
  deliveryAddress: DeliveryAddress;
  contact: Contact;
  paymentMethod: string;
  orderItems: OrderItem[];
  deliveryFee: number;
  transaction: Transaction;

  constructor(raw: OrderResponse) {
    this.id = raw.id;
    this.orderNumber = raw.orderNumber;
    this.customerEmail = raw.customerEmail;
    this.customerName = raw.customerName;
    this.inputAddress = raw.inputAddress;
    this.deliveryAddress = raw.deliveryAddress;
    this.contact = raw.contact;
    this.paymentMethod = raw.paymentMethod;
    this.orderStatus = raw.orderStatus;
    this.orderItems = raw.orderItems.map((oi) => new OrderItem(oi));
    this.transaction = new Transaction(raw.transaction);
    this.deliveryFee = raw.deliveryFee;
  }

  get orderAmount(): number {
    return this.orderItems
      .map((oi) => oi.quantity * (oi.stock?.price ?? 0))
      .reduce((a, b) => a + b, 0);
  }

  get totalAmount(): number {
    return this.orderAmount + this.deliveryFee;
  }
}

export class Transaction {
  id: string;
  status: string;
  amount: number;
  paymentMethod: string;
  confirmOfficerId: string;
  confirmOfficerName: string;
  transactionStartedAt: Date;
  transactionEndedAt: Date;
  appliedCoupon: IIssuedCoupon | null;

  constructor(raw: TransactionResponse) {
    this.id = raw.id;
    this.status = raw.status;
    this.amount = raw.amount;
    this.paymentMethod = raw.paymentMethod;
    this.confirmOfficerId = raw.confirmOfficerId;
    this.confirmOfficerName = raw.confirmOfficerName;
    this.transactionStartedAt = raw.transactionStartedAt;
    this.transactionEndedAt = raw.transactionEndedAt;
    this.appliedCoupon = raw.appliedCoupon;
  }
}

export class OrderItem {
  id: string;
  productId: string;
  productName: string;
  productBrand: string;
  productImageUrls: string[];
  optionItemSelections: ProductOptionItem[];
  quantity: number;
  isInPromotion?: boolean;
  isReviewed?: boolean;
  stock?: OptionStock;

  constructor(raw: OrderItemResponse) {
    this.id = raw.id;
    this.productId = raw.productId;
    this.productName = raw.productName;
    this.productBrand = raw.productBrand;
    this.productImageUrls = raw.productImageUrls;
    this.quantity = raw.quantity;
    this.isInPromotion = raw.isInPromotion;
    this.isReviewed = raw.isReviewed;
    if (raw.stock) this.stock = new OptionStock(raw.stock);

    // TODO: 추후 리펙토링 필요 (API 응답 형식 일관성이 떨어지는 문제로 인한 분기 처리)
    const optionItems = raw.optionItemSelections || raw.selectedOptions;
    this.optionItemSelections = optionItems?.map((s) => new ProductOptionItem(s)) ?? [];
  }
}

export class OrderHistory {
  orderId: string;
  orderedAt: Date;
  orderStatus: string;
  orderItems: OrderItem[];
  orderAmount: number;

  constructor(raw: OrderHistoryResponse) {
    this.orderId = raw.orderId;
    this.orderedAt = raw.orderedAt;
    this.orderStatus = raw.orderStatus;
    this.orderAmount = raw.orderAmount;
    this.orderItems = raw.orderItems.map((oi) => new OrderItem(oi));
  }
}

export interface OrderStatusResponse {
  id: string;
  orderStatus: ORDER_STATUS;
  paymentStatus: PAYMENT_STATUS;
}

export class OrderStatus {
  id: string;
  orderStatus: ORDER_STATUS;
  paymentStatus: PAYMENT_STATUS;

  constructor(raw: OrderStatusResponse) {
    this.id = raw.id;
    this.orderStatus = raw.orderStatus;
    this.paymentStatus = raw.paymentStatus;
  }
}
