import ModalPopup from '~/components/ModalPopup.tsx';
import Typography from '~/components/Typography.tsx';
import useKintStore from '~kint/store';
import Container from '~/components/Container.tsx';
import ValueList from '~/components/ValueList.tsx';
import Button from '~/components/Button.tsx';
import { useTranslation } from 'react-i18next';

export type ReservationPopupProps = {
  onClose: () => void;
  open: boolean;
};

export default function ReservationPopup(props: ReservationPopupProps) {
  const { open, onClose } = props;
  const { i18n } = useTranslation();
  const { reservation } = useKintStore((state) => ({
    reservation: state.reservation,
  }));

  const handleCheckInOther = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <ModalPopup
      open={open}
      onClose={onClose}
      bottomPosition={'sticky'}
      fixedBottom={
        <Container
          bottom
          background={'paper'}
          py={'12px'}
          px={'20px'}
          stackProps={{
            gap: '8px',
          }}
        >
          <Button fullWidth size={'small'} color={'fill'} onClick={onClose}>
            {
              i18n.t('home-main-confirm') // Confirm
            }
          </Button>
          <Button variant={'text'} size={'small'} onClick={handleCheckInOther} color={'secondary'}>
            {
              i18n.t('home-main-checkDifferentReservation') // Check in a different reservation
            }
          </Button>
        </Container>
      }
    >
      <Container py={'16px'} px={'20px'}>
        <Typography variant={'TitleM_B'}>
          {
            i18n.t('home-main-myBooking') // My Booking
          }
        </Typography>
        <Typography variant={'BaseS'} color={'typography.secondary'} component={'p'}>
          {
            i18n.t('home-main-reservationByClient', { clientName: reservation?.clientName }) // A reservation made by {reservation?.clientName}
          }
        </Typography>
      </Container>
      <Container py={'16px'} px={'20px'}>
        <ValueList
          pairs={[
            {
              key: 'date',
              pair: [i18n.t('home-main-date'), reservation?.date],
            },
            {
              key: 'tour',
              pair: [i18n.t('home-main-tour'), reservation?.product],
            },
            {
              key: 'name of lead',
              pair: [i18n.t('home-main-name'), reservation?.clientName],
            },
            {
              key: 'travelers',
              pair: [
                i18n.t('home-main-travelers'),
                [
                  [i18n.t('home-main-adult'), reservation?.adult],
                  [i18n.t('home-main-kid'), reservation?.kid],
                  [i18n.t('home-main-infant'), reservation?.infant],
                ]
                  .filter(([, count]) => !!count)
                  .map(([label, count]) => `${label} ${count}`)
                  .join(' / '),
              ],
            },
            {
              key: 'pickup',
              pair: [
                i18n.t('home-main-pickup'),
                reservation?.pickupInfo?.en?.toUpperCase() ??
                  reservation?.pickupPlace?.toUpperCase() ??
                  '',
              ],
            },
            {
              key: 'email',
              pair: [i18n.t('home-main-email'), reservation?.email?.toUpperCase()],
            },
            {
              key: 'Platform',
              pair: [i18n.t('home-main-platform'), reservation?.agency?.toUpperCase()],
            },
            {
              key: 'ID',
              pair: [i18n.t('home-main-id'), reservation?.agencyCode?.toUpperCase()],
            },
          ]}
        />
      </Container>
    </ModalPopup>
  );
}
