import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import Page from '~/components/Page';
import Spotlight from '~/components/Spotlight.tsx';
import Button from '~/components/Button.tsx';
import Container from '~/components/Container.tsx';
import Icon from '~/components/Icon';
import { PAYMENT_METHOD, PAYMENT_STATUS } from '~/pages/shop/constants';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { getOrderStatus } from '~/services/kup/controllers/order';
import useSyncShopping from '~/hooks/useSyncShopping.ts';

export default function ShopOrderTransactionPage() {
  useSyncShopping();

  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const [remainTime, setRemainTime] = useState<number>(3);
  const orderId = searchParams.get('orderId');
  const paymentMethod = searchParams.get('paymentMethod');
  const orderDetailLink = `/shop/orders/${orderId}`;
  const orderFailedLink = `/shop/order/fail`;

  const guidanceMessages = {
    [PAYMENT_METHOD[0].value]: {
      message: i18n.t('shop-order-cardPaymentPage'), // Card payment page will show up
      trail: null,
    },
    [PAYMENT_METHOD[1].value]: {
      message: 'Alipay payment page will show up',
      trail: <Button>Request payment</Button>,
    },
  };

  const checkOrderStatus = (orderId: string) => {
    getOrderStatus(orderId).then((orderStatus) => {
      const paymentStatus = orderStatus?.paymentStatus ?? null;
      if (paymentStatus === PAYMENT_STATUS.COMPLETED) {
        // 주문 확인 중(결제 성공)인 경우, 주문 상세 페이지로 이동
        navigate(orderDetailLink, { replace: true });
      } else if (paymentStatus === PAYMENT_STATUS.FAILED) {
        // 결제 실패인 경우, 결제 실패 페이지로 이동
        navigate(orderFailedLink, { replace: true });
      }
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRemainTime((prevState) => prevState - 1);
      if (orderId) {
        checkOrderStatus(orderId);
      }
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [remainTime]);

  if (!orderId) return <Navigate to={'/shop/orders'} replace />;
  // if (remainTime <= 0) return <Navigate to={orderDetailLink} replace />;

  return (
    <Page
      type={'modal'}
      name={
        i18n.t('shop-order-order') // Order
      }
      backgroundColor={'paper'}
      // fixedBottomPosition={'fixed'}
      // fixedBottom={
      //   <Container bottom background={'paper'} sx={() => ({ zIndex: 1 })}>
      //     <Container pb={'12px'} px={'20px'}>
      //       <Button
      //         fullWidth
      //         variant={'contained'}
      //         onClick={() => {
      //           navigate(orderDetailLink, { replace: true });
      //         }}
      //       >
      //         {
      //           i18n.t('shop-order-goToOrderDetail', { remainTime }) // Go to Order Detail ({remainTime}s)
      //         }
      //       </Button>
      //     </Container>
      //   </Container>
      // }
    >
      <Spotlight
        textAlign={'center'}
        lead={
          <Icon
            type={'circle'}
            size={'40px'}
            width={'48px'}
            backgroundColor={'typography.tertiary'}
          >
            check
          </Icon>
        }
        headline={
          i18n.t('shop-order-orderProcessed') // Your order has been processed
        }
        description={guidanceMessages[paymentMethod ?? ''].message}
        descriptionProps={{
          whiteSpace: 'pre-wrap',
        }}
        trail={guidanceMessages[paymentMethod ?? ''].trail}
      />
      <Container
        sx={{
          zIndex: 9999,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color="inherit" size={120} />
      </Container>
    </Page>
  );
}
