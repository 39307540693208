import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import useProductCategories from '~/pages/shop/hooks/useProductCategories';
import styled from '~/components/styled';
import Container from '~/components/Container';
import Page from '~/components/Page.tsx';
import PromotionSection from '~/components/PromotionSection.tsx';
import BaseLayout from '~/layouts/BaseLayout.tsx';
import OrderPopup from '~/pages/shop/main/components/OrderPopup.tsx';
import useKupStore from '~kup/store';
import CommonFooter from '~/components/CommonFooter.tsx';
import Divider from '~/components/Divider.tsx';
import Logo from '~/components/Logo';
import ImageView from '~/components/ImageView.tsx';
import usePromotions from '~/hooks/usePromotions.ts';
import { useTranslation } from 'react-i18next';
import MustHaveProducts from './MustHaveProducts.tsx';
import IconButtonMenu from '~/components/ImageButtonMenu.tsx';
import Grid from '@mui/material/Grid';
import IconMenu from '~/components/IconMenu.tsx';
import useBatchLogger from '~/hooks/useBatchLogger';
import OnTourCouponSection from '~/partials/OnTourCouponSection.tsx';
import BrandCollection from '~/pages/shop/main/components/BrandCollection.tsx';
import Stack from '~/components/Stack.tsx';
import IconButton from '~/components/IconButton.tsx';
import { Skeleton } from '@mui/material';
import BasketIconWithCount from '~/components/BasketIconWithCount.tsx';
import LikeIconWithCount from '~/components/LikeIconWithCount.tsx';
import useSyncShopping from '~/hooks/useSyncShopping.ts';

const GradientContainer = styled(Container)({
  background:
    'linear-gradient(180deg, rgba(227,238,255,0.15) 0%, #E3EEFF 35%, #E3EEFF 75%, #FFF 100%)',
});

export default function ShopMainPage() {
  useSyncShopping();

  const location = useLocation();
  const navigate = useNavigate();
  const { logAction } = useBatchLogger();
  const { categories } = useProductCategories({ parentId: 'none' });
  const { checkOrderPopup, setCheckOrderPopup } = useKupStore((state) => ({
    checkOrderPopup: state.checkOrderPopup,
    setCheckOrderPopup: state.setCheckOrderPopup,
  }));
  const { i18n } = useTranslation();
  const { promotions } = usePromotions({ isHighlight: true });

  const handleMoveToMyOrders = () => {
    navigate('/shop/orders');
  };
  if (location.pathname.split('/').length > 2) {
    return (
      <BaseLayout>
        <Outlet />
      </BaseLayout>
    );
  }

  let categorySection = (
    <Grid container spacing={2} px={'36px'}>
      {new Array(8).fill(0).map((_, idx) => (
        <Grid key={'skeleton-' + idx} item xs={3}>
          <Stack gap={'4px'}>
            <Skeleton variant={'rectangular'} height={'56px'} width={'100%'} />
            <Skeleton variant={'rectangular'} height={'22px'} width={'100%'} />
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
  if (categories && categories.length > 0) {
    categorySection = (
      <Grid container rowGap={'16px'} px={'20px'}>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
          <IconMenu
            key="all"
            icon="category-all"
            label={i18n.t('common-all')}
            width={'40px'}
            height={'56px'}
            to={`/shop/category-nav`}
          />
        </Grid>
        {[...categories]
          .filter((c) => c.slug !== 'other') //TODO: 추후 카테고리 설정 값에 의한 처리로 변경 필요
          .map(({ slug, name, iconUrl }) => {
            return (
              <Grid key={slug} item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                <IconButtonMenu
                  key={slug}
                  imageUrl={iconUrl}
                  label={name}
                  to={`/shop#category/${slug}/all`}
                  onClick={() => {
                    logAction({
                      eventType: 'CLICK',
                      eventTarget: 'SHOP_CATEGORY',
                      eventCategory: 'category',
                      eventData: { slug, name },
                    });
                    navigate(`/shop#category/${slug}/all`);
                  }}
                />
              </Grid>
            );
          })}
      </Grid>
    );
  }

  return (
    <>
      <Page
        type={'main'}
        navigationBarProps={{
          lead: (
            <Logo color={'typography.tertiary'} height={'20px'}>
              shop
            </Logo>
          ),
          trail: (
            <Stack height={'20px'} flexDirection={'row'} gap={'12px'}>
              <BasketIconWithCount />
              <LikeIconWithCount />
              <IconButton
                touchArea={'16px'}
                iconProps={{
                  width: '24px',
                }}
                sx={(theme) => ({
                  color: theme.palette.typography.tertiary,
                })}
                onClick={handleMoveToMyOrders}
              >
                my
              </IconButton>
            </Stack>
          ),
          sx: (theme) => ({
            position: 'sticky',
            top: 0,
            left: 0,
            right: 0,
            background: theme.palette.background.paper,
            borderRadius: 0,
          }),
        }}
      >
        <Container px={'20px'}>
          <Container pt={'12px'}>
            <ImageView src={'/best_price_guarantee.png'} width={'100%'} objectFit={'contain'} />
          </Container>
        </Container>

        <Container
          stackProps={{
            column: true,
          }}
        >
          <Container>
            <Container py={'20px'}>{categorySection}</Container>
          </Container>
          <GradientContainer>
            <OnTourCouponSection />
          </GradientContainer>
          <Container>
            <BrandCollection />
          </Container>
          {(promotions ?? []).map((promotion) => (
            <Container>
              <PromotionSection key={promotion.id} promotion={promotion} />
            </Container>
          ))}
          <Container py={'12px'}>
            <MustHaveProducts />
          </Container>
          {/*<ProductList type={"horizontal"} title={"Don't miss this product!"} products={products}/>*/}
        </Container>
        <Divider type={'box'} color={'fill'} />
        <CommonFooter />
      </Page>
      <OrderPopup open={!checkOrderPopup} onClose={() => setCheckOrderPopup(true)} />
    </>
  );
}
