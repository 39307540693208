import Page from '~/components/Page';
import Stack from '~/components/Stack';
import { useTranslation } from 'react-i18next';
import useProductReview from '~/hooks/useProductReview';
import { useEffect, useState } from 'react';
import Typography from '~/components/Typography';
import Container from '~/components/Container';
import ImageView from '~/components/ImageView';
import Rating from '@mui/material/Rating';
import dayjs from 'dayjs';
import Button from '~/components/Button';
import { Grid } from '@mui/material';
import { ProductReview } from '~/services/kup/models/ProductReview';
import { useNavigate } from 'react-router-dom';
import { deleteProductReview } from '~/services/kup/controllers/productReview';
import Dialog from '~/components/Dialog';

export default function MyReviewPage() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { myProductReviews, fetchMyReviews } = useProductReview();
  const [deleteReviewConfirmPopoverOpen, setDeleteReviewConfirmPopoverOpen] = useState(false);
  const [deleteReviewId, setDeleteReviewId] = useState<string | null>(null);
  useEffect(() => {
    fetchMyReviews();
  }, []);

  const handleModifyReview = (review: ProductReview) => {
    navigate(`/shop/product-review/rating`, {
      state: { review, orderId: review.orderId },
    });
  };

  const handleDeleteReview = async () => {
    setDeleteReviewConfirmPopoverOpen(false);
    await deleteProductReview(deleteReviewId!);
    await fetchMyReviews();
  };

  return (
    <Page
      type={'page'}
      name={i18n.t('common-my-review')}
      onBack={() => {
        navigate('/shop/orders');
        return false;
      }}
    >
      <Stack alignItems={'center'} gap={'10px'} px={'20px'}>
        {myProductReviews.map((review) => (
          <Container key={review.id} sx={{ py: '8px' }}>
            <Stack direction={'row'} alignItems={'center'} gap={'14px'} py={'12px'}>
              {review.images?.[0] && (
                <ImageView
                  borderRadius={'6px'}
                  width={'60px'}
                  height={'60px'}
                  src={review.images?.[0]}
                />
              )}
              <Stack>
                {review.product?.brand && (
                  <Typography variant={'Caption'} color={'typography.secondary'}>
                    {review.product.brand?.name}
                  </Typography>
                )}
                <Typography variant={'BaseS_B'}>{review.product?.name}</Typography>
                <Typography variant={'BaseS'} color={'typography.secondary'}>
                  {review.productOptionNames?.join(', ')}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap={'10px'}>
              <Rating
                name="half-rating"
                value={review.rating}
                readOnly
                precision={0.5}
                sx={{
                  '& .MuiRating-icon > .MuiSvgIcon-root': { width: '24px', height: '24px' },
                }}
              />
              <Typography variant={'Caption'} color={'typography.secondary'}>
                {`${dayjs(review.updatedAt).format('YYYY.MM.DD')} ${i18n.t('common-writing-out')}`}
              </Typography>
            </Stack>
            <Container py={'8px'}>
              <Typography variant={'FootNote'}>{review.comment}</Typography>
            </Container>
            <Grid container sx={{ py: '8px' }}>
              <Grid item xs={6} sx={{ pr: '4px' }}>
                <Button
                  fullWidth
                  variant={'contained'}
                  color={'fill'}
                  size={'small'}
                  onClick={() => handleModifyReview(review)}
                >
                  {i18n.t('common-modify')}
                </Button>
              </Grid>
              <Grid item xs={6} sx={{ pl: '4px' }}>
                <Button
                  fullWidth
                  variant={'contained'}
                  color={'fill'}
                  size={'small'}
                  onClick={() => {
                    setDeleteReviewId(review.id!);
                    setDeleteReviewConfirmPopoverOpen(true);
                  }}
                >
                  {i18n.t('common-delete')}
                </Button>
              </Grid>
            </Grid>
            <Dialog
              open={deleteReviewConfirmPopoverOpen}
              onClose={() => setDeleteReviewConfirmPopoverOpen(false)}
              onCancel={() => setDeleteReviewConfirmPopoverOpen(false)}
              onConfirm={() => handleDeleteReview()}
              children={
                <Container px={'20px'} pt={'20px'}>
                  <Typography variant={'BaseS_B'}>
                    {i18n.t('shop-product-review-remove-confirm')}
                  </Typography>
                </Container>
              }
            />
          </Container>
        ))}
      </Stack>
    </Page>
  );
}
