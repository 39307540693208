import { useEffect, useState } from 'react';
import { getProductCollectionByBrand } from '~/services/kup/controllers/productCollection';
import { ProductCollection } from '~kup/models/ProductCollection';

type UseProductBrandCollections = {
  collections: ProductCollection[];
  loading: boolean;
};

type ProductCollectionGroupSearchOptions = {
  brandId: string;
  orderBy: 'orderWeight' | 'random';
};

export default function useProductBrandCollections(
  searchOptions: ProductCollectionGroupSearchOptions
): UseProductBrandCollections {
  const [loading, setLoading] = useState(false);
  const [collections, setCollections] = useState<ProductCollection[]>([]);

  useEffect(() => {
    setLoading(true);
    getProductCollectionByBrand(searchOptions.brandId, searchOptions.orderBy)
      .then((productCollections) => {
        setCollections(productCollections);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchOptions.brandId, searchOptions.orderBy]);

  return { collections, loading };
}
