import useKintStore from '~kint/store';
import { useEffect, useState } from 'react';
import { IChatting } from '~kint/models/Chatting.ts';
import countMessage from '~kint/controllers/countMessage.ts';

type Counts = {
  all: number;
  unread: number;
  read: number;
  my: number;
  notification: number;
};

export default function useChattingMessageCount(chat: IChatting): Counts {
  const { participant } = useKintStore((state) => ({ participant: state.participant }));
  const [counts, setCounts] = useState<Counts>({
    all: 0,
    unread: 0,
    read: 0,
    my: 0,
    notification: 0,
  });
  const participantLastRead = participant?.id ? chat?.readDate?.[participant?.id]?.toString() : '';
  useEffect(() => {
    if (participant?.id)
      countMessage(chat.id, participant.id).then((counts) => {
        setCounts(counts);
      });
  }, [participant?.id, participantLastRead]);

  return counts;
}
