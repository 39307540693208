import ProductList from '~/components/ProductList';
import useBatchLogger from '~/hooks/useBatchLogger';
import useProducts from '~/hooks/useProducts';
import i18n from '~/i18n';
import { extractProductEventData } from '~/utils/product';
import { Product } from '~/services/kup/models/Product';

export default function RecommendedProducts() {
  const { products } = useProducts();
  const { logAction } = useBatchLogger();

  const handleClickProductItem = (product: Product) => {
    logAction({
      eventType: 'CLICK',
      eventTarget: 'CART_PRODUCT',
      eventCategory: 'product',
      eventData: extractProductEventData(product, { type: 'cart' }),
    });
  };

  const handleLikeProduct = (product: Product) => {
    if (!product.isLiked) {
      logAction({
        eventType: 'CLICK',
        eventTarget: 'CART_PRODUCT_LIKE',
        eventCategory: 'product',
        eventData: extractProductEventData(product, { type: 'cart' }),
      });
    }
  };

  return (
    <ProductList
      type={'simple'}
      title={
        i18n.t('shop-basket-howAboutThis') // How about this?
      }
      products={products}
      trail={'AD'}
      onClickProductItem={handleClickProductItem}
      onLikeProductItem={handleLikeProduct}
    />
  );
}
