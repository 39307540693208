import { Slice } from '~common/store';

export interface CommonSlice {
  currentPage: { pathname: string; hash?: string; query?: string } | null;
  setCurrentPage: (pathname: string, hash?: string, query?: string) => void;
  stopAskInstallFrom: number;
  setStopAskInstallFrom: (stopAskInstallFrom: number) => void;
}

export interface PersistedCommonSlice {
  stopAskInstallFrom: number;
}

export const persistCommonSlice: (state: CommonSlice) => PersistedCommonSlice = (
  state: CommonSlice
) => ({
  stopAskInstallFrom: state.stopAskInstallFrom,
});

export const createCommonSlice: Slice<CommonSlice, CommonSlice> = (setState) => ({
  currentPage: null,
  stopAskInstallFrom: 0,
  setStopAskInstallFrom: (stopAskInstallFrom: number) => setState({ stopAskInstallFrom }),
  setCurrentPage: (pathname: string, hash?: string, query?: string) => {
    const currentPage = { pathname, hash: hash, query: query };
    setState(() => ({ currentPage }));
  },
});
