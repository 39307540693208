import { useNavigate } from 'react-router-dom';
import IconButton from '~/components/IconButton.tsx';
import useKupStore from '~kup/store';

export default function BasketIconWithCount({
  width = '24px',
  touchArea = '16px',
  color,
  displayOnlyNeeded,
}: {
  width?: string;
  touchArea?: string;
  color?: string;
  displayOnlyNeeded?: boolean;
}) {
  const navigate = useNavigate();
  const { basket } = useKupStore((state) => ({
    basket: state.basket,
  }));
  const basketItemCount: number | undefined = basket?.count || undefined;
  const handleNavigate = () => {
    navigate('/shop/basket');
  };
  if (displayOnlyNeeded && !basketItemCount) return null;
  return (
    <IconButton
      badge={basketItemCount}
      touchArea={touchArea}
      iconProps={{ width }}
      onClick={handleNavigate}
      sx={(theme) => ({ color: color ?? theme.palette.typography.tertiary })}
    >
      basket
    </IconButton>
  );
}
