import { Product } from './Product';

export interface ProductBrandResponse {
  id: string;
  slug: string;
  name: string;
  logo: string;
  description: string;
  images: string[];
  isActive: boolean;
  isMainDisplay: boolean;
  isLiked: boolean;
  likeCount: number;
}

export class ProductBrand {
  id: string;
  slug: string;
  name: string;
  logo: string;
  description: string;
  images: string[];
  isActive: boolean;
  isMainDisplay: boolean;
  isLiked: boolean;
  likeCount: number;
  products?: Product[];

  constructor(raw: ProductBrandResponse) {
    this.id = raw.id;
    this.slug = raw.slug;
    this.name = raw.name;
    this.logo = raw.logo;
    this.description = raw.description;
    this.images = raw.images;
    this.isActive = raw.isActive;
    this.isMainDisplay = raw.isMainDisplay;
    this.isLiked = raw.isLiked;
    this.likeCount = raw.likeCount;
  }
}
