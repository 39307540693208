import { useState, useEffect, useLayoutEffect } from 'react';

const useSafeArea = () => {
  const [safeArea, setSafeArea] = useState<{
    topSafeArea: number;
    bottomSafeArea: number;
  }>({
    topSafeArea: 0,
    bottomSafeArea: 0,
  });
  const [viewportHeight, setViewportHeight] = useState<number>(window.innerHeight);
  useLayoutEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // const calculatedBottom = window.visualViewport.height - document.documentElement.clientHeight;
  useEffect(() => {
    const updateSafeArea = () => {
      setSafeArea({
        topSafeArea: Math.min(
          44,
          Number.parseInt(
            getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-top')
          )
        ),
        bottomSafeArea: Math.max(
          0,
          Number.parseInt(
            getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-bottom')
          )
        ),
      });
    };

    updateSafeArea();
    window.addEventListener('resize', updateSafeArea);

    return () => window.removeEventListener('resize', updateSafeArea);
  }, []);

  return { ...safeArea, viewportHeight };
};

export default useSafeArea;
