import Reservation from '~/services/kint/models/Reservation';
import api from '~kup/api';
import Auth from '~kup/models/Auth';

export async function signIn(
  reservationId: string,
  reservationDate: string,
  name: string,
  locale: 'en-US' | 'zh-CN' | 'zh-TW' = 'en-US',
  reservation?: Reservation
): Promise<Auth> {
  const authRes = await api.post('/auth/signIn/by-reservation', {
    reservationId,
    reservationDate,
    name,
    locale,
    reservation,
  });

  const data = authRes.data.data;
  //auth에서 쿠키셋업
  return new Auth({
    accessToken: data.accessToken,
    role: data.role,
    name: data.name,
    locale,
  });
}

export async function getNames(reservationId: string): Promise<string[]> {
  const nameRes = await api.get('/customers/name/by-reservation', { params: { reservationId } });
  return nameRes.data;
}
