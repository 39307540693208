import { useEffect, useState } from 'react';
import useKupStore from '~kup/store';
import {
  requestTokenForKup,
  getTokenForKup,
  isPermissionGranted,
  isSupported,
} from '~kup/controllers/notification.ts';
import { startNotification, stopNotification } from '~kup/controllers/notification.ts';

export default function useKupNotificationToken() {
  const [isNotificationSupported, setIsNotificationSupported] = useState<boolean>(false);
  const {
    setIsNotificationPermissionRequested,
    isNotificationPermissionRequested,
    stopRequestNotificationPermission,
    setStopRequestNotificationPermission,
    setIsNotificationPermissionGranted,
    isNotificationPermissionGranted,
    token,
    setToken,
    isNotificationDisabled,
    setIsNotificationDisabled,
  } = useKupStore((state) => ({
    isNotificationPermissionRequested: state.isNotificationPermissionRequested,
    isNotificationPermissionGranted: state.isNotificationPermissionGranted,
    stopRequestNotificationPermission: state.stopRequestNotificationPermission, // request skip한 시간
    isNotificationDisabled: state.isNotificationDisabled,
    token: state.token,
    setStopRequestNotificationPermission: state.setStopRequestNotificationPermission,
    setIsNotificationPermissionRequested: state.setIsNotificationPermissionRequested,
    setIsNotificationPermissionGranted: state.setIsNotificationPermissionGranted,
    setIsNotificationDisabled: state.setIsNotificationDisabled,
    setToken: state.setToken,
  }));

  const isSnoozed = stopRequestNotificationPermission > Date.now() - 12 * 60 * 60 * 1000;

  const disableRequest = () => {
    const now = Date.now();
    setStopRequestNotificationPermission(now);
    setIsNotificationPermissionRequested(true);
  };
  const disableNotification = () => {
    setIsNotificationDisabled(true);
    stopNotification().catch(console.error);
  };

  const enableNotification = () => {
    setIsNotificationDisabled(false);
    startNotification().catch(console.error);
  };

  const requestToken = () => {
    requestTokenForKup()
      .then((token) => {
        setToken(token);
        setIsNotificationPermissionGranted(!!token);
      })
      .catch(console.error);
    setIsNotificationPermissionRequested(true);
  };

  useEffect(() => {
    isSupported().then(setIsNotificationSupported);
    if (isNotificationDisabled) {
      //disable 일 경우 토큰 갱신 하지 아니함
      return;
    }

    isPermissionGranted().then((isNotificationGranted) => {
      setIsNotificationPermissionRequested(true);
      setIsNotificationPermissionGranted(isNotificationGranted);
      if (isNotificationGranted) {
        getTokenForKup().then((token) => {
          setToken(token);
        });
      }
    });
  }, []);

  return {
    token,
    requestToken,
    disableRequest,
    disableNotification,
    enableNotification,
    isNotificationPermissionRequested,
    isNotificationPermissionGranted,
    isNotificationSupported,
    isNotificationDisabled,
    isSnoozed,
    stopRequestNotificationPermission,
    isNeededToShowDialog:
      isNotificationSupported &&
      !isNotificationDisabled &&
      !isSnoozed &&
      !isNotificationPermissionGranted,
  };
}
