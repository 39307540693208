import { ReactNode } from 'react';
import Area, { AreaProps } from '~/components/Area';
import ProductItem from '~/components/ProductItem';
import Container from '~/components/Container';
import Stack from '~/components/Stack';
import { Product } from '~kup/models/Product';
import { chunkArray } from '~/utils/array';
import Typography from './Typography';

type ProductLimitedListProps = {
  title?: ReactNode;
  trail?: ReactNode;
  OverlapBadge?: ReactNode;
  products: Product[];
  background?: AreaProps<'div'>['background'];
  moreProduct?: string;
  column?: number;
  row?: number;
  numbering?: boolean;
  onClickProductItem?: (product: Product) => void;
  onLikeProductItem?: (product: Product) => void;
};

export default function ProductLimitedList(props: ProductLimitedListProps) {
  const { ...listProps } = props;
  return <TableList {...listProps} />;
}

function TableList(props: ProductLimitedListProps) {
  const {
    title,
    trail,
    OverlapBadge,
    products,
    background = 'paper',
    column = 1,
    row,
    numbering = false,
    onClickProductItem,
    onLikeProductItem,
  } = props;
  const chunkedProducts = chunkArray<Product>(products, row, column);
  return (
    <Area
      title={title}
      trail={trail}
      containerProps={{
        stackProps: {
          gap: '8px',
        },
      }}
      background={background}
    >
      <Container py={'4px'} px={'20px'}>
        <Stack sx={{ overflowX: 'auto' }}>
          <Stack direction={'row'}>
            {chunkedProducts.map((products, idx) => {
              return (
                <ProductColumn
                  key={idx}
                  numberingStartedAt={numbering ? idx * (row ?? 1) + 1 : undefined}
                  OverlapBadge={OverlapBadge}
                  products={products}
                  onClickProductItem={onClickProductItem}
                  onLikeProductItem={onLikeProductItem}
                />
              );
            })}
          </Stack>
        </Stack>
      </Container>
    </Area>
  );
}

type ProductColumnProps = {
  products: Product[];
  OverlapBadge?: ReactNode;
  numberingStartedAt?: number;
  onClickProductItem?: (product: Product) => void;
  onLikeProductItem?: (product: Product) => void;
};

function ProductColumn(props: ProductColumnProps) {
  const { products, OverlapBadge, numberingStartedAt, onClickProductItem, onLikeProductItem } =
    props;
  return (
    <Stack gap={1}>
      {products.map((product, index) => {
        return (
          <Stack direction={'row'} gap={1.5}>
            {numberingStartedAt && (
              <Typography variant={'h3'}>{numberingStartedAt + index}</Typography>
            )}
            <ProductItem
              key={product.id}
              type={'horizontal'}
              product={product}
              OverlapBadge={OverlapBadge}
              onClickProductItem={onClickProductItem}
              onLikeProductItem={onLikeProductItem}
            />
          </Stack>
        );
      })}
    </Stack>
  );
}
