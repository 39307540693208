export type IconMyProps = {
  width?: string;
  height?: string;
};
export default function IconMy(props: IconMyProps) {
  const { width = '32px', height = '32px' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 11.6248C10.7625 11.6248 9.73434 11.2155 8.91553 10.3969C8.09691 9.57806 7.68759 8.54991 7.68759 7.31241C7.68759 6.07491 8.09691 5.04675 8.91553 4.22794C9.73434 3.40931 10.7625 3 12 3C13.2375 3 14.2657 3.40931 15.0845 4.22794C15.9031 5.04675 16.3124 6.07491 16.3124 7.31241C16.3124 8.54991 15.9031 9.57806 15.0845 10.3969C14.2657 11.2155 13.2375 11.6248 12 11.6248ZM3 18.7686V17.8312C3 17.1436 3.17344 16.542 3.52031 16.0264C3.86719 15.5108 4.31878 15.1186 4.87509 14.8499C6.10003 14.2812 7.29684 13.8547 8.46553 13.5702C9.63441 13.2858 10.8126 13.1436 12 13.1436C13.1874 13.1436 14.3624 13.2889 15.5249 13.5795C16.6874 13.8701 17.8807 14.2948 19.1047 14.8536C19.6773 15.1239 20.1363 15.5159 20.4817 16.0295C20.8272 16.543 21 17.1436 21 17.8312V18.7686C21 19.2842 20.8164 19.7257 20.4493 20.093C20.082 20.4601 19.6405 20.6437 19.1249 20.6437H4.87509C4.35947 20.6437 3.918 20.4601 3.55069 20.093C3.18356 19.7257 3 19.2842 3 18.7686ZM4.87509 18.7686H19.1249V17.8312C19.1249 17.5625 19.0484 17.3093 18.8954 17.0718C18.7422 16.8342 18.55 16.6561 18.3188 16.5374C17.1813 15.9811 16.1093 15.5889 15.103 15.3609C14.0969 15.1328 13.0626 15.0187 12 15.0187C10.9374 15.0187 9.89681 15.1328 8.87813 15.3609C7.85944 15.5889 6.78759 15.9811 5.66259 16.5374C5.43122 16.6561 5.24212 16.8342 5.09531 17.0718C4.9485 17.3093 4.87509 17.5625 4.87509 17.8312V18.7686ZM12 9.75C12.6937 9.75 13.2734 9.51713 13.739 9.05138C14.2047 8.58581 14.4376 8.00616 14.4376 7.31241C14.4376 6.61866 14.2047 6.039 13.739 5.57344C13.2734 5.10769 12.6937 4.87481 12 4.87481C11.3062 4.87481 10.7266 5.10769 10.261 5.57344C9.79528 6.039 9.56241 6.61866 9.56241 7.31241C9.56241 8.00616 9.79528 8.58581 10.261 9.05138C10.7266 9.51713 11.3062 9.75 12 9.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
