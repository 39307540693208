import Page from '~/components/Page';
import i18n from '~/i18n';
import { useNavigate } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2';
import List from '~/components/List';
import { useProductCategoryTree } from '../hooks/useProductCategories';
import ListItem from '~/components/ListItem';
import { useEffect, useState } from 'react';
import { ProductCategory } from '~/services/kup/models/Product';
import Icon from '~/components/Icon';
import BasketIconWithCount from '~/components/BasketIconWithCount.tsx';
import LikeIconWithCount from '~/components/LikeIconWithCount.tsx';
import Stack from '~/components/Stack.tsx';

export default function CategoryNav() {
  const navigate = useNavigate();
  const { categories } = useProductCategoryTree();
  const [selectedCategory, setSelectedCategory] = useState<ProductCategory | null>(null);

  useEffect(() => {
    if (categories.length > 0) {
      setSelectedCategory(categories[0]);
    }
  }, [categories]);

  return (
    <Page
      type={'page'}
      name={i18n.t('common-category')}
      backgroundColor={'transparent'}
      display={'relative'}
      navigationBarProps={{
        trail: (
          <Stack height={'20px'} flexDirection={'row'} gap={'12px'}>
            <BasketIconWithCount />
            <LikeIconWithCount />
          </Stack>
        ),
      }}
    >
      <Grid2 container>
        <Grid2 xs={5}>
          <List sx={{ p: 0 }}>
            {categories.map((category) => (
              <ListItem
                key={category.slug}
                type="headline"
                headline={category.name}
                headlineProps={{
                  p: 0,
                }}
                backgroundColor={selectedCategory?.id === category.id ? 'white' : 'transparent'}
                onClick={() => setSelectedCategory(category)}
              />
            ))}
          </List>
        </Grid2>
        <Grid2 xs={7}>
          <List
            sx={{
              backgroundColor: 'white',
              height: 'calc(100vh - 52px)',
              p: 0,
            }}
          >
            <ListItem
              key={'all'}
              type="headline"
              headline={i18n.t('common-all')}
              trail={
                <Icon size={'20px'} color={'typography.tertiary'}>
                  chevron_right
                </Icon>
              }
              onClick={() => navigate(`/shop#category/${selectedCategory?.slug}/all`)}
            />
            {selectedCategory?.children?.map((category) => (
              <ListItem
                key={category.slug}
                type="headline"
                headline={category.name}
                trail={
                  <Icon size={'20px'} color={'typography.tertiary'}>
                    chevron_right
                  </Icon>
                }
                onClick={() => navigate(`/shop#category/${selectedCategory.slug}/${category.slug}`)}
              />
            ))}
          </List>
        </Grid2>
      </Grid2>
    </Page>
  );
}
