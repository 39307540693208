import Container from '~/components/Container.tsx';
import Stack from '~/components/Stack';

import ImageView from '~/components/ImageView.tsx';
import { ProductBrand } from '~/services/kup/models/ProductBrand';
import { useNavigate } from 'react-router-dom';
import Typography from '~/components/Typography';

export type BrandListItemProps = {
  brand: ProductBrand;
};

export default function BrandListItem(props: BrandListItemProps) {
  const { brand } = props;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/shop/brand/${brand.id}`, { state: { brandId: brand.id } });
  };

  return (
    <Container py={'4px'}>
      <Container px={'20px'}>
        <Stack row gap={'14px'} alignItems={'center'} onClick={handleClick}>
          <ImageView borderRadius={'6px'} width={'60px'} height={'60px'} src={brand.logo} />
          <Stack sx={{ width: '100%' }}>
            <Typography variant={'BaseM_B'}>{brand.name}</Typography>
            <Typography
              variant={'FootNote'}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
              }}
            >
              {brand.description}
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </Container>
  );
}
