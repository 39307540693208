import { FavoriteBorderOutlined } from '@mui/icons-material';
import Area from '~/components/Area';
import ImageView from '~/components/ImageView';
import LikeButton from '~/components/LikeButton';
import ProductList from '~/components/ProductList';
import Spotlight from '~/components/Spotlight';
import Stack from '~/components/Stack';
import Typography from '~/components/Typography';
import useLikedProductBrands from '~/hooks/useLikedProductBrands';
import i18n from '~/i18n';
import { unlikeProductBrand } from '~/services/kup/controllers/productBrand';
import { likeProductBrand } from '~/services/kup/controllers/productBrand';
import useKupStore from '~kup/store';
import { ProductBrand } from '~kup/models/ProductBrand.ts';

export default function LikedBrandList() {
  const { productBrands, fetchLikedProductBrands } = useLikedProductBrands();
  const { toggleBrandLike } = useKupStore((state) => ({
    toggleBrandLike: state.toggleBrandLike,
  }));
  const handleLike = async (brandId: string, liked: boolean) => {
    if (liked) {
      await unlikeProductBrand(brandId);
    } else {
      await likeProductBrand(brandId);
    }

    toggleBrandLike(brandId, !liked);
    fetchLikedProductBrands();
  };

  const emptyBrandSpotlight = (
    <Spotlight
      textAlign={'center'}
      py={'15vh'}
      lead={<FavoriteBorderOutlined sx={{ fontSize: '64px', color: 'typography.tertiary' }} />}
      headline={i18n.t('shop-no-brand-liked')}
      description={i18n.t('shop-no-brand-liked-guide')}
    />
  );

  return (
    <Area
      title={<Typography variant={'FootNote'}>{productBrands.length} brands</Typography>}
      containerProps={{ stackProps: { gap: '6px' } }}
      background={'paper'}
    >
      {productBrands.length === 0
        ? emptyBrandSpotlight
        : productBrands.map((productBrand) => (
            <LikedBrandItem productBrand={productBrand} onLike={handleLike} key={productBrand.id} />
          ))}
    </Area>
  );
}

function LikedBrandItem(props: {
  productBrand: ProductBrand;
  onLike: (brandId: string, likedBefore: boolean) => void;
}) {
  const { productBrand, onLike } = props;
  const { brandLikeDict } = useKupStore((state) => ({
    brandLikeDict: state.brandLikeDict,
  }));

  productBrand.isLiked = productBrand.id in brandLikeDict;
  const handleLike = () => {
    onLike(productBrand.id, productBrand.isLiked);
  };

  return (
    <Stack key={productBrand.id} gap={2} sx={{ py: '12px' }}>
      <Stack
        row
        gap={'14px'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ px: '20px' }}
      >
        <ImageView borderRadius={'6px'} width={'60px'} height={'60px'} src={productBrand.logo} />
        <Stack column gap={'4px'} sx={{ width: '100%' }}>
          <Typography variant={'BaseM_B'}>{productBrand.name}</Typography>
          <Typography
            variant={'FootNote'}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
            }}
          >
            {productBrand.description}
          </Typography>
        </Stack>
        <LikeButton liked={productBrand.isLiked} type={'simple'} onClick={handleLike} />
      </Stack>
      <ProductList products={productBrand.products ?? []} type={'simple'} />
    </Stack>
  );
}
