import { ReactNode } from 'react';
import {
  default as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';
import { default as MuiBadge, BadgeProps as MuiBadgeProps } from '@mui/material/Badge';
import Icon, { IconProps } from './Icon';
import styled from '~/components/styled.tsx';

function isUsingIconFont(children: ReactNode): children is ReactNode & string {
  return typeof children === 'string';
}

const StyledBadge = styled(MuiBadge)<MuiBadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -5,
    top: 12,
    border: `2px solid ${theme.palette.background.paper}`,
    transform: 'scale(0.8)',
  },
}));

export type IconButtonProps = {
  color?: IconProps['color'];
  iconProps?: Omit<IconProps, 'children'>;
  badge?: boolean | number;
  badgeColor?: MuiBadgeProps['color'];
} & Omit<MuiIconButtonProps, 'color' | 'edge'>;

export function BaseIconButton(props: IconButtonProps) {
  const { children, color, iconProps, badge, badgeColor, ...restProps } = props;
  const iconPropsAssign = {};
  if (iconProps) Object.assign(iconPropsAssign, iconProps);
  if (color) Object.assign(iconPropsAssign, { color });
  return isUsingIconFont(children) ? (
    <MuiIconButton {...restProps}>
      {badge !== undefined ? (
        <StyledBadge
          color={badgeColor ?? 'primary'}
          variant={typeof badge === 'number' ? 'standard' : 'dot'}
          badgeContent={typeof badge === 'number' ? badge : undefined}
        >
          <Icon {...iconPropsAssign}>{children}</Icon>
        </StyledBadge>
      ) : (
        <Icon {...iconPropsAssign}>{children}</Icon>
      )}
    </MuiIconButton>
  ) : (
    <MuiIconButton {...restProps}>
      {badge !== undefined ? (
        <StyledBadge
          color={badgeColor ?? 'primary'}
          variant={typeof badge === 'number' ? 'standard' : 'dot'}
          badgeContent={typeof badge === 'number' ? badge : undefined}
        >
          {children}
        </StyledBadge>
      ) : (
        children
      )}
    </MuiIconButton>
  );
}

export type IconButtonEdges = {
  edge?: boolean | string;
  edgeTop?: boolean | string;
  edgeBottom?: boolean | string;
  edgeLeft?: boolean | string;
  edgeRight?: boolean | string;
  touchArea?: string;
  touchAreaLeft?: string;
  touchAreaRight?: string;
  touchAreaTop?: string;
  touchAreaBottom?: string;
};

const EdgeIconButton = styled(BaseIconButton, {
  shouldForwardProp: (prop) =>
    prop !== 'edge' &&
    prop !== 'edgeTop' &&
    prop !== 'edgeBottom' &&
    prop !== 'edgeLeft' &&
    prop !== 'edgeRight' &&
    prop !== 'touchArea' &&
    prop !== 'touchAreaLeft' &&
    prop !== 'touchAreaRight' &&
    prop !== 'touchAreaTop' &&
    prop !== 'touchAreaBottom',
})<IconButtonProps & IconButtonEdges>(({
  edge,
  edgeTop,
  edgeBottom,
  edgeLeft,
  edgeRight,
  touchArea,
  touchAreaLeft,
  touchAreaRight,
  touchAreaTop,
  touchAreaBottom,
}) => {
  const calculateEdgeMargin = (
    edge: boolean | string | undefined,
    touchArea: string | undefined
  ) => {
    if (edge === false) {
      return undefined;
    }
    if (typeof edge === 'string') {
      return `-${edge}`;
    }
    return `-${touchArea || '0px'}`;
  };

  // 우선 개별적인 방향의 edge 속성을 먼저 계산하고, 그 다음에 전역적인 edge 속성을 적용
  const calculatedMargins = {
    marginTop: calculateEdgeMargin(
      edgeTop !== undefined ? edgeTop : edge,
      touchAreaTop || touchArea
    ),
    marginBottom: calculateEdgeMargin(
      edgeBottom !== undefined ? edgeBottom : edge,
      touchAreaBottom || touchArea
    ),
    marginLeft: calculateEdgeMargin(
      edgeLeft !== undefined ? edgeLeft : edge,
      touchAreaLeft || touchArea
    ),
    marginRight: calculateEdgeMargin(
      edgeRight !== undefined ? edgeRight : edge,
      touchAreaRight || touchArea
    ),
  };

  const calculatedPaddings = {
    paddingTop: touchAreaTop || touchArea || '0px',
    paddingBottom: touchAreaBottom || touchArea || '0px',
    paddingLeft: touchAreaLeft || touchArea || '0px',
    paddingRight: touchAreaRight || touchArea || '0px',
  };

  return {
    ...calculatedMargins,
    ...calculatedPaddings,
  };
});

export default EdgeIconButton;
