import { FirebaseApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  isSupported,
  MessagePayload,
  Messaging,
  onMessage,
  Unsubscribe,
} from 'firebase/messaging';
import kupApp from '~kup/firebase/app.ts';

export class FMessaging {
  app: FirebaseApp;
  messaging: Messaging | null;
  private static rn: boolean = !!window?.ReactNativeWebView;
  private static rnGranted: boolean | undefined;
  private static rnSupported: boolean | undefined;
  private static instances: { [name: string]: FMessaging } = {};

  static isRN() {
    return this.rn;
  }
  static getInstance(app: FirebaseApp, name: string) {
    if (FMessaging.rn) {
      //rn 이면 강제 override
      name = 'rn';
      app = kupApp;
    }
    if (!FMessaging.instances[name]) {
      FMessaging.instances[name] = new FMessaging(app);
    }
    return FMessaging.instances[name];
  }

  private constructor(app: FirebaseApp) {
    this.app = app;
    this.messaging = null;
    this.initMessaging().catch(console.error);
  }

  private async initMessaging(): Promise<void> {
    if (FMessaging.isRN()) return; // RN에 위임
    const supported = await isSupported();
    if (supported) {
      this.messaging = getMessaging(this.app);
    }
  }

  private async ensureMessagingInitialized(): Promise<boolean> {
    if (FMessaging.isRN()) return true; // RN에 위임
    if (!this.messaging) {
      await this.initMessaging();
    }
    return this.messaging !== null;
  }

  protected async isSupportedRN(): Promise<boolean> {
    if (FMessaging.rnSupported) return true;
    if (FMessaging.isRN() && window?.ReactNativeWebView?.requestJob) {
      const jobResult = await window.ReactNativeWebView?.requestJob<{
        supported: boolean;
      }>('FCM_IS_SUPPORTED');
      return (FMessaging.rnSupported = jobResult.supported ?? false);
    }
    return (FMessaging.rnSupported = false);
  }

  protected async requestPermissionRN(): Promise<boolean> {
    if (FMessaging.rnGranted) return true;
    if (FMessaging.isRN() && window?.ReactNativeWebView?.requestJob) {
      const jobResult = await window.ReactNativeWebView?.requestJob<{
        granted: boolean;
      }>('FCM_REQUEST_PERMISSION');
      return (FMessaging.rnGranted = jobResult.granted ?? false);
    }
    return (FMessaging.rnGranted = false);
  }

  protected async getTokenRn(): Promise<string | null> {
    if (FMessaging.isRN() && window?.ReactNativeWebView?.requestJob) {
      const jobResult = await window.ReactNativeWebView?.requestJob<{
        token: string | null;
      }>('FCM_GET_TOKEN');
      return jobResult.token ?? null;
    }
    return null;
  }

  protected async requestTokenRn(): Promise<string | null> {
    if (FMessaging.isRN() && window?.ReactNativeWebView?.requestJob) {
      const jobResult = await window.ReactNativeWebView?.requestJob<{
        token: string | null;
      }>('FCM_REQUEST_TOKEN');
      return jobResult.token ?? null;
    }
    return null;
  }

  protected async checkPermissionRn(): Promise<boolean> {
    if (FMessaging.rnGranted) return true;
    if (FMessaging.isRN() && window?.ReactNativeWebView?.requestJob) {
      const jobResult = await window.ReactNativeWebView?.requestJob<{
        granted: boolean;
      }>('FCM_CHECK_PERMISSION');
      return (FMessaging.rnGranted = jobResult.granted ?? false);
    }
    return (FMessaging.rnGranted = false);
  }
  async isSupported(): Promise<boolean> {
    if (FMessaging.isRN()) return await this.isSupportedRN(); // RN에 위임
    return await isSupported();
  }

  async isPermissionGranted(): Promise<boolean> {
    if (FMessaging.isRN()) return await this.checkPermissionRn(); // RN에 위임
    return window?.Notification?.permission === 'granted';
  }

  async requestPermission(): Promise<boolean> {
    if (FMessaging.isRN()) {
      return await this.requestPermissionRN(); // RN에 위임
    }

    if (!(await this.isSupported())) return false;
    if (await this.isPermissionGranted()) return true;

    try {
      return 'granted' === (await Notification.requestPermission());
    } catch (e) {
      return false;
    }
  }

  async getToken(): Promise<string | null> {
    if (FMessaging.isRN()) {
      return await this.getTokenRn(); // RN에 위임
    }

    const messagingInitialized = await this.ensureMessagingInitialized();
    if (!messagingInitialized || !(await this.isPermissionGranted())) {
      return null;
    }
    try {
      if (!this.messaging) return null;
      return await getToken(this.messaging, {
        vapidKey: import.meta.env.VITE_VITE_FIREBASE_VAPID_KEY,
      });
    } catch (e) {
      return await new Promise((resolve) => {
        setTimeout(() => {
          if (this.messaging)
            getToken(this.messaging, {
              vapidKey: import.meta.env.VITE_VITE_FIREBASE_VAPID_KEY,
            }).then(resolve);
        }, 500);
      });
    }
  }

  async requestToken(): Promise<string | null> {
    if (FMessaging.isRN()) {
      return await this.requestTokenRn();
    }

    if (!(await this.ensureMessagingInitialized())) {
      return null;
    }
    if (!(await this.isPermissionGranted())) {
      const isGranted = await this.requestPermission();
      if (!isGranted) return null;
    }
    return await this.getToken();
  }

  onMessage(listener: (payload: MessagePayload) => void): Unsubscribe {
    if (FMessaging.isRN()) {
      window.addEventListener('message', console.log); //webview message;
    }
    if (!this.messaging) {
      return () => {};
    }
    return onMessage(this.messaging, listener);
  }
}
