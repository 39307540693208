import { ICoupon } from '~kup/models/Coupon.ts';
import { getIssuableCoupons } from '~kup/controllers/coupon.ts';
import { useCallback, useLayoutEffect, useState } from 'react';

type UseIssuableCoupons = {
  issuableCoupons: ICoupon[];
  trueIssuableCoupons: ICoupon[];
  callIssuableCoupons: () => void;
};

export default function useIssuableCoupons(): UseIssuableCoupons {
  const [issuableCoupons, setIssuableCoupons] = useState<ICoupon[]>([]);
  const [trueIssuableCoupons, setTrueIssuableCoupons] = useState<ICoupon[]>([]);

  const callIssuableCoupons = useCallback(() => {
    getIssuableCoupons({}).then((issuableCoupons) => {
      setIssuableCoupons(issuableCoupons);
      setTrueIssuableCoupons(issuableCoupons.filter((coupon) => coupon.isIssuable));
    });
  }, []);

  useLayoutEffect(() => {
    callIssuableCoupons();
  }, []);

  return {
    issuableCoupons,
    trueIssuableCoupons,
    callIssuableCoupons,
  };
}
