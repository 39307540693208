import Page from '~/components/Page';
import Container from '~/components/Container';
import Typography from '~/components/Typography';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export default function PrivacyPage() {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState<string>(i18n.language);
  const transitToEn = () => {
    setLang('en');
  };
  if (lang.toLowerCase() === 'zht') return <PrivacyPageZht transitToEn={transitToEn} />;
  if (lang.toLowerCase() === 'zh') return <PrivacyPageZh transitToEn={transitToEn} />;
  return <PrivacyPageEn />;
}

function PrivacyPageZh({ transitToEn }: { transitToEn: () => void }) {
  return (
    <Page type={'modal'} name={'隐私政策'}>
      <Container pb={'20px'} px={'20px'}>
        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            1. 简介
          </Typography>
          <p>
            本内容为 AI 翻译,详细内容请参考
            <span onClick={transitToEn} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              <b>英文</b>
            </span>{' '}
            版。
          </p>
          在 K Tour Story Co.,
          Ltd.,您的隐私是我们的首要任务。本隐私政策概述了您在使用我们的单日游服务以及接收首尔和韩国相关旅游信息时,我们如何收集、使用和保护您的个人信息。
        </Container>
        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            2. 我们收集的信息
          </Typography>
          为了向您提供我们的服务,我们收集以下个人信息：
          <ul>
            <li>
              <b>姓名：</b>
              <br />
              用于个性化您的旅游体验并与您进行有效沟通。
            </li>
            <li>
              <b>联系信息：</b>
              <br />
              包括您的电话号码和电子邮件地址,以向您发送有关旅游更新、订单确认和必要的服务相关信息。
            </li>
            <li>
              <b>送货地址：</b>
              <br />
              如果您使用我们的商务服务进行当日送货,我们需要您的送货地址。
            </li>
            <li>
              <b>预订信息：</b>
              <br />
              从旅游代理或其他预订平台收集,用于处理和存储您的预订详情。
            </li>
            <li>
              <b>使用数据与互动信息：</b>
              <br />
              包括设备标识符、产品互动数据、广告标识符, 以及通过 Google Analytics、Hotjar 和
              AppsFlyer 收集的匿名使用数据。 所有数据收集遵守相关隐私法规和 Apple 的 App Tracking
              Transparency 政策, 只有在您同意的情况下才会收集可识别信息。
            </li>
          </ul>
        </Container>
        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            3. 我们如何使用您的信息
          </Typography>
          您的个人信息用于以下目的：
          <ul>
            <li>
              <b>旅游服务：</b>
              <br />
              为了促进并提升您的单日游体验。
            </li>
            <li>
              <b>旅游信息：</b>
              <br />
              为您提供首尔和韩国的相关信息,包括旅游提示、目的地指南和其他有用内容。
            </li>
            <li>
              <b>商务服务：</b>
              <br />
              处理订单并安排与您的旅游体验相关的当日送货。
            </li>
            <li>
              <b>服务通知：</b>
              <br />
              向您发送有关聊天服务和商务功能的推送通知。请注意,我们不发送营销推送通知；所有通知均为服务相关通知。
            </li>
            <li>
              <b>非侵扰性营销内容：</b>
              <br />
              我们可能会提供类似杂志形式的旅游相关内容,包括商店介绍和其他信息内容。此内容仅供参考,并不涉及直接营销推送。
            </li>
            <li>
              <b>服务分析与优化：</b>
              <br />
              我们使用 Google Analytics、Hotjar 和 AppsFlyer 等工具
              来分析服务使用情况、跟踪应用安装来源及优化营销效果。
              如果您未授权,所有数据收集将以匿名模式进行；
              如果您同意,我们会依据您的授权进行可识别数据追踪, 并严格遵守 Apple App Tracking
              Transparency 政策。
            </li>
          </ul>
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            4. 数据收集与同意
          </Typography>
          通过使用我们的服务并自愿提供您的信息,您明确同意按照本隐私政策所述收集、使用和处理您的个人信息。这包括您直接提供的信息以及通过第三方预订平台或代理间接提供的信息。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            5. 数据保留
          </Typography>
          我们在您最后一次旅游或服务完成后保留您的个人信息一年。此期间结束后,除非法律要求保留更长时间,否则您的数据将被安全删除。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            6. 数据安全
          </Typography>
          我们使用行业标准的安全措施保护您的个人信息免受未经授权的访问、披露或滥用。
        </Container>
        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            7. 第三方数据共享
          </Typography>
          在以下情况下,我们可能会与第三方共享您的个人信息：
          <ul>
            <li>
              <b>支付处理：</b>
              <br />
              为了便于支付交易,您的支付信息可能会与我们的支付处理合作伙伴共享。
            </li>
            <li>
              <b>运输和送货：</b>
              <br />
              如果您使用我们的商务服务,您的送货信息将与我们的物流合作伙伴共享,以确保您的订单正确送达。
            </li>
            <li>
              <b>服务分析和营销效果跟踪：</b>
              <br />
              我们可能会与第三方数据分析及营销合作伙伴 (如 Google Analytics、Hotjar 和 AppsFlyer)
              共享匿名化使用数据,以帮助我们改善服务和评估营销活动效果。
              所有数据收集和共享均遵循适用的隐私法规及 Apple App Tracking Transparency 政策,
              并且仅在您授权的情况下进行可识别数据追踪,否则仅以匿名模式处理。
            </li>
          </ul>
          我们与之共享信息的所有第三方均有合同义务, 按照适用隐私法规保护您的数据。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            8. 数据处理与服务分析
          </Typography>
          我们使用 Google Analytics、Hotjar 和 AppsFlyer
          来分析服务使用情况、应用安装来源及优化您的体验和我们的营销活动。
          这些工具以不会直接识别您个人身份的方式收集数据, 确保您的隐私得以维护。
          数据仅用于服务改进和效果分析,并按照行业标准进行保护。
          <br />
          <br />
          Google Analytics、Hotjar 和 AppsFlyer 可能会使用 cookies 或设备标识符收集信息。
          如果您不希望 Google Analytics 收集数据,可以使用{' '}
          <a href="https://tools.google.com/dlpage/gaoptout">
            Google Analytics Opt-out Browser Add-on
          </a>
          ,或通过其 <a href="https://www.hotjar.com/policies/do-not-track/">选择退出页面</a>阻止
          Hotjar 数据收集。
          <br />
          <br />
          同时,AppsFlyer 会在您授权的情况下根据 Apple App Tracking Transparency 政策收集可识别数据。
          如果您未授权,AppsFlyer 仅在匿名模式下运行,不会收集可识别个人身份的信息。
          <br />
          <br />
          所收集的数据仅保留在分析所需的时间内,之后将被安全删除。
          我们确保所有代表我们处理此数据的第三方均遵守相关隐私法规并妥善保护您的数据。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            9. 您的权利
          </Typography>
          您有权随时访问、更正或删除您的个人信息。如果您希望行使任何这些权利,或者希望取消接收任何信息内容,请联系
          support@kup.travel。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            10. 未成年人隐私
          </Typography>
          我们理解保护未成年人隐私的重要性。如果未成年人（14
          岁以下）使用我们的服务,我们可能仅在其法定监护人明确同意的情况下收集其个人信息。任何未经同意收集的信息在发现后将立即删除。此外,该信息可应未成年人的法定监护人明确请求删除。请联系
          support@kup.travel 以请求删除。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            11. 本政策的变更
          </Typography>
          我们可能会不时更新本隐私政策。任何更改将发布在我们的网站上。我们鼓励您定期查看本政策,以了解我们如何保护您的信息。更改发布后继续使用我们的服务,即表示您接受更新后的隐私政策。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            12. 联系我们
          </Typography>
          如果您对本隐私政策有任何疑问或疑虑,请联系 support@kup.travel 或访问我们在韩国首尔 Supyo-ro
          12 的办公室。
        </Container>
      </Container>
    </Page>
  );
}

function PrivacyPageZht({ transitToEn }: { transitToEn: () => void }) {
  return (
    <Page type={'modal'} name={'隱私政策'}>
      <Container pb={'20px'} px={'20px'}>
        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            1. 簡介
          </Typography>
          <p>
            本內容為 AI 翻譯,詳細內容請參考
            <span onClick={transitToEn} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              <b>英文</b>
            </span>{' '}
            版。
          </p>
          在 K Tour Story Co.,
          Ltd.,您的隱私是我們的首要任務。本隱私政策概述了您在使用我們的一日遊服務以及接收首爾和韓國相關旅遊資訊時,我們如何收集、使用和保護您的個人資訊。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            2. 我們收集的資訊
          </Typography>
          為了向您提供我們的服務,我們收集以下個人資訊：
          <ul>
            <li>
              <b>姓名：</b>
              <br />
              用於個性化您的旅遊體驗並與您進行有效溝通。
            </li>
            <li>
              <b>聯絡資訊：</b>
              <br />
              包括您的電話號碼和電子郵件地址,以向您發送有關旅遊更新、訂單確認和必要的服務相關資訊。
            </li>
            <li>
              <b>送貨地址：</b>
              <br />
              如果您使用我們的商務服務進行當日送貨,我們需要您的送貨地址。
            </li>
            <li>
              <b>訂單資訊：</b>
              <br />
              從旅遊代理或其他預訂平台收集,用於處理和存儲您的預訂詳情。
            </li>
            <li>
              <b>使用數據與互動資訊：</b>
              <br />
              包括裝置識別碼、產品互動紀錄、廣告標識符,以及來自 Google Analytics、Hotjar 和
              AppsFlyer 收集的匿名化使用數據,用於分析使用情況並優化服務。 所有追蹤行為均遵守 Apple
              的 App Tracking Transparency 政策, 僅在您同意的情況下才會使用可識別資料。
            </li>
          </ul>
        </Container>
        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            3. 我們如何使用您的資訊
          </Typography>
          您的個人資訊用於以下目的：
          <ul>
            <li>
              <b>旅遊服務：</b>
              <br />
              為了促進並提升您的一日遊體驗。
            </li>
            <li>
              <b>旅遊資訊：</b>
              <br />
              為您提供首爾和韓國的相關資訊,包括旅遊提示、目的地指南和其他有用內容。
            </li>
            <li>
              <b>商務服務：</b>
              <br />
              處理訂單並安排與您的旅遊體驗相關的當日送貨。
            </li>
            <li>
              <b>服務通知：</b>
              <br />
              向您發送有關聊天服務和商務功能的推送通知。請注意,我們不發送營銷推送通知；所有通知均為服務相關通知。
            </li>
            <li>
              <b>非干擾性營銷內容：</b>
              <br />
              我們可能會提供類似雜誌形式的旅遊相關內容,包括店鋪介紹和其他資訊內容。此內容僅供參考,並不涉及直接營銷推送。
            </li>
            <li>
              <b>服務分析與優化：</b>
              <br />
              我們利用 Google Analytics、Hotjar 和 AppsFlyer
              分析使用行為、追蹤安裝來源、及優化行銷活動效果。
              在未經您同意的情況下,不會使用可識別您的個人資訊； 如您不同意,AppsFlyer
              及相關服務將在匿名模式下運行。
            </li>
          </ul>
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            4. 資料收集與同意
          </Typography>
          通過使用我們的服務並自願提供您的資訊,您明確同意按照本隱私政策所述收集、使用和處理您的個人資訊。這包括您直接提供的資訊以及通過第三方預訂平台或代理間接提供的資訊。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            5. 資料保留
          </Typography>
          我們在您最後一次旅遊或服務完成後保留您的個人資訊一年。此期間結束後,除非法律要求保留更長時間,否則您的資料將被安全刪除。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            6. 資料安全
          </Typography>
          我們使用行業標準的安全措施保護您的個人資訊免受未經授權的訪問、披露或濫用。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            7. 第三方資料共享
          </Typography>
          在以下情況下,我們可能會與第三方共享您的個人資訊：
          <ul>
            <li>
              <b>支付處理：</b>
              <br />
              為了便於支付交易,您的支付資訊可能會與我們的支付處理合作夥伴共享。
            </li>
            <li>
              <b>運輸和送貨：</b>
              <br />
              如果您使用我們的商務服務,您的送貨資訊將與我們的物流合作夥伴共享,以確保您的訂單正確送達。
            </li>
            <li>
              <b>服務分析和行銷成效追蹤：</b>
              <br />
              我們可能會與第三方數據分析和行銷合作夥伴 (包括 Google Analytics、Hotjar 和 AppsFlyer)
              共享匿名化的使用數據,以便分析服務使用狀況並優化行銷活動。
              所有資料收集和共享均遵循相關隱私法律與 Apple 的 App Tracking Transparency 政策,
              僅在您同意的情況下使用可識別的資料,未經授權時僅以匿名模式處理。
            </li>
          </ul>
          我們與之共享資訊的所有第三方均有合同義務按照適用的隱私法保護您的資料。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            8. 資料處理與服務分析
          </Typography>
          我們使用 Google Analytics、Hotjar 和 AppsFlyer
          分析服務使用情況、行銷活動效果並改善您的體驗。
          這些工具僅以不會識別您個人身份的方式收集資料, 且遵守相關隱私法律,確保您的隱私得以維護。
          所收集的資料僅用於服務改進與行銷成效分析, 並按照行業標準進行保護。
          <br />
          <br />
          Google Analytics、Hotjar 和 AppsFlyer 可能會使用 cookie 或
          裝置標識符收集此資訊。如果您不希望 Google Analytics 收集資料,可以使用{' '}
          <a href="https://tools.google.com/dlpage/gaoptout">
            Google Analytics Opt-out Browser Add-on
          </a>
          ,或通過其 <a href="https://www.hotjar.com/policies/do-not-track/">選擇退出頁面</a>
          阻止 Hotjar 資料收集。
          <br />
          <br />
          同時,我們使用 AppsFlyer 進行應用安裝歸因與行銷活動分析。 AppsFlyer
          收集的資料僅用於統計與優化服務用途, 並遵循 Apple 的 App Tracking Transparency 政策,
          僅在您允許的前提下進行追蹤。若您未授權, AppsFlyer 僅以匿名方式收集資料,不會識別個人身份。
          <br />
          <br />
          所收集的資料僅在分析所需的期間內保留, 並在無需保留時安全刪除。我們亦確保所有第三方 (包括
          Google、Hotjar、AppsFlyer) 都遵守相關隱私規範, 並相應保護您的資料。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            9. 您的權利
          </Typography>
          您有權隨時訪問、更正或刪除您的個人資訊。如果您希望行使任何這些權利,或者希望取消接收任何資訊內容,請聯絡
          support@kup.travel。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            10. 未成年人的隱私
          </Typography>
          我們理解保護未成年人隱私的重要性。如果未成年人（14
          歲以下）使用我們的服務,我們可能僅在其法定監護人明確同意的情況下收集其個人資訊。任何未經同意收集的資訊在發現後將立即刪除。此外,該資訊可應未成年人的法定監護人明確要求刪除。請聯絡
          support@kup.travel 以請求刪除。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            11. 本政策的變更
          </Typography>
          我們可能會不時更新本隱私政策。任何變更將發佈在我們的網站上。我們鼓勵您定期查看本政策,以了解我們如何保護您的資訊。變更發佈後繼續使用我們的服務,即表示您接受更新後的隱私政策。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            12. 聯繫我們
          </Typography>
          如果您對本隱私政策有任何疑問或疑慮,請聯絡 support@kup.travel 或訪問我們在韓國首爾 Supyo-ro
          12 的辦公室。
        </Container>
      </Container>
    </Page>
  );
}

function PrivacyPageEn() {
  return (
    <Page type={'modal'} name={'Privacy Policy'}>
      <Container pb={'20px'} px={'20px'}>
        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            1. Introduction
          </Typography>
          At K Tour Story Co., Ltd., your privacy is our top priority. This Privacy Policy outlines
          how we collect, use, and protect your personal information when you use our one-day tour
          services and receive related travel information for Seoul and Korea.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            2. Information We Collect
          </Typography>
          To provide you with our services, we collect the following personal information:
          <ul>
            <li>
              <b>Name:</b>
              <br /> To personalize your tour experience and communicate with you effectively.
            </li>
            <li>
              <b>Contact Information:</b>
              <br /> Including your phone number and email address, to send you updates about your
              tour, order confirmations, and essential service-related information.
            </li>
            <li>
              <b>Delivery Address:</b>
              <br /> If you utilize our commerce services for same-day delivery, we require your
              delivery address.
            </li>
            <li>
              <b>Booking Information:</b>
              <br /> Collected from travel agencies or other platforms where the tour was reserved,
              used to process and store your booking details.
            </li>
            <li>
              <b>Usage and Interaction Data:</b>
              <br /> Includes device identifiers, product interaction data, advertising identifiers,
              and anonymized usage data collected through tools like Google Analytics, Hotjar, and
              AppsFlyer. This data is collected in compliance with applicable privacy regulations
              and Apple’s App Tracking Transparency policy. Tracking that uses identifiable data is
              only conducted if you provide explicit consent.
            </li>
          </ul>
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            3. How We Use Your Information
          </Typography>
          Your personal information is used for the following purposes:
          <ul>
            <li>
              <b>Tour Services:</b>
              <br /> To facilitate and enhance your one-day tour experience.
            </li>
            <li>
              <b>Travel Information:</b>
              <br /> To provide you with relevant information about Seoul and Korea, including
              travel tips, destination guides, and other useful content.
            </li>
            <li>
              <b>Commerce Services:</b>
              <br /> To process orders and arrange same-day deliveries related to your tour
              experience.
            </li>
            <li>
              <b>Service Notifications:</b>
              <br /> To send you push notifications related to your use of the chat service and
              commerce features. Note that we do not send marketing push notifications; all
              notifications are strictly service-related.
            </li>
            <li>
              <b>Non-Intrusive Marketing Content:</b>
              <br /> We may provide travel-related content in a magazine-style format, including
              store introductions and other informational material. This content is provided solely
              for informational purposes and does not involve direct marketing pushes.
            </li>
            <li>
              <b>Service Analysis and Optimization:</b>
              <br /> We use tools such as Google Analytics, Hotjar, and AppsFlyer to analyze service
              usage, track app installs, and improve the effectiveness of our services and marketing
              activities. Identifiable tracking only occurs with your consent in accordance with
              Apple’s App Tracking Transparency policy. Otherwise, all data collection is conducted
              in anonymized mode.
            </li>
          </ul>
        </Container>
        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            4. Data Collection and Consent
          </Typography>
          By using our services and voluntarily providing your information, you expressly consent to
          the collection, use, and processing of your personal information as outlined in this
          Privacy Policy. This includes information provided directly by you as well as information
          indirectly provided through third-party booking platforms or agencies.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            5. Data Retention
          </Typography>
          We retain your personal information for one year after the completion of your last tour or
          service with us. After this period, your data will be securely deleted unless required by
          law to retain it longer.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            6. Data Security
          </Typography>
          We use industry-standard security measures to protect your personal information from
          unauthorized access, disclosure, or misuse.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            7. Third-Party Data Sharing
          </Typography>
          We may share your personal information with third parties in the following circumstances:
          <ul>
            <li>
              <b>Payment Processing:</b>
              <br /> To facilitate payment transactions, your payment information may be shared with
              our payment processing partners.
            </li>
            <li>
              <b>Shipping and Delivery:</b>
              <br /> If you use our commerce services, your delivery information will be shared with
              our logistics partners to ensure the proper delivery of your order.
            </li>
            <li>
              <b>Service Analysis and Marketing Attribution:</b>
              <br /> We may share anonymized usage data with third-party analytics and marketing
              partners, including Google Analytics, Hotjar, and AppsFlyer, to analyze service usage,
              track app installs, and improve marketing effectiveness. All data sharing complies
              with applicable privacy laws and Apple’s App Tracking Transparency policy, and
              identifiable tracking data is only collected with your consent.
            </li>
          </ul>
          All third parties with whom we share your information are contractually obligated to
          protect your data in accordance with applicable privacy laws.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            8. Data Processing and Service Analysis
          </Typography>
          We use Google Analytics, Hotjar, and AppsFlyer to analyze service usage, track app
          installation sources, and improve your experience and our marketing activities. These
          tools collect data in a manner that does not personally identify you unless you have
          explicitly provided consent, ensuring that your privacy is maintained. The data is used
          solely for service optimization and is protected in accordance with industry standards.
          <br />
          <br />
          Google Analytics, Hotjar, and AppsFlyer may use cookies or device identifiers to collect
          this information. If you do not wish to have data collected by Google Analytics, you can
          use the{' '}
          <a href="https://tools.google.com/dlpage/gaoptout">
            Google Analytics Opt-out Browser Add-on
          </a>{' '}
          or block Hotjar data collection through their{' '}
          <a href="https://www.hotjar.com/policies/do-not-track/">Opt-out page</a>. AppsFlyer only
          collects identifiable data if you have provided consent in line with Apple’s App Tracking
          Transparency policy; otherwise, it operates in anonymized mode.
          <br />
          <br />
          The data collected is retained only as long as necessary for analysis purposes, after
          which it is securely deleted. We ensure that any third parties processing this data on our
          behalf comply with relevant privacy laws and protect your data accordingly.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            9. Your Rights
          </Typography>
          You have the right to access, correct, or delete your personal information at any time. If
          you wish to exercise any of these rights, or if you wish to opt-out of receiving any
          informational content, please contact us at support@kup.travel.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            10. Minor’s Privacy
          </Typography>
          We understand the importance of protecting minors' privacy. If a minor (under the age of
          14) uses our services, we may collect their personal information only with the explicit
          consent of their legal guardian. Any information collected without such consent will be
          promptly deleted upon discovery. Additionally, such information can be deleted upon the
          explicit request of the minor’s legal guardian. To request deletion, please contact us at
          support@kup.travel.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            11. Changes to This Policy
          </Typography>
          We may update this Privacy Policy from time to time. Any changes will be posted on our
          website. We encourage you to review this policy periodically to stay informed about how we
          are protecting your information. Continued use of our services after any changes
          constitutes your acceptance of the updated Privacy Policy.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            12. Contact Us
          </Typography>
          If you have any questions or concerns about this Privacy Policy, please contact us at
          support@kup.travel or visit our office at 12, Supyo-ro, Seoul, South Korea.
        </Container>
      </Container>
    </Page>
  );
}
