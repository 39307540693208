import { default as MuiRating, RatingProps as MuiRatingProps } from '@mui/material/Rating';
import IconEmptyStar from './Icon/IconEmptyStar';
import IconStar from './Icon/IconStar';
import IconBorderedEmptyStar from './Icon/IconBorderedEmptyStar';
import IconBorderedStar from './Icon/IconBorderedStar';

export type RatingProps = MuiRatingProps & {
  iconSize?: string;
  spacing?: string;
  bordered?: boolean;
};

export default function Rating(props: RatingProps) {
  const { iconSize = '16px', spacing = '4px', bordered = false, ...restProps } = props;

  return (
    <MuiRating
      icon={bordered ? <IconBorderedStar size={iconSize} /> : <IconStar size={iconSize} />}
      emptyIcon={
        bordered ? <IconBorderedEmptyStar size={iconSize} /> : <IconEmptyStar size={iconSize} />
      }
      {...restProps}
      sx={{ '&': { gap: spacing } }}
    />
  );
}
