import { useEffect, useState } from 'react';
import { getProductTag } from '~kup/controllers/product.ts';
import { ProductTag } from '~kup/models/Product.ts';
import * as Sentry from '@sentry/react';

export default function useTag(keyword: string) {
  const [tag, setTag] = useState<ProductTag | null>(null);
  useEffect(() => {
    getProductTag(keyword)
      .then((tag) => {
        return setTag(tag);
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }, [keyword]);

  return tag;
}
