import api from '~kup/api';
import { BasketResponse } from '~kup/models/Basket.ts';
import {
  Order,
  OrderHistory,
  OrderHistoryResponse,
  OrderStatus,
  OrderStatusResponse,
} from '~kup/models/Order.ts';
import { DeliveryAddress } from '../models/types';

const ORDER_PATH = {
  ORDERS: () => ['', 'orders'].join('/'),
  ORDER: (pathParams: string[] = []) => ['', 'orders', ...pathParams].join('/'),
  ORDER_IN_CART: (pathParams: string[] = []) => ['', 'orders/in-cart', ...pathParams].join('/'),
  REQUEST_CANCELLATION: (orderId: string) =>
    ['', 'admin/orders', orderId, 'request-cancellation'].join('/'),
  ORDER_ADDRESS: (orderId: string) => ['', 'orders', orderId, 'address'].join('/'),
  ORDER_CONTACT: (orderId: string) => ['', 'orders', orderId, 'contact'].join('/'),
  ORDER_STATUS: (orderId: string) => ['', 'orders', orderId, 'status'].join('/'),
};

type GetOrderHistoryParams = {
  pageSize: number;
  page: number;
};

type GetOrderHistoryResponse = {
  orderHistories: OrderHistory[];
  hasNextPage: boolean;
};

type OrderAddressData = {
  inputAddress: string;
  orderAddress: DeliveryAddress;
};

type ContactData = {
  channel: string;
  identifier: string;
};

export async function putBasketItem(basket: BasketResponse): Promise<BasketResponse> {
  const resp = await api.put(ORDER_PATH.ORDER_IN_CART(), basket);
  return resp.data.data;
}

export async function getBasket(): Promise<BasketResponse | null> {
  const resp = await api.get(ORDER_PATH.ORDER_IN_CART());
  return resp.data.data;
}

export async function updateOrderAddress(
  orderId: string,
  addressData: OrderAddressData
): Promise<void> {
  await api.patch(ORDER_PATH.ORDER_ADDRESS(orderId), addressData);
}

export async function updateOrderContact(orderId: string, contactData: ContactData): Promise<void> {
  await api.patch(ORDER_PATH.ORDER_CONTACT(orderId), contactData);
}

export async function getOrderHistories(
  params: GetOrderHistoryParams
): Promise<GetOrderHistoryResponse> {
  const config = { params };
  const resp = await api.get(ORDER_PATH.ORDERS(), config);
  const orderHistoryResponses: OrderHistoryResponse[] = resp.data.data;
  const hasNextPage: boolean = resp.data.meta.hasNext ?? false;
  return {
    orderHistories: orderHistoryResponses.map((r) => new OrderHistory(r)),
    hasNextPage,
  };
}

export async function getOrder(orderId: string): Promise<Order | null> {
  const resp = await api.get(ORDER_PATH.ORDER([orderId]));
  return resp.data.data ? new Order(resp.data.data) : null;
}

export async function cancelOrder(orderId: string): Promise<void> {
  const resp = await api.post(ORDER_PATH.REQUEST_CANCELLATION(orderId));
  return resp.data.data;
}

export async function getOrderStatus(orderId: string): Promise<OrderStatusResponse | null> {
  const resp = await api.get(ORDER_PATH.ORDER_STATUS(orderId));
  return resp.data.data ? new OrderStatus(resp.data.data) : null;
}
