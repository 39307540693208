import Typography from '~/components/Typography.tsx';
import i18n from '~/i18n.ts';
import useSomeDayCutOff from '../pages/home/hooks/useSomeDayCutOff.ts';
import { useKintStore } from '~kint/store';
import dayjs from 'dayjs';

export default function OnTourCouponTimeLimit() {
  const { reservation } = useKintStore((state) => ({
    reservation: state.reservation,
  }));
  const { cutOffRemains } = useSomeDayCutOff(dayjs(reservation?.date).toDate());
  return (
    <Typography variant="TitleM_B" sx={{ mt: '12px', textAlign: 'center', whiteSpace: 'pre-wrap' }}>
      {i18n.t('home-main-coupon-offer-ends', { time: cutOffRemains.text })}
    </Typography>
  );
}
