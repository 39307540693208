export type IconLikeProps = {
  width?: string;
  height?: string;
};
export default function IconLike(props: IconLikeProps) {
  const { width = '32px', height = '32px' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 20.9402C11.8033 20.9402 11.6032 20.9048 11.3995 20.8339C11.1958 20.7631 11.0168 20.6499 10.8623 20.4942L9.40825 19.1567C7.44158 17.34 5.71525 15.5859 4.22925 13.8942C2.74308 12.2025 2 10.4039 2 8.49844C2 6.97444 2.51108 5.70177 3.53325 4.68044C4.55558 3.65911 5.82225 3.14844 7.33325 3.14844C8.19442 3.14844 9.03333 3.34702 9.85 3.74419C10.6667 4.14152 11.3833 4.7596 12 5.59844C12.6722 4.7596 13.3999 4.14152 14.1833 3.74419C14.9666 3.34702 15.7944 3.14844 16.6668 3.14844C18.1778 3.14844 19.4444 3.65911 20.4668 4.68044C21.4889 5.70177 22 6.97444 22 8.49844C22 10.4039 21.2583 12.2039 19.775 13.8984C18.2917 15.5929 16.5611 17.3485 14.5833 19.1652L13.1333 20.4984C12.9806 20.6526 12.8035 20.7648 12.602 20.8349C12.4007 20.9051 12.2 20.9402 12 20.9402ZM11.25 7.36519C10.7888 6.59285 10.2222 5.97477 9.55 5.51094C8.87783 5.0471 8.13892 4.81519 7.33325 4.81519C6.26658 4.81519 5.38883 5.16235 4.7 5.85669C4.01117 6.55119 3.66675 7.43419 3.66675 8.50569C3.66675 9.43419 3.9855 10.4146 4.623 11.4469C5.2605 12.4791 6.023 13.4804 6.9105 14.4509C7.798 15.4214 8.71533 16.3262 9.6625 17.1652C10.6097 18.004 11.3888 18.704 12 19.2652C12.6112 18.7152 13.3903 18.0179 14.3375 17.1734C15.2847 16.3289 16.2013 15.4179 17.0875 14.4402C17.9737 13.4624 18.7362 12.4571 19.375 11.4244C20.0138 10.3918 20.3333 9.41644 20.3333 8.49844C20.3333 7.43177 19.9861 6.55119 19.2918 5.85669C18.5973 5.16235 17.7223 4.81519 16.6668 4.81519C15.8501 4.81519 15.1084 5.04435 14.4418 5.50269C13.7751 5.96102 13.2001 6.58185 12.7168 7.36519C12.6223 7.50952 12.5139 7.61502 12.3918 7.68169C12.2694 7.74835 12.1333 7.78169 11.9833 7.78169C11.8333 7.78169 11.6944 7.74835 11.5668 7.68169C11.4389 7.61502 11.3333 7.50952 11.25 7.36519Z"
        fill="currentColor"
      />
    </svg>
  );
}
