import { useState } from 'react';
import { getProductCollectionProducts } from '~/services/kup/controllers/productCollection';
import { ProductCollection } from '~/services/kup/models/ProductCollection';

type UseProductCollectionsProducts = {
  fetchProductCollection: (slug: string) => Promise<ProductCollection>;
  loading: boolean;
};

export default function useProductCollectionsProducts(): UseProductCollectionsProducts {
  const [loading, setLoading] = useState(false);

  const fetchProductCollection = async (slug: string) => {
    setLoading(true);
    const productCollection = await getProductCollectionProducts(slug);
    setLoading(false);
    return productCollection;
  };

  return { fetchProductCollection, loading };
}
