import ModalPopup from '~/components/ModalPopup.tsx';
import { useTranslation } from 'react-i18next';
import useKupStore from '~kup/store';
import Headline from '~/components/Headline.tsx';
import Container from '~/components/Container.tsx';
import Button from '~/components/Button.tsx';
import { useState } from 'react';

type InstallPopupProps = {
  postpone?: boolean;
  agencyCode?: string;
  name?: string;
};

export default function InstallPopup(props: InstallPopupProps) {
  const { postpone, agencyCode, name } = props;
  const { i18n } = useTranslation();
  const { stopAskInstallFrom, setStopAskInstallFrom } = useKupStore((state) => ({
    stopAskInstallFrom: state.stopAskInstallFrom,
    setStopAskInstallFrom: state.setStopAskInstallFrom,
  }));

  const [open, setOpen] = useState(
    !window?.ReactNativeWebView && stopAskInstallFrom < Date.now() - 6 * 60 * 60 * 1000
  );

  const handleOnClose = () => {
    setOpen(false);
    if (postpone) setStopAskInstallFrom(Date.now());
  };

  const goToInstall = () => {
    if (window) {
      const url = [
        'https://us-central1-kint-2.cloudfunctions.net/kupInstall',
        agencyCode ? `?agencyCode=${agencyCode}` : '',
        name ? `&name=${name}` : '',
      ]
        .filter((s) => !!s)
        .join('');
      window.open(url, '_blank')?.focus();
    }
  };

  return (
    <ModalPopup
      open={open}
      onClose={handleOnClose}
      fixedBottom={
        <Container
          py={'8px'}
          px={'20px'}
          stackProps={{
            column: true,
            alignItems: 'center',
          }}
        >
          <Button fullWidth onClick={goToInstall}>
            {
              i18n.t('common-goToInstall') // Agree and Start
            }
          </Button>
          <Button variant={'text'} size={'small'} color={'primary'} onClick={handleOnClose}>
            {i18n.t('common-keepSeeOnWeb')}
          </Button>
        </Container>
      }
    >
      <Container py={'16px'} px={'20px'}>
        <Headline
          size={'large'}
          textAlign={'center'}
          headline={i18n.t('common-UseItMoreConveniently')}
          description={i18n.t('common-installAppDescription')}
          descriptionProps={{
            whiteSpace: 'pre-wrap',
          }}
          gap={'8px'}
        />
      </Container>
    </ModalPopup>
  );
}
