import dayjs from 'dayjs';
import { useRef } from 'react';
import { IChatting } from '~kint/models/Chatting.ts';
import { useNavigate } from 'react-router-dom';
import useChattingMessageCount from '~/pages/message/chat/hooks/useChattingMessageCount.ts';
import Typography from '~/components/Typography.tsx';
import Stack from '~/components/Stack.tsx';
import ButtonBase from '~/components/ButtonBase';
import Box from '~/components/Box';
import Avatar from '~/components/Avatar.tsx';
import { useTranslation } from 'react-i18next';

export default function ChatListItem(props: { chat: IChatting; highlight?: boolean }) {
  const { chat, highlight } = props;
  const ref = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { notification } = useChattingMessageCount(chat);
  const lastMessageDayjs = dayjs(chat.lastMessage?.date.toDate());
  const fromThan = lastMessageDayjs.diff(dayjs(), 'minutes');
  const fromThanExpression =
    fromThan > -60
      ? `${Math.abs(fromThan)} ${i18n.t('component-chattingListItem-mins')}`
      : fromThan < -24 * 60
        ? `${Math.abs(Math.floor(fromThan / 60 / 24))} ${i18n.t('component-chattingListItem-days')}`
        : `${Math.abs(Math.floor(fromThan / 60))} ${i18n.t('component-chattingListItem-hours')}`;
  const lastMessage = (chat.lastMessage?.text ?? '...').slice(0, 60);
  const lastMessageSenderName =
    chat.lastMessage?.sender?.type?.toLowerCase() === 'guide'
      ? `${chat.lastMessage?.sender?.nameEn}(Guide)`
      : (chat.lastMessage?.sender?.nameEn ?? chat.lastMessage?.sender?.name);
  const handleClick = () => navigate(`/message/chatting/${chat.id}`);

  return (
    <ButtonBase
      ref={ref}
      sx={(theme) => ({
        width: '100%',
        py: '14px',
        px: '20px',
        borderRadius: '12px',
        border: highlight ? `solid 2px ${theme.palette.secondary.main}` : 'none',
      })}
      onClick={handleClick}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={'14px'}
        sx={{
          width: '100%',
        }}
      >
        {chat?.lastMessage?.sender?.type?.toLowerCase() === 'guide' ? (
          <Avatar
            variant={'rounded'}
            sx={(theme) => ({
              width: '32px',
              height: '32px',
              borderRadius: '4px',
              backgroundColor: theme.palette.primary.main,
            })}
          >
            G
          </Avatar>
        ) : null}
        <Stack
          column
          gap={'4px'}
          sx={{
            width: '100%',
          }}
        >
          {
            <Box display={'flex'} flexDirection={'row'}>
              <Typography
                variant={'Caption_B'}
                color={chat.category.startsWith('CS') ? 'error' : 'primary'}
              >
                {chat.category.startsWith('CS')
                  ? i18n.t('component-chattingListItem-oneOnOneSupport')
                  : i18n.t('component-chattingListItem-tourChat')}
              </Typography>
            </Box>
          }
          <Stack row gap={'4px'} alignItems={'center'}>
            <Typography
              component={'p'}
              variant={'BaseS_B'}
              overflow={'hidden'}
              textAlign={'left'}
              textOverflow={'ellipsis'}
              sx={{ maxLines: 2, flex: 1 }}
            >
              {lastMessageSenderName}
            </Typography>
            <Typography
              variant={'Caption'}
              color={'typography.tertiary'}
              flexShrink={0}
              flexGrow={0}
              sx={{ flexShrink: 0, flexGrow: 0 }}
            >
              {fromThanExpression}
            </Typography>
          </Stack>
          <Stack
            row
            justifyContent={'stretch'}
            alignItems={'center'}
            gap={'4px'}
            sx={{
              width: '100%',
            }}
          >
            <Typography
              variant={'BaseS_B'}
              color={'typography.secondary'}
              overflow={'hidden'}
              textAlign={'left'}
              textOverflow={'ellipsis'}
              sx={{ maxLines: 2, flex: 1 }}
              component={'p'}
            >
              {lastMessage}
            </Typography>
            {notification > 0 && (
              <Typography
                component={'span'}
                variant={'Caption_B'}
                sx={(theme) => ({
                  ...theme.typography.Caption,
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  maxWidth: '18px',
                  minWidth: '18px',
                  maxHeight: '18px',
                  minHeight: '18px',
                  background: theme.palette.error.main,
                  borderRadius: '100%',
                  color: theme.palette.error.contrastText,
                  marginLeft: '8px',
                })}
              >
                {notification}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </ButtonBase>
  );
}

// export default function ChatListItem(props: { chat: IChatting }) {
//   const {chat,} = props;
//   const ref = useRef<HTMLButtonElement>(null);
//   const navigate = useNavigate();
//   const {all, read, unread} = useChattingMessageCount(chat);
//   const unreadCount = read === 0 ? all : unread;
//   const lastMessageDayjs = dayjs(chat.lastMessage?.date.toDate());
//   const fromThan = lastMessageDayjs.diff(dayjs(), 'minutes');
//   const fromThanExpression = fromThan > -60 ? `${Math.abs(fromThan)} mins` : fromThan < (-24 * 60) ? `${Math.abs(Math.floor(fromThan / 60 / 24))} days` : `${Math.abs(Math.floor(fromThan / 60))} hours`;
//   const lastMessage = (chat.lastMessage?.text ?? '...').slice(0, 60);
//   const lastMessageSenderName = chat.lastMessage?.sender?.type?.toLowerCase() === 'guide' ? `${chat.lastMessage?.sender?.nameEn}(Guide)` : chat.lastMessage?.sender?.nameEn ?? chat.lastMessage?.sender?.name;
//   const handleClick = () => navigate(`/message/chatting/${chat.id}`);
//
//   return (
//       <ButtonBase
//           ref={ref}
//           sx={({
//             width: '100%',
//             py: '12px',
//             px: '20px',
//           })}
//           onClick={handleClick}
//       >
//         <Stack
//             direction={'column'}
//             alignItems={'flex-start'}
//             justifyContent={'stretch'}
//             gap={'4px'}
//             sx={{
//               width:'100%'
//             }}
//         >
//
//           <Box
//               display={'flex'}
//               flexDirection={'row'}
//               justifyContent={'flex-start'}
//           >
//
//             <Typography variant={'Caption_B'} color={chat.category.startsWith('CS') ? 'error' : 'primary'}>
//               {
//                 chat.category.startsWith('CS')
//                     ? '1:1 Support Chat'
//                     : 'Tour Chat'
//               }
//             </Typography>
//           </Box>
//           <Stack
//               direction={'row'}
//               justifyContent={'stretch'}
//               alignItems={'center'}
//               gap={'14px'}
//               sx={{
//                 width:'100%'
//               }}
//           >
//             {
//               chat?.lastMessage?.sender?.type?.toLowerCase() === 'guide'
//                   ?
//                   <Avatar
//                       variant={'rounded'}
//                       sx={(theme) => ({
//                         width: '32px',
//                         height: '32px',
//                         borderRadius: '4px',
//                         backgroundColor:theme.palette.primary.main,
//                       })}
//                   >
//                     G
//                   </Avatar>
//                   : null
//             }
//             <Stack
//                 column
//                 gap={'4px'}
//                 sx={{
//                   width: '100%',
//                 }}
//             >
//               <Stack
//                   row
//                   gap={'4px'}
//                   alignItems={'center'}
//               >
//                 <Typography
//                     component={'p'}
//                     variant={'BaseS_B'}
//                     overflow={'hidden'}
//                     textAlign={'left'}
//                     textOverflow={'ellipsis'}
//                     sx={{maxLines: 2, flex: 1}}
//                 >
//                   {lastMessageSenderName}
//                 </Typography>
//                 <Typography variant={'Caption'} color={'typography.tertiary'} flexShrink={0} flexGrow={0}
//                             sx={{flexShrink: 0, flexGrow: 0,}}>
//                   {fromThanExpression}
//                 </Typography>
//               </Stack>
//               <Stack
//                   row
//                   justifyContent={'stretch'}
//                   alignItems={'center'}
//                   gap={'4px'}
//                   sx={{
//                     width: '100%',
//                   }}
//               >
//                 <Typography
//                     variant={'BaseS_B'}
//                     color={'typography.secondary'}
//                     overflow={'hidden'}
//                     textAlign={'left'}
//                     textOverflow={'ellipsis'}
//                     sx={{maxLines: 2, flex: 1}}
//                     component={'p'}>
//                   {lastMessage}
//                 </Typography>
//                 {
//                     unreadCount > 0 && (
//                         <Typography
//                             component={"span"}
//                             variant={"Caption_B"}
//                             sx={(theme) => ({
//                               ...theme.typography.Caption,
//                               display: "inline-flex",
//                               alignItems: "center",
//                               justifyContent: "center",
//                               maxWidth: "18px",
//                               minWidth: "18px",
//                               maxHeight: "18px",
//                               minHeight: "18px",
//                               background: theme.palette.error.main,
//                               borderRadius: "100%",
//                               color: theme.palette.error.contrastText,
//                               marginLeft: "8px",
//                             })}
//                         >
//                           {unreadCount}
//                         </Typography>
//                     )
//                 }
//               </Stack>
//             </Stack>
//           </Stack>
//         </Stack>
//       </ButtonBase>
//   )
// }
