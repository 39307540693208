import dayjs from 'dayjs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DiscoveryPlaceSimple, DiscoveryProductSimple } from '~kup/models/Discovery.ts';
import Page from '~/components/Page';
import useDiscovery from '~/pages/discovery/hooks/useDiscovery.ts';
import Container from '~/components/Container.tsx';
import Typography from '~/components/Typography.tsx';
import NaverMap from '~/components/NaverMap.tsx';
import ImageView from '~/components/ImageView.tsx';
import Divider from '~/components/Divider.tsx';
import HTML from '~/components/HTML.tsx';
import { useLayoutEffect } from 'react';
import Logo from '~/components/Logo';
import Button from '~/components/Button.tsx';
import Carousel from '~/components/Carousel.tsx';
import { useTranslation } from 'react-i18next';

export default function DiscoveryContentPage() {
  const { discoveryId } = useParams();
  const navigate = useNavigate();
  const { discovery } = useDiscovery(discoveryId ?? '');
  useLayoutEffect(() => {
    if (!discoveryId) return navigate(-1);
  }, [discoveryId]);

  if (!discovery) return null;
  return (
    <Page
      type={'page'}
      navigationBarProps={{
        center: (
          <Link
            to={'/discovery'}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Logo color={'typography.tertiary'} height={'20px'}>
              discovery
            </Logo>
          </Link>
        ),
      }}
    >
      <DiscoveryContentHeader
        title={discovery.title}
        date={dayjs(discovery.updatedAt).format('YYYY.MM.DD')}
        description={discovery.description}
        places={discovery.places}
        images={discovery.images}
        mapZoom={discovery.mapZoom}
      />
      <Divider type={'box'} />
      {discovery.html && (
        <>
          <DiscoveryContentHTML html={discovery.html} />
          <Divider type={'box'} />
        </>
      )}
      {discovery.places && discovery.places.length > 0 && (
        <>
          <DiscoveryContentPlaces places={discovery.places} />
        </>
      )}
      {discovery.products && discovery.products.length > 0 && (
        <>
          <DiscoveryContentProducts products={discovery.products} />
        </>
      )}
    </Page>
  );
}

type DiscoveryContentHeaderProps = {
  title: string;
  description: string;
  date: string;
  images: string[];
  places?: DiscoveryPlaceSimple[];
  mapZoom?: number;
};

function DiscoveryContentHeader(props: DiscoveryContentHeaderProps) {
  const { title, date, description, places, images, mapZoom } = props;
  const markers = (places ?? []).map(({ latlng }, idx) => ({
    label: idx + 1 + '',
    lat: latlng[0],
    lng: latlng[1],
  }));
  return (
    <Container>
      <Container>
        {places && places.length > 0 && (
          <Container>
            <NaverMap
              zoom={mapZoom ?? 13}
              centerMarker={false}
              center={{ lat: places[0].latlng[0], lng: places[0].latlng[1] }}
              placeName={title}
              markers={markers}
              containerStyles={{ width: '100%', height: 'calc(100vh - 250px)' }}
            />
          </Container>
        )}
        {(!places || places.length === 0) && <Carousel images={images} />}
      </Container>
      <Container px={'20px'} py={'16px'}>
        <Typography variant={'TitleM_B'} display={'block'}>
          {title}
        </Typography>
        <Typography variant={'Caption'} color={'text.secondary'}>
          {date}
        </Typography>
      </Container>
      <Container px={'20px'} py={'8px'}>
        <Typography variant={'BaseS'} whiteSpace={'pre-wrap'}>
          {description}
        </Typography>
      </Container>
    </Container>
  );
}

type DiscoveryContentHTMLProps = {
  html: string;
};

function DiscoveryContentHTML(props: DiscoveryContentHTMLProps) {
  return (
    <Container py={'16px'} px={'20px'}>
      <HTML>{props.html}</HTML>
    </Container>
  );
}

type DiscoveryProductsProps = {
  products: DiscoveryProductSimple[];
};

function DiscoveryContentProducts(props: DiscoveryProductsProps) {
  const products = props.products;
  const { i18n } = useTranslation();
  if (products.length === 0) return null;
  return (
    <Container
      py={'16px'}
      stackProps={{
        flexDirection: 'column',
        gap: '12px',
      }}
    >
      <Container px={'20px'}>
        <Typography variant={'TitleS_B'}>
          {i18n.t('discovery-content-bestPriceGuarantee')}
        </Typography>
      </Container>
      {products.map((p) => {
        return <DiscoveryContentProduct key={p.id} product={p} />;
      })}
    </Container>
  );
}

function DiscoveryContentProduct({ product }: { product: DiscoveryProductSimple }) {
  const { i18n } = useTranslation();
  const tags = product.tags;
  const name = product.name;
  const image = product.images[0];
  const description = product.description;
  const url = product.url;
  return (
    <Container
      px={'20px'}
      stackProps={{
        flexDirection: 'column',
      }}
    >
      {image && <ImageView src={image} width={'100%'} />}
      <Container pt={'16px'}>
        <Typography variant={'Caption'} color={'text.secondary'}>
          {(tags ?? []).filter((s) => !!s).join(' • ')}
        </Typography>
        <Typography variant={'BaseM_B'} display={'block'}>
          {name}
        </Typography>
        <Typography>{description}</Typography>
        <Button
          fullWidth
          component={Link}
          to={url}
          variant={'outlined'}
          sx={{
            marginTop: '16px',
          }}
        >
          {i18n.t('discovery-content-checkShopNow')}
        </Button>
      </Container>
    </Container>
  );
}

type DiscoveryPlacesProps = {
  places: DiscoveryPlaceSimple[];
};

function DiscoveryContentPlaces(props: DiscoveryPlacesProps) {
  const places = props.places;
  if (places.length === 0) return null;
  return (
    <Container
      py={'16px'}
      stackProps={{
        flexDirection: 'column',
        gap: '12px',
      }}
    >
      {places.map((p) => {
        return <DiscoveryContentPlace key={p.id} place={p} />;
      })}
    </Container>
  );
}

function DiscoveryContentPlace({ place }: { place: DiscoveryPlaceSimple }) {
  const { i18n } = useTranslation();
  const name = place.name;
  const url = place.url;
  const image = place.images[0];
  const description = place.description;
  const priceLevel = place.priceLevel;
  const tags = place.tags;
  return (
    <Container
      px={'20px'}
      stackProps={{
        flexDirection: 'column',
      }}
    >
      <Container py={'16px'}>
        <Typography variant={'Caption'} color={'text.secondary'}>
          {[new Array(priceLevel ?? 0).fill('$').join('')]
            .concat(tags ?? [])
            .filter((s) => !!s)
            .join(' • ')}
        </Typography>
        <Typography variant={'TitleS_B'} display={'block'}>
          {name}
        </Typography>
      </Container>
      {image && <ImageView src={image} width={'100%'} />}
      <Container pt={'16px'}>
        <Typography>{description}</Typography>
        <Link to={url} style={{ textDecoration: 'underline', color: 'inherit' }}>
          <Typography variant={'BaseS_B'}>{i18n.t('discovery-content-readMore')}</Typography>
        </Link>
      </Container>
    </Container>
  );
}
