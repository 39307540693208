import IconButton from '~/components/IconButton';
import Page from '~/components/Page';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Tab } from '@mui/material';
import Headline from '~/components/Headline';
import i18n from '~/i18n';
import Tabs from '~/components/Tabs';
import { useEffect, useState } from 'react';
import Stack from '~/components/Stack';
import CouponsInPossession from './components/CouponsInPossession';
import CouponsToGetIt from './components/CouponsToGetIt';
import BasketIconWithCount from '~/components/BasketIconWithCount.tsx';
import LikeIconWithCount from '~/components/LikeIconWithCount.tsx';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function MyLikes() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [selectedTabIndex, setSelectedTabIndex] = useState(tab === 'in-possession' ? 0 : 1);

  useEffect(() => {
    setSelectedTabIndex(tab === 'in-possession' ? 0 : 1);
  }, [tab]);

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
    navigate(`/shop/coupons?tab=${newValue === 0 ? 'in-possession' : 'get-it'}`, { replace: true });
  };

  return (
    <Page
      type={'page'}
      navigationBarProps={{
        lead: (
          <>
            <IconButton
              touchArea={'16px'}
              iconProps={{
                width: '20px',
                color: 'typography.tertiary',
              }}
              onClick={() => navigate('/shop/orders')}
            >
              back
            </IconButton>
          </>
        ),
        trail: (
          <Stack height={'20px'} flexDirection={'row'} gap={'12px'}>
            <BasketIconWithCount />
            <LikeIconWithCount />
          </Stack>
        ),
        background: 'transparent',
      }}
    >
      <Stack py={'20px'} gap={'12px'}>
        <Stack px={'20px'} gap={'12px'}>
          <Headline size={'large'} headline={i18n.t('common-my-coupon')} />
          <Tabs
            tabLabelVariant={'BaseS'}
            type={'toggle'}
            value={selectedTabIndex}
            onChange={handleChangeTab}
            tabs={[
              {
                label: i18n.t('shop-my-coupon-in-possession'),
                value: 0,
              },
              {
                label: i18n.t('shop-my-coupon-get-it'),
                value: 1,
              },
            ]}
          >
            <Tab label={'in-possession'} {...a11yProps(0)} />
            <Tab label={'get-it'} {...a11yProps(1)} />
          </Tabs>
        </Stack>
        <CustomTabPanel value={selectedTabIndex} index={0}>
          <CouponsInPossession />
        </CustomTabPanel>
        <CustomTabPanel value={selectedTabIndex} index={1}>
          <CouponsToGetIt />
        </CustomTabPanel>
      </Stack>
    </Page>
  );
}
