import { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Page from '~/components/Page';
import Spotlight from '~/components/Spotlight.tsx';
import Button from '~/components/Button.tsx';
import Icon from '~/components/Icon';

import { useTranslation } from 'react-i18next';
import FillBox from '~/components/FillBox';
import Typography from '~/components/Typography';
import Stack from '~/components/Stack';
import useSyncShopping from '~/hooks/useSyncShopping.ts';

export default function ShopOrderFailPage() {
  useSyncShopping();

  const { i18n } = useTranslation();
  const [remainTime, setRemainTime] = useState<number>(5);
  const basketLink = '/shop/basket';

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRemainTime((prevState) => prevState - 1);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [remainTime]);

  if (remainTime <= 0) return <Navigate to={basketLink} replace />;

  return (
    <Page
      type={'modal'}
      name={
        i18n.t('shop-order-order') // Order
      }
      backgroundColor={'paper'}
    >
      <Spotlight
        textAlign={'center'}
        sx={{ px: '20px' }}
        lead={
          <Icon
            type={'circle'}
            size={'40px'}
            width={'48px'}
            backgroundColor={'typography.tertiary'}
          >
            priority_high
          </Icon>
        }
        headline={i18n.t('shop-order-paymentFailedDescription')}
        description={i18n.t('shop-order-paymentFailedSubDescription')}
        descriptionProps={{
          whiteSpace: 'pre-wrap',
        }}
        trail={
          <Stack justifyContent={'center'} alignItems={'center'} gap={5} sx={{ px: '20px' }}>
            <Button
              component={Link}
              to={basketLink}
              size="small"
              variant="contained"
              color="fill"
              sx={{ minWidth: '180px' }}
            >
              {i18n.t('shop-order-backToBasketIn', { remainTime })}
            </Button>
            <FillBox color={'fill'} display={'flex'} flexDirection={'column'}>
              <Typography variant={'BaseM_B'} component={'p'}>
                {i18n.t('shop-order-paymentNotice')}
              </Typography>
              <Typography variant={'FootNote_B'} component={'p'}>
                • {i18n.t('shop-order-corporateCardNotSupported')}
              </Typography>
              <Typography variant={'FootNote'} component={'p'}>
                • {i18n.t('shop-order-paymentRestricted')}
              </Typography>
              <Typography variant={'FootNote'} component={'p'}>
                • {i18n.t('shop-order-paymentFailedTryAnother')}
              </Typography>
              <Typography variant={'FootNote'} component={'p'}>
                • {i18n.t('shop-order-frequentTransactionsFlagged')}
              </Typography>
            </FillBox>
          </Stack>
        }
      />
    </Page>
  );
}
