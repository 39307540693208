import { useParams } from 'react-router-dom';
import ImageView from '~/components/ImageView';
import Page from '~/components/Page';
import Stack from '~/components/Stack';
import Typography from '~/components/Typography';
import { ProductBrand } from '~/services/kup/models/ProductBrand';
import ProductsInBrand from './components/ProductsInBrand';
import LikeButton from '~/components/LikeButton';
import { Grid } from '@mui/material';
import Divider from '~/components/Divider';
import {
  getProductBrandDetail,
  likeProductBrand,
  unlikeProductBrand,
} from '~/services/kup/controllers/productBrand';
import { useEffect, useState } from 'react';
import { theme } from '~/theme';
import { FavoriteBorder } from '@mui/icons-material';
import i18n from '~/i18n';
import useBatchLogger from '~/hooks/useBatchLogger';
import ProductsInBrandCollections from './components/ProductsInBrandCollections';
import BasketIconWithCount from '~/components/BasketIconWithCount.tsx';
import LikeIconWithCount from '~/components/LikeIconWithCount.tsx';
import useKupStore from '~kup/store';

export default function ShopBrandDetailPage() {
  const { logAction } = useBatchLogger();
  const brandId = useParams().brandId!;
  const [brand, setBrand] = useState<ProductBrand | null>(null);
  const { toggleBrandLike, brandLikeDict } = useKupStore((state) => ({
    toggleBrandLike: state.toggleBrandLike,
    brandLikeDict: state.brandLikeDict,
  }));

  if (brand) brand.isLiked = brand.id in brandLikeDict;

  useEffect(() => {
    getProductBrandDetail(brandId).then((brand) => {
      setBrand(brand);
    });
  }, [brandId]);

  const handleLike = async (liked: boolean) => {
    if (brand === null) return;
    if (liked) {
      await unlikeProductBrand(brand.id).then(() => {
        setBrand({ ...brand, isLiked: false, likeCount: brand.likeCount - 1 });
      });
    } else {
      logAction({
        eventType: 'CLICK',
        eventTarget: 'BRAND_LIKE',
        eventCategory: 'brand',
        eventData: { id: brand.id, name: brand.name },
      });
      await likeProductBrand(brand.id).then(() => {
        setBrand({ ...brand, isLiked: true, likeCount: brand.likeCount + 1 });
      });
    }
    toggleBrandLike(brand.id, !liked);
  };
  if (brand === null) return null;

  return (
    <Page
      type={'page'}
      navigationBarProps={{
        trail: (
          <Stack row alignItems={'center'} gap={2}>
            <BasketIconWithCount />
            <LikeIconWithCount />
          </Stack>
        ),
      }}
    >
      {brand.likeCount > 0 && (
        <Stack
          row
          gap={1}
          alignItems={'center'}
          sx={{ height: '38px', backgroundColor: theme.palette.fill.primary, opacity: '0.6' }}
          justifyContent={'center'}
        >
          <FavoriteBorder sx={{ fontSize: '16px' }} />
          <Typography variant={'FootNote_B'}>
            {i18n.t('common-like-count-sentence', { count: brand.likeCount })}
          </Typography>
        </Stack>
      )}
      <Stack gap={2}>
        {brand.images?.length > 0 && (
          <ImageView borderRadius={'0px'} src={brand.images[0]} height={'100%'} width={'100%'} />
        )}
        <Stack gap={5} sx={{ px: '20px' }}>
          <Stack gap={1}>
            <Typography variant={'TitleM_B'}>{brand.name}</Typography>
            <Grid container alignItems={'center'}>
              <Grid item xs={11}>
                <Typography variant={'FootNote'}>{brand.description}</Typography>
              </Grid>
              <Grid item xs={1}>
                <LikeButton liked={brand.isLiked} type={'square'} onClick={handleLike} />
              </Grid>
            </Grid>
          </Stack>
        </Stack>
        <Divider type="line" thickness="5px" />
        <Stack gap={5} sx={{ px: '20px' }}>
          <ProductsInBrandCollections brandId={brand.id} />
        </Stack>
        <ProductsInBrand brandSlug={brand.slug} />
      </Stack>
    </Page>
  );
}
