import { Order, OrderResponse } from './Order';
import { Product, ProductResponse } from './Product';

export interface ProductReviewResponse {
  id?: string;
  reviewerName?: string;
  order?: OrderResponse;
  orderId?: string;
  product: ProductResponse;
  productId: string;
  productOptionNames?: string[];
  comment?: string;
  rating?: number;
  images?: string[];
  sourceUrl?: string | null;
  platform?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export class ProductReview {
  id?: string;
  reviewerName?: string;
  order?: Order;
  orderId?: string;
  product?: Product;
  productId: string;
  productOptionNames?: string[];
  comment?: string;
  rating?: number;
  images?: string[];
  sourceUrl?: string | null;
  platform?: string;
  createdAt?: Date;
  updatedAt?: Date;

  constructor(raw: ProductReviewResponse) {
    this.id = raw.id;
    this.reviewerName = raw.reviewerName;
    this.order = raw.order ? new Order(raw.order) : undefined;
    this.orderId = raw.orderId;
    this.product = new Product(raw.product);
    this.productId = raw.productId;
    this.productOptionNames = raw.productOptionNames;
    this.comment = raw.comment;
    this.rating = raw.rating;
    this.images = raw.images;
    this.sourceUrl = raw.sourceUrl;
    this.platform = raw.platform;
    this.createdAt = raw.createdAt;
    this.updatedAt = raw.updatedAt;
  }
}
