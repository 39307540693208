import { useEffect, useState } from 'react';
import { getProductCollectionGroup } from '~/services/kup/controllers/productCollection';
import { ProductCollectionGroup } from '~kup/models/ProductCollection';

type UseProductCollections = {
  collectionGroup: ProductCollectionGroup | null;
  loading: boolean;
};

type ProductCollectionGroupSearchOptions = {
  slug: string;
};

export default function useProductCollections(
  searchOptions: ProductCollectionGroupSearchOptions
): UseProductCollections {
  const [loading, setLoading] = useState(false);
  const [collectionGroup, setCollectionGroup] = useState<ProductCollectionGroup | null>(null);

  useEffect(() => {
    setLoading(true);
    getProductCollectionGroup(searchOptions.slug)
      .then((productCollectionGroup) => {
        setCollectionGroup(productCollectionGroup);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchOptions.slug]);

  return { collectionGroup, loading };
}
