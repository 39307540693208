import { Typography } from '@mui/material';
import ProductItem from '~/components/ProductItem';
import Stack from '~/components/Stack';
import useBatchLogger from '~/hooks/useBatchLogger';
import useProductBrandCollections from '~/hooks/useProductBrandCollections';
import { Product } from '~/services/kup/models/Product';
import { ProductCollection } from '~/services/kup/models/ProductCollection';
import { extractProductEventData } from '~/utils/product';

type ProductsInBrandCollectionsProps = {
  brandId: string;
};

export default function ProductsInBrandCollections(props: ProductsInBrandCollectionsProps) {
  const { brandId } = props;
  const { logAction } = useBatchLogger();
  const { collections } = useProductBrandCollections({
    brandId: brandId,
    orderBy: 'orderWeight',
  });

  const handleClickProductItem = (product: Product, collection: ProductCollection) => {
    logAction({
      eventType: 'CLICK',
      eventTarget: 'BRAND_PRODUCT',
      eventCategory: 'product',
      eventData: extractProductEventData(product, {
        slug: collection.slug,
        name: collection.name,
        type: 'brand',
      }),
    });
  };

  const handleLikeProduct = (product: Product, collection: ProductCollection) => {
    if (!product.isLiked) {
      logAction({
        eventType: 'CLICK',
        eventTarget: 'BRAND_PRODUCT_LIKE',
        eventCategory: 'product',
        eventData: extractProductEventData(product, {
          slug: collection.slug,
          name: collection.name,
          type: 'brand',
        }),
      });
    }
  };

  return (
    <>
      {collections.map((collection) => (
        <Stack key={collection.slug} gap={1}>
          <Typography variant={'BaseM_B'}>{collection.name}</Typography>
          <Stack row justifyContent={'space-evenly'} gap={'16px'} sx={{ overflowX: 'auto' }}>
            {collection.collectionItems?.map((item) => {
              const product = item.product;
              if (!product) return null;
              return (
                <ProductItem
                  type="medium"
                  key={item.product?.id}
                  product={item.product!}
                  sx={{ flex: 1 }}
                  onClickProductItem={(product) => handleClickProductItem(product, collection)}
                  onLikeProductItem={(product) => handleLikeProduct(product, collection)}
                />
              );
            })}
          </Stack>
        </Stack>
      ))}
    </>
  );
}
