import useKupStore from '~kup/store';
import { useNavigate } from 'react-router-dom';
import Page from '~/components/Page';
import Container from '~/components/Container.tsx';
import Headline from '~/components/Headline.tsx';
import Typography from '~/components/Typography.tsx';
import Area, { BaseArea } from '~/components/Area';
import Card from '~/components/Card.tsx';
import IconButton from '~/components/IconButton.tsx';
import Stack from '~/components/Stack.tsx';
import ImageView from '~/components/ImageView.tsx';
import Button from '~/components/Button.tsx';
import Badge from '~/components/Badge.tsx';
import dayjs from 'dayjs';
import useOrderHistories from '~/pages/shop/hooks/useOrderHistory.ts';
import { OrderHistory } from '~kup/models/Order.ts';
import { ORDER_STATUS_GUIDANCE_MAP } from '~/pages/shop/constants';
import { useTranslation } from 'react-i18next';
import HeaderMenu from './components/HeaderMenu';
import { useState } from 'react';
import OrderItemReviewSelection from './components/OrderItemReviewSelection';

function OrderHistoryItem({
  data,
  onClickWriteReview,
}: {
  data: OrderHistory;
  onClickWriteReview?: (order: OrderHistory) => void;
}) {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { currency } = useKupStore((state) => ({ currency: state.currency }));
  const orderTitle = data.orderItems.map((item) => item.productName).join(' / ');
  const orderStatusTag = ORDER_STATUS_GUIDANCE_MAP[data.orderStatus]?.tag;
  const hasNotReviewedOrderItem = data.orderItems.some((item) => !item.isReviewed);

  const handleWriteReview = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClickWriteReview?.(data);
  };

  return (
    <Card sx={{ marginBottom: 3, boxShadow: 'none', backgroundColor: '#f5f7fa' }}>
      <BaseArea
        bottom
        headerContainerProps={{ py: '0px', px: '0px' }}
        title={
          <Stack row gap={'6px'}>
            <Typography variant={'body2'}>{dayjs(data.orderedAt).format('YYYY.MM.DD')}</Typography>
            <Badge color={orderStatusTag?.includes('CANCEL') ? 'error' : 'primary'} size={'small'}>
              {orderStatusTag ?? '???'}
            </Badge>
          </Stack>
        }
        trail={
          <IconButton
            disableRipple
            color={'typography.tertiary'}
            iconProps={{
              width: '24px',
              size: '1.25em',
            }}
          >
            chevron_right
          </IconButton>
        }
        onClick={() => {
          navigate(data.orderId);
        }}
      >
        <Container pt={'18px'}>
          <Stack row gap={'14px'} alignItems={'center'}>
            <ImageView
              borderRadius={'6px'}
              width={'60px'}
              height={'60px'}
              src={data.orderItems[0].productImageUrls[0]}
            />
            <Headline
              sx={{ flex: 1 }}
              subHeadline={
                i18n.t('shop-myOrder-itemCount', { itemCount: data.orderItems.length }) // {data.orderItems.length} items
              }
              headline={orderTitle}
              headlineProps={{
                sx: {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2, // 최대 라인 수
                  WebkitBoxOrient: 'vertical',
                },
              }}
              description={data.orderAmount.toLocaleString() + ' ' + currency}
            />
          </Stack>
        </Container>
        {hasNotReviewedOrderItem && (
          <Container pt={'12px'}>
            <Button
              fullWidth
              color={'secondary'}
              size={'small'}
              sx={{ flex: 0 }}
              onClick={handleWriteReview}
            >
              Write Review
            </Button>
          </Container>
        )}
        {/*<Container pt={'6px'}>*/}
        {/*  <Button*/}
        {/*    fullWidth*/}
        {/*    color={'fill'}*/}
        {/*    size={'small'}*/}
        {/*    sx={{ flex: 0 }}*/}
        {/*    onClick={() => {*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Order Cancel*/}
        {/*  </Button>*/}
        {/*</Container>*/}
      </BaseArea>
    </Card>
  );
}

export default function ShopMyOrdersPage() {
  const { orderHistories, hasNextPage, next } = useOrderHistories({
    pageSize: 6,
    continuous: true,
  });
  const { i18n } = useTranslation();
  const [openItemReviewSelection, setOpenItemReviewSelection] = useState<boolean>(false);
  const [selectedOrderHistory, setSelectedOrderHistory] = useState<OrderHistory | null>(null);

  return (
    <Page type={'page'}>
      <Stack py={'20px'} gap={'12px'}>
        <Container px={'20px'}>
          <Headline
            size={'large'}
            headline={
              i18n.t('shop-myOrder-myOrders') // My Orders
            }
          />
        </Container>
        <Container px={'20px'}>
          <HeaderMenu />
        </Container>
        <Area
          title={i18n.t('shop-myOrder-orderHistory')} // Order History
          containerProps={{
            py: '8px',
            px: '20px',
          }}
        >
          {orderHistories.map((orderHistory, idx) => (
            <OrderHistoryItem
              key={idx}
              data={orderHistory}
              onClickWriteReview={() => {
                setSelectedOrderHistory(orderHistory);
                setOpenItemReviewSelection(true);
              }}
            />
          ))}
          {hasNextPage && (
            <Container py={'8px'}>
              <Button
                fullWidth
                size={'small'}
                color={'fill'}
                variant={'contained'}
                onClick={() => next()}
              >
                {
                  i18n.t('shop-myOrder-moreProducts') // More Products
                }
              </Button>
            </Container>
          )}
        </Area>
      </Stack>
      {openItemReviewSelection && (
        <OrderItemReviewSelection
          open={openItemReviewSelection}
          onClose={() => setOpenItemReviewSelection(false)}
          orderItems={selectedOrderHistory?.orderItems.filter((item) => !item.isReviewed) ?? []}
          orderId={selectedOrderHistory?.orderId ?? ''}
        />
      )}
    </Page>
  );
}
