import Button from '~/components/Button';
import Icon from '~/components/Icon';
import Stack from '~/components/Stack';
import Typography from '~/components/Typography';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import Box from '~/components/Box';
import IconButton from '~/components/IconButton';
import { Cancel, Image } from '@mui/icons-material';
import ImageView from '~/components/ImageView';
import { uploadFileToStorage } from '~/services/kup/controllers/uploadFile';
import { v4 as uuidv4 } from 'uuid';

export interface Image {
  file: File;
  preview: string;
}

export default function ImageUploader({
  baseImageUrls,
  onImagesChange,
}: {
  baseImageUrls?: string[];
  onImagesChange: (imageUrls: string[]) => void;
}) {
  const { i18n } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadedImageUrls, setUploadedImageUrls] = useState<string[]>(baseImageUrls || []);

  const handleOpenImageSelection = () => {
    fileInputRef.current?.click();
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newImageFiles = Array.from(e.target.files).map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));
      Promise.all(
        newImageFiles.map((image) =>
          uploadFileToStorage(`reviews/${uuidv4()}`, image.file, (progress) => {
            console.log(progress);
          })
        )
      ).then((urls) => {
        const totalImageUrls = [...uploadedImageUrls, ...urls];
        setUploadedImageUrls(totalImageUrls);
        onImagesChange(totalImageUrls);
      });
    }
  };

  const removeImage = (index: number) => {
    setUploadedImageUrls((prevImageUrls) => {
      return prevImageUrls.filter((_, i) => i !== index);
    });
  };

  return (
    <>
      <Stack>
        {uploadedImageUrls.length > 0 && (
          <Stack row gap={'10px'} sx={{ py: '4px' }}>
            {uploadedImageUrls.map((imageUrl, index) => (
              <Box key={index} sx={{ position: 'relative' }} width={'60px'} height={'60px'}>
                <ImageView src={imageUrl} width={'100%'} height={'100%'} />
                <IconButton
                  aria-label="delete"
                  color="primary"
                  sx={{
                    position: 'absolute',
                    width: '13px',
                    height: '13px',
                    top: '5px',
                    right: '5px',
                    cursor: 'pointer',
                    zIndex: '999',
                  }}
                  onClick={() => removeImage(index)}
                >
                  <Cancel sx={{ color: '#1010104D' }} />
                </IconButton>
              </Box>
            ))}
          </Stack>
        )}

        <Button
          fullWidth
          size={'small'}
          variant={'contained'}
          color={'fill'}
          onClick={handleOpenImageSelection}
          sx={{ my: '8px' }}
        >
          <Icon color={'typography.tertiary'} width={'24px'}>
            image_attachment
          </Icon>
          <Typography variant={'FootNote_B'} sx={{ ml: '4px' }}>
            {i18n.t('shop-product-add-review-picture')}
          </Typography>
        </Button>
        <input
          type="file"
          hidden
          accept="image/*"
          multiple
          ref={fileInputRef}
          onChange={handleImageChange}
        />
      </Stack>
    </>
  );
}
