import api from '~kup/api';
import { ICoupon, IIssuedCoupon } from '~kup/models/Coupon.ts';

const COUPON_PATH = {
  ISSUE_COUPON: (couponId: string) => ['coupons', couponId, 'issued'].join('/'),
  USABLE_COUPONS: () => ['coupons', 'issued', 'usable'].join('/'),
  ISSUABLE_COUPONS: () => ['coupons', 'issuable'].join('/'),
  POSSESSION_COUPONS: () => ['coupons', 'possession'].join('/'),
};

type GetIssuableCouponsQueryParams = {
  appliedSurvey?: boolean;
  onTour?: boolean;
};

export async function issueCoupon(couponId: string): Promise<void> {
  await api.post(COUPON_PATH.ISSUE_COUPON(couponId));
}

export async function getUsableCoupons(orderItemIds: string[]): Promise<IIssuedCoupon[]> {
  const resp = await api.get(COUPON_PATH.USABLE_COUPONS(), { params: { orderItemIds } });
  return resp.data.data;
}

export async function getIssuableCoupons(query: GetIssuableCouponsQueryParams): Promise<ICoupon[]> {
  const resp = await api.get(COUPON_PATH.ISSUABLE_COUPONS(), { params: query });
  return resp.data.data;
}

export async function getPossessionCoupons(): Promise<IIssuedCoupon[]> {
  const resp = await api.get(COUPON_PATH.POSSESSION_COUPONS());
  return resp.data.data;
}
