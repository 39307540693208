import { FavoriteBorderOutlined } from '@mui/icons-material';
import ProductList from '~/components/ProductList';
import Spotlight from '~/components/Spotlight';
import Typography from '~/components/Typography';
import useLikedProducts from '~/hooks/useLikedProducts';
import i18n from '~/i18n';

export default function LikedProductList() {
  const { products, total, hasNextPage, next } = useLikedProducts();

  const emptyProductSpotlight = (
    <Spotlight
      textAlign={'center'}
      py={'15vh'}
      lead={<FavoriteBorderOutlined sx={{ fontSize: '64px', color: 'typography.tertiary' }} />}
      headline={i18n.t('shop-no-product-liked')}
      description={i18n.t('shop-no-product-liked-guide')}
      descriptionProps={{
        whiteSpace: 'pre-wrap',
      }}
    />
  );

  return (
    <ProductList
      title={<Typography variant={'FootNote'}>{total} products</Typography>}
      products={products}
      type={'pair'}
      onInfiniteScroll={hasNextPage ? () => next() : undefined}
      emptySpotlight={emptyProductSpotlight}
    />
  );
}
