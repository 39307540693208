import ModalPopup from '~/components/ModalPopup.tsx';

import { OrderItem } from '~/services/kup/models/Order';
import { useTranslation } from 'react-i18next';
import Stack from '~/components/Stack';
import ImageView from '~/components/ImageView';
import Typography from '~/components/Typography';
import { useNavigate } from 'react-router-dom';

export type OrderItemReviewSelectionProps = {
  open: boolean;
  orderItems: OrderItem[];
  orderId: string;
  onClose: () => void;
};

interface OptionItemForReview extends OrderItem {
  optionNames: string[];
}

const getOrderItemsForReview = (orderItems: OrderItem[]) => {
  const distinctOrderItems: Record<string, OptionItemForReview> = {};
  orderItems.forEach((orderItem) => {
    if (orderItem.isReviewed) return;
    const optionItemForReview: OptionItemForReview = {
      ...orderItem,
      optionNames: [],
    };
    if (!distinctOrderItems[orderItem.productId]) {
      optionItemForReview.optionNames.push(
        orderItem.optionItemSelections.map((optionItem) => optionItem.name).join(' / ')
      );
      distinctOrderItems[orderItem.productId] = optionItemForReview;
    } else {
      distinctOrderItems[orderItem.productId].optionNames.push(
        orderItem.optionItemSelections.map((optionItem) => optionItem.name).join(' / ')
      );
    }
  });
  return Object.values(distinctOrderItems);
};

export default function OrderItemReviewSelection(props: OrderItemReviewSelectionProps) {
  const { open, onClose, orderItems, orderId } = props;
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const orderItemsForReview = getOrderItemsForReview(orderItems);

  const handleSectionItem = (orderItem: OptionItemForReview) => {
    navigate(`/shop/product-review/rating`, {
      state: {
        productId: orderItem.productId,
        orderId,
        orderItem,
        orderItems,
        productOptionNames: orderItem.optionNames,
      },
    });
    onClose();
  };

  const handelClose = () => {
    onClose();
  };

  return (
    <ModalPopup open={open} title={i18n.t('shop-product-review-writeReview')} onClose={handelClose}>
      {orderItemsForReview.map((orderItem, idx) => (
        <Stack
          key={idx}
          direction={'row'}
          px={'20px'}
          py={'12px'}
          gap={'14px'}
          alignItems={'center'}
          onClick={() => handleSectionItem(orderItem)}
        >
          <ImageView src={orderItem.productImageUrls[0]} width={'60px'} height={'60px'} />
          <Stack justifyContent={'center'}>
            <Typography variant={'FootNote'}>{orderItem.productBrand}</Typography>
            <Typography variant={'BaseM_B'}>{orderItem.productName}</Typography>
            {orderItem.optionNames.map((optionName) => (
              <Typography variant={'Caption'} color={'typography.secondary'}>
                • {optionName}
              </Typography>
            ))}
          </Stack>
        </Stack>
      ))}
    </ModalPopup>
  );
}
