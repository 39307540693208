export const PRODUCT_ORDER_BY = [
  { label: 'shop-product-orderBy-recommendation', value: 'recommendation' },
  { label: 'shop-product-orderBy-price-low-to-high', value: 'price:ASC' },
  { label: 'shop-product-orderBy-price-high-to-low', value: 'price:DESC' },
  { label: 'shop-product-orderBy-sales', value: 'sales' },
  { label: 'shop-product-orderBy-discountRate-high-to-low', value: 'discountRate:DESC' },
  { label: 'shop-product-orderBy-discountRate-low-to-high', value: 'discountRate:ASC' },
] as const;

export const MESSENGERS = [
  { label: 'WhatsApp', icon: 'WhatsApp', value: 'WhatsApp' },
  { label: 'Line', icon: 'Line', value: 'Line' },
  { label: 'WeChat', icon: 'WeChat', value: 'WeChat' },
  { label: 'Viber', icon: 'Viber', value: 'Viber' },
  { label: 'KakaoTalk', icon: 'KakaoTalk', value: 'KakaoTalk' },
  {
    label: 'Facebook Messenger',
    icon: 'facebook_messenger',
    value: 'FacebookMessenger',
  },
  { label: 'Tel', icon: 'call', value: 'Tel' },
  { label: 'SMS', icon: 'message', value: 'SMS' },
  { label: 'Others', icon: 'etc', value: 'Others' },
] as const;

export const PAYMENT_METHOD = [
  {
    headline: 'Pay with Card',
    value: 'PORTONE_EXIMBAY_CREDIT_CARD_KRW',
    description: 'Visa, Master, Amex, JCB support\n(Business and corporate cards are not accepted)',
  },
  {
    headline: 'Pay with Alipay, Alipay+',
    value: 'PORTONE_EXIMBAY_ALIPAY',
    description: 'Touch`n Go, GCash, Dana support',
  },
];

type orderStatusGuidance = {
  title: string;
  tag: string;
};

export const ORDER_STATUS_GUIDANCE_MAP: Record<string, orderStatusGuidance> = {
  IN_CART: { title: 'Waiting for payment!', tag: 'PROCESSING PAYMENT' },
  CONFIRMING_PAYMENT: {
    title: 'Waiting for payment!',
    tag: 'AWAITING PAYMENT',
  },
  CONFIRMING_ORDER: { title: 'Checking your order!', tag: 'CHECKING ORDER' },
  PREPARING_ITEMS: { title: 'Packing your order!', tag: 'PREPARING ITEMS' },
  ASSIGN_DELIVERY: { title: 'Preparing transit!', tag: 'PREPARING DELIVERY' },
  OUT_FOR_DELIVERY: { title: 'Order is on its way!', tag: 'ON GOING DELIVERY' },
  DELIVERED: { title: 'Delivery completed!', tag: 'DELIVERY COMPLETED' },
  COMPLETED: { title: 'Delivery completed!', tag: 'DELIVERY COMPLETED' },
  REQUESTED_CANCELLATION: {
    title: 'Cancellation requested',
    tag: 'CANCELLATION REQUESTED',
  },
  CANCELLATION: { title: 'Order canceled', tag: 'ORDER CANCELED' },
};

export enum ORDER_STATUS {
  IN_CART = 'IN_CART',
  CONFIRMING_PAYMENT = 'CONFIRMING_PAYMENT',
  CONFIRMING_ORDER = 'CONFIRMING_ORDER',
  PREPARING_ITEMS = 'PREPARING_ITEMS',
  ASSIGN_DELIVERY = 'ASSIGN_DELIVERY',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  DELIVERED = 'DELIVERED',
  COMPLETED = 'COMPLETED',
  REQUESTED_CANCELLATION = 'REQUESTED_CANCELLATION',
  CANCELLATION = 'CANCELLATION',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
}

export enum PAYMENT_STATUS {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  REFUNDED = 'REFUNDED',
}
