export type IconBasketProps = {
  width?: string;
  height?: string;
};
export default function IconBasket(props: IconBasketProps) {
  const { width = '32px', height = '32px' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.52513 22C5.10574 22 4.74667 21.8446 4.44791 21.5338C4.1493 21.2229 4 20.8491 4 20.4126V8.19048C4 7.75397 4.1493 7.38032 4.44791 7.06952C4.74667 6.75857 5.10574 6.6031 5.52513 6.6031H7.81271C7.81271 5.33865 8.23332 4.25532 9.07454 3.3531C9.91591 2.45103 10.9441 2 12.1591 2C13.4037 2 14.4647 2.44579 15.3421 3.33738C16.2193 4.22881 16.6579 5.31738 16.6579 6.6031H18.9455C19.3648 6.6031 19.7239 6.75857 20.0227 7.06952C20.3213 7.38032 20.4706 7.75397 20.4706 8.19048V20.4126C20.4706 20.8491 20.3213 21.2229 20.0227 21.5338C19.7239 21.8446 19.3648 22 18.9455 22H5.52513ZM5.52513 20.4126H18.9455V8.19048H5.52513V20.4126ZM9.33761 6.6031H15.133C15.133 5.75659 14.8534 5.0423 14.2941 4.46024C13.7349 3.87833 13.0486 3.58738 12.2353 3.58738C11.422 3.58738 10.7357 3.87833 10.1765 4.46024C9.61723 5.0423 9.33761 5.75659 9.33761 6.6031ZM12.2234 13.9048C13.3492 13.9048 14.3685 13.5026 15.2812 12.6983C16.194 11.8942 16.6478 10.9921 16.6428 9.99214C16.6428 9.78579 16.569 9.60587 16.4216 9.45238C16.2741 9.29889 16.0903 9.22214 15.8703 9.22214C15.6889 9.22214 15.527 9.28564 15.3846 9.41262C15.2422 9.5396 15.1481 9.71421 15.1023 9.93643C14.9448 10.6348 14.594 11.2063 14.05 11.6507C13.5062 12.0952 12.8988 12.3174 12.2277 12.3174C11.5567 12.3174 10.9479 12.0952 10.4013 11.6507C9.85491 11.2063 9.50545 10.6348 9.35294 9.93643C9.30719 9.70897 9.21317 9.53309 9.07088 9.40881C8.92844 9.28436 8.76572 9.22214 8.58271 9.22214C8.37439 9.22214 8.1952 9.29889 8.04513 9.45238C7.89522 9.60587 7.82026 9.78579 7.82026 9.99214C7.82026 10.9928 8.27267 11.8951 9.17748 12.699C10.0825 13.5029 11.0978 13.9048 12.2234 13.9048Z"
        fill="currentColor"
      />
    </svg>
  );
}
