import api from '~kup/api';

const LOG_PATH = {
  PATH_LOG: () => ['metric', 'user-path'].join('/'),
  ACTION_LOG: () => ['metric', 'user-action'].join('/'),
};

export async function pushPathLog(
  pathname: string,
  optional: {
    hash?: string;
    query?: string;
    reservationId?: string;
    reservationAgencyCode?: string;
    reservationDate?: string;
    participantId?: string;
    participantName?: string;
    tourId?: string;
    teamId?: string;
    guideId?: string;
    guideName?: string;
    referrerPathname?: string;
    referrerHash?: string;
    referrerQuery?: string;
  }
): Promise<void> {
  await api.post(LOG_PATH.PATH_LOG(), {
    pathname,
    ...optional,
  });
}

export type PathInfo = {
  pathname: string;
  hash?: string;
  query?: string;
};

export type EventInfo = {
  eventType: string;
  eventTarget: string;
  eventCategory: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventData?: Record<string, any>;
};

export type ActionLog = {
  pathInfo: PathInfo;
  eventInfo: EventInfo;
};
export async function pushActionLogs(action: ActionLog): Promise<void> {
  await api.post(LOG_PATH.ACTION_LOG(), { ...action.eventInfo, ...action.pathInfo });
}
