import Area from '~/components/Area';
import Button from '~/components/Button';
import Stack from '~/components/Stack';
import Typography from '~/components/Typography';
import { useSnackBar } from '~/contexts/SnackBarContext';
import useIssuableCoupons from '~/hooks/useIssuableCoupons';
import i18n from '~/i18n';
import { issueCoupon } from '~/services/kup/controllers/coupon';
import { theme } from '~/theme';

export default function CouponsToGetIt() {
  const { trueIssuableCoupons, callIssuableCoupons } = useIssuableCoupons();
  const { showSnackBar } = useSnackBar();

  const handleIssueAllCoupons = async () => {
    if (!trueIssuableCoupons) return;
    await Promise.all(
      trueIssuableCoupons.map((coupon) => {
        issueCoupon(coupon.id).then(() => {
          showSnackBar({
            message: i18n.t('shop-coupon-couponsAreIssued'),
            lift: 120,
          });
          callIssuableCoupons();
        });
      })
    );
  };

  const handleIssueCoupon = async (couponId: string) => {
    await issueCoupon(couponId).then(() => {
      showSnackBar({
        message: i18n.t('shop-coupon-couponsAreIssued'),
        lift: 120,
      });
      callIssuableCoupons();
    });
  };

  return (
    <Area
      title={<Typography variant={'FootNote'}>{trueIssuableCoupons.length} coupons</Typography>}
      containerProps={{ stackProps: { gap: '6px', sx: { px: '20px', position: 'relative' } } }}
      background={'paper'}
    >
      {trueIssuableCoupons.map((issuableCoupon) => (
        <Stack
          key={issuableCoupon.id}
          gap={2}
          sx={{ py: '12px', border: `1px solid ${theme.palette.divider}`, borderRadius: '8px' }}
        >
          <Stack
            row
            gap={'14px'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ px: '20px' }}
          >
            <Stack column gap={'4px'} sx={{ width: '100%' }}>
              <Typography variant={'BaseM_B'}>{issuableCoupon.name}</Typography>
              <Typography
                variant={'FootNote'}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                }}
              >
                {issuableCoupon.description}
              </Typography>
            </Stack>
            <Button
              size={'small'}
              variant={'contained'}
              color={'secondary'}
              sx={{ color: theme.palette.text.primary }}
              onClick={() => handleIssueCoupon(issuableCoupon.id)}
            >
              {i18n.t('shop-my-coupon-get-it')}
            </Button>
          </Stack>
        </Stack>
      ))}
      <Button
        variant={'contained'}
        color={'primary'}
        sx={{ position: 'fixed', bottom: '20px', left: '20px', right: '20px' }}
        onClick={handleIssueAllCoupons}
      >
        {i18n.t('common-take-it-all')}
      </Button>
    </Area>
  );
}
