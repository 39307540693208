import { useLayoutEffect, useState } from 'react';
import getTimeRemains from '~/utils/getTimeRemains.ts';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

type Remains = {
  hours: number;
  minutes: number;
  seconds: number;
  text: string;
};

type UseTodayCutOff = {
  cutOffRemains: Remains;
};

const calcRemains = (targetDate: Date): Remains => {
  const { hours, seconds, minutes, days } = getTimeRemains(targetDate);
  const totalHours = 24 * days + hours;
  return {
    hours,
    minutes,
    seconds,
    text: `${totalHours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
  };
};

export default function useSomeDayCutOff(targetDate: Date): UseTodayCutOff {
  const someDay = dayjs(targetDate).tz('Asia/Seoul');
  const cutOffKST = someDay.set('hour', 24).set('minute', 0).set('seconds', 0);

  const [cutOffRemains, setCutOffRemains] = useState<Remains>(calcRemains(cutOffKST.toDate()));

  useLayoutEffect(() => {
    const interval = () => {
      setCutOffRemains(calcRemains(cutOffKST.toDate()));
    };

    const intervalId = setInterval(interval, 1000);
    return () => clearInterval(intervalId);
  }, [cutOffKST]);

  return {
    cutOffRemains,
  };
}
