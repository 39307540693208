import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Skeleton } from '@mui/material';

import Container from '~/components/Container.tsx';
import Typography from '~/components/Typography.tsx';
import Box from '~/components/Box.tsx';
import Stack from '~/components/Stack.tsx';
import Button from '~/components/Button.tsx';
import Icon from '~/components/Icon';
import OnTourCouponTimeLimit from '~/components/OnTourCouponTimeLimit.tsx';
import { useSnackBar } from '~/contexts/SnackBarContext.tsx';
import useBatchLogger from '~/hooks/useBatchLogger.ts';
import { BenefitTypeEnum, ICoupon } from '~kup/models/Coupon.ts';
import { getIssuableCoupons, issueCoupon } from '~kup/controllers/coupon.ts';

function OnTourCouponCard({ coupon }: { coupon: ICoupon }) {
  return (
    <Box
      sx={{
        flex: 1,
        position: 'relative',
        borderRadius: '12px',
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        py: '16px',
        border: '1px solid',
        borderColor: 'primary.main',
      }}
    >
      <div
        style={{
          backgroundColor: '#e6ebf5',
          width: '13px',
          height: '26px',
          borderTopLeftRadius: '26px',
          borderBottomLeftRadius: '26px',
          border: '1px solid',
          borderRight: 'none',
          borderColor: '#1a73e8',
          position: 'absolute',
          top: '50%',
          right: '-1px',
          transform: 'translateY(-50%)',
        }}
      ></div>
      <Stack>
        <Typography
          variant="TitleL_B"
          color="primary"
          sx={{ textAlign: 'center', fontWeight: 700 }}
        >
          {coupon.benefitValue.toLocaleString()}
          {coupon.benefitType === BenefitTypeEnum.DISCOUNT_RATE ? '%' : '₩'}
        </Typography>
        <Typography
          variant="Caption_B"
          color="primary"
          sx={{ textAlign: 'center', fontWeight: 700 }}
        >
          {coupon.description}
        </Typography>
      </Stack>
    </Box>
  );
}

export default function OnTourCouponSection() {
  const { i18n } = useTranslation();
  const { showSnackBar } = useSnackBar();
  const { logAction } = useBatchLogger();
  const [onTourCoupons, setOnTourCoupons] = useState<ICoupon[] | null>([]);
  const isIssuableCoupon = onTourCoupons?.filter((coupon) => !coupon.isIssuable).length === 0;

  useEffect(() => {
    getIssuableCoupons({ onTour: true }).then((coupons) => {
      if (coupons.length === 0) {
        setOnTourCoupons(null);
        return;
      }
      setOnTourCoupons(coupons);
    });
  }, []);

  const handleIssueCoupons = async () => {
    if (!onTourCoupons) return;
    await Promise.all(
      onTourCoupons.map((coupon) => {
        issueCoupon(coupon.id).then(() => {
          showSnackBar({
            message: i18n.t('shop-coupon-couponsAreIssued'),
            lift: 120,
          });
          getIssuableCoupons({ onTour: true }).then(setOnTourCoupons);
        });
      })
    );
  };

  const couponSectionSkeleton = (
    <Container px={'20px'} py={'40px'} sx={{ height: '508px' }}>
      <Stack sx={{ height: '148px' }} gap={'12px'}>
        <Skeleton variant="rectangular" height={40} />
        <Skeleton variant="rectangular" height={40} />
        <Skeleton variant="rectangular" height={40} />
      </Stack>
      <Grid container columnSpacing="12px" pt="16px" rowSpacing="12px">
        <Grid item xs={6}>
          <Skeleton variant="rectangular" height={112} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" height={112} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" height={112} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" height={112} />
        </Grid>
      </Grid>
    </Container>
  );

  const couponSection = (
    <>
      <Container px={'20px'} py={'40px'}>
        <Stack justifyContent="center" alignItems="center">
          <Typography
            variant="FootNote"
            color="primary"
            sx={{
              backgroundColor: 'rgba(47, 128, 237, 0.1)',
              px: '6px',
              py: '2px',
              borderRadius: '6px',
              width: 'fit-content',
              display: 'block',
            }}
          >
            {i18n.t('home-main-coupon-exclusive')}
          </Typography>

          <OnTourCouponTimeLimit />
          <Typography
            variant="FootNote"
            color="typography.secondary"
            sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}
          >
            {i18n.t('home-main-coupon-enjoy-lowest')}
          </Typography>
        </Stack>

        <Grid container columnSpacing="12px" pt="16px" rowSpacing="12px">
          {onTourCoupons !== null &&
            onTourCoupons.map((coupon) => (
              <Grid item xs={onTourCoupons.length === 1 ? 12 : 6}>
                <OnTourCouponCard coupon={coupon} />
              </Grid>
            ))}
        </Grid>

        <Stack sx={{ pt: '16px', textAlign: 'center' }}>
          <Button
            fullWidth
            size="small"
            disabled={!isIssuableCoupon}
            startIcon={<Icon>download</Icon>}
            onClick={() => {
              handleIssueCoupons();
              logAction({
                eventType: 'CLICK',
                eventTarget: 'HOME_CLAIM_COUPON',
                eventCategory: 'coupon',
              });
            }}
          >
            {isIssuableCoupon
              ? i18n.t('home-main-coupon-get')
              : i18n.t('shop-coupon-allAvailableCouponsHaveBeenIssued')}
          </Button>

          <Typography
            variant="FootNote"
            color="typography.secondary"
            sx={{ textAlign: 'center', mt: '12px' }}
          >
            {i18n.t('home-main-coupon-valid-24h')}
          </Typography>
        </Stack>
      </Container>
    </>
  );

  if (onTourCoupons === null) return null;
  return onTourCoupons.length > 0 ? couponSection : couponSectionSkeleton;
}
