import { useNavigate } from 'react-router-dom';
import Box, { BoxProps } from '~/components/Box';
import Badge from '~/components/Badge';
import Stack from '~/components/Stack';
import Typography from '~/components/Typography';
import Logo from '~/components/Logo';
import ImageView from '~/components/ImageView.tsx';

import { Product } from '~kup/models/Product';
import useDeliveryCutOff from '~/pages/shop/hooks/useDeliveryCutOff.ts';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import LikeButton from './LikeButton';
import { likeProduct, unlikeProduct } from '~/services/kup/controllers/product';
import useKupStore from '~kup/store';

export type ProductProps = BoxProps & {
  product: Product;
  OverlapBadge?: ReactNode;
  onClickProductItem?: (product: Product) => void;
  onLikeProductItem?: (product: Product) => void;
};
export type ProductItemProps = ProductProps & {
  type?: 'large' | 'medium' | 'small' | 'simple' | 'horizontal';
};

export default function ProductItem(props: ProductItemProps) {
  const { type = 'large', product, ...productProps } = props;
  const { toggleProductLike } = useKupStore((state) => ({
    toggleProductLike: state.toggleProductLike,
  }));

  const handleLikeProductItem = (prevProduct: Product) => {
    props.onLikeProductItem?.(prevProduct);
    toggleProductLike(prevProduct.id);
  };
  const { productLikeDict } = useKupStore((state) => ({ productLikeDict: state.productLikeDict }));
  product.isLiked = !!productLikeDict[product.id];

  return type === 'large' ? (
    <ProductItemLarge
      {...productProps}
      product={product}
      onLikeProductItem={handleLikeProductItem}
    />
  ) : type === 'medium' ? (
    <ProductItemMedium
      {...productProps}
      product={product}
      onLikeProductItem={handleLikeProductItem}
    />
  ) : type === 'small' ? (
    <ProductItemSmall
      {...productProps}
      product={product}
      onLikeProductItem={handleLikeProductItem}
    />
  ) : type === 'simple' ? (
    <ProductSimpleItem
      {...productProps}
      product={product}
      onLikeProductItem={handleLikeProductItem}
    />
  ) : type === 'horizontal' ? (
    <ProductHorizontalItem
      {...productProps}
      product={product}
      onLikeProductItem={handleLikeProductItem}
    />
  ) : null;
}

async function handleLikeProduct(product: Product, onHandled?: (isLiked: boolean) => void) {
  if (product.isLiked) {
    await unlikeProduct(product.id);
  } else {
    await likeProduct(product.id);
  }
  onHandled?.(!product.isLiked);
}

export function ProductItemLarge(props: ProductProps) {
  const { sx, OverlapBadge, onClickProductItem, onLikeProductItem, product } = props;
  const { i18n } = useTranslation();
  const { deliveryDayExpression, deliveryHoursKST } = useDeliveryCutOff();
  const navigate = useNavigate();
  const isSoldOut = !product.stocks.quantity;
  const isInPromotion = product.isInPromotion;

  return (
    <Box
      sx={sx}
      py={'4px'}
      onClick={() => {
        onClickProductItem?.(product);
        navigate(`/shop/product/${product.id}`);
      }}
    >
      <Box
        sx={{
          position: 'relative',
          height: '400px',
          width: '100%',
        }}
      >
        <ImageView borderRadius={'0px'} src={product.imageUrls[0]} height={'100%'} width={'100%'} />
        <Box
          sx={{
            position: 'absolute',
            top: '10px',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}
        >
          {OverlapBadge}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            right: '0',
            bottom: '0',
            mr: '3px',
            mb: '3px',
          }}
        >
          <LikeButton
            liked={product.isLiked}
            type={'circle'}
            onClick={() => {
              onLikeProductItem?.(product);
              handleLikeProduct(product);
            }}
          />
        </Box>
      </Box>
      <Box py={'12px'} px={'20px'}>
        <Stack gap={'4px'}>
          <Typography variant={'BaseS'}>{product.name}</Typography>
          <Stack row alignItems={'center'} gap={'4px'}>
            {product.price !== product.originalPrice && product.price < product.originalPrice ? (
              <Typography component={'span'} variant={'TitleS_B'} fontWeight={'bold'} color={'red'}>
                {(product.discountRate * 100).toFixed(0)}%
              </Typography>
            ) : null}
            <Typography component={'span'} variant={'TitleS_B'} fontWeight={'bold'}>
              ₩{product.price.toLocaleString()}
            </Typography>

            {product.price !== product.originalPrice && product.price < product.originalPrice ? (
              <Typography
                component={'span'}
                variant={'FootNote'}
                fontWeight={'bold'}
                color={'typography.quaternary'}
                sx={{
                  textDecoration: 'line-through',
                }}
              >
                ₩{product.originalPrice.toLocaleString()}
              </Typography>
            ) : null}
          </Stack>
          <Stack row gap={'6px'}>
            {isSoldOut && (
              <Badge color={'error'} size={'small'}>
                {i18n.t('component-productItem-soldOut')}
              </Badge>
            )}
            {!isSoldOut && product.best && (
              <Badge color={'warning'} size={'small'}>
                {i18n.t('component-productItem-best')}
              </Badge>
            )}
            {!isSoldOut && isInPromotion && (
              <Badge color={'error'} size={'small'}>
                {i18n.t('component-productItem-hotDeal')}
              </Badge>
            )}
            {!isSoldOut && product.delivery && (
              <>
                <Badge color={'primary'} size={'small'}>
                  <Logo height={'12px'} color={'inherit'}>
                    delivery
                  </Logo>
                </Badge>
                <Typography variant={'FootNote'} color={'typography.tertiary'}>
                  {i18n.t('component-productItem-freeDelivery', {
                    deliveryDay: deliveryDayExpression,
                    deliveryHour: deliveryHoursKST,
                  })}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

export function ProductItemMedium(props: ProductProps) {
  const { sx, onClickProductItem, onLikeProductItem, product } = props;
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const isSoldOut = !product.stocks.quantity;

  return (
    <Box
      sx={sx}
      onClick={() => {
        onClickProductItem?.(product);
        navigate(`/shop/product/${product.id}`);
      }}
    >
      <Box
        sx={{
          position: 'relative',
          height: '150px',
          width: '150px',
        }}
      >
        <ImageView src={product.imageUrls[0]} height={'100%'} width={'100%'} borderRadius={'8px'} />
        <Box
          sx={{
            position: 'absolute',
            right: '0',
            bottom: '0',
            mr: '3px',
            mb: '3px',
          }}
        >
          <LikeButton
            liked={product.isLiked}
            type={'circle'}
            onClick={() => {
              onLikeProductItem?.(product);
              handleLikeProduct(product);
            }}
          />
        </Box>
      </Box>
      <Box py={'10px'}>
        <Stack gap={'4px'}>
          <Typography variant={'FootNote_B'}>{product.brand?.name}</Typography>
          <Typography variant={'FootNote'}>{product.name}</Typography>
          <Stack row gap={'4px'}>
            {product.price !== product.originalPrice && product.price < product.originalPrice ? (
              <Typography
                component={'span'}
                variant={'BaseM_B'}
                fontWeight={'bold'}
                color={'error'}
              >
                {(product.discountRate * 100).toFixed(0)}%
              </Typography>
            ) : null}
            <Typography component={'span'} variant={'BaseM_B'} fontWeight={'bold'}>
              ₩{product.price.toLocaleString()}
            </Typography>
          </Stack>
          <Stack row gap={'6px'}>
            {isSoldOut && (
              <Badge color={'error'} size={'small'}>
                {i18n.t('component-productItem-soldOut')}
              </Badge>
            )}
            {!isSoldOut && product.best && (
              <Badge color={'warning'} size={'small'}>
                {i18n.t('component-productItem-best')}
              </Badge>
            )}
            {!isSoldOut && product.isInPromotion && (
              <Badge color={'error'} size={'small'}>
                {i18n.t('component-productItem-hotDeal')}
              </Badge>
            )}
            {!isSoldOut && product.delivery && (
              <>
                <Badge color={'primary'} size={'small'}>
                  <Logo height={'12px'} color={'inherit'}>
                    delivery
                  </Logo>
                </Badge>
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

export function ProductItemSmall(props: ProductProps) {
  const { sx, onClickProductItem, onLikeProductItem, product } = props;
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const isSoldOut = !product.stocks.quantity;

  return (
    <Box
      sx={sx}
      onClick={() => {
        onClickProductItem?.(product);
        navigate(`/shop/product/${product.id}`);
      }}
    >
      <Box
        sx={{
          position: 'relative',
          height: '160px',
          width: '100%',
        }}
      >
        <ImageView src={product.imageUrls[0]} height={'100%'} width={'100%'} borderRadius={'8px'} />
        <Box
          sx={{
            position: 'absolute',
            right: '0',
            bottom: '0',
            mr: '5px',
            mb: '5px',
          }}
        >
          <LikeButton
            liked={product.isLiked}
            type={'circle'}
            onClick={() => {
              onLikeProductItem?.(product);
              handleLikeProduct(product);
            }}
          />
        </Box>
      </Box>
      <Box py={'10px'}>
        <Stack gap={'4px'}>
          <Typography variant={'FootNote'}>{product.name}</Typography>
          <Stack row gap={'4px'}>
            {product.price !== product.originalPrice && product.price < product.originalPrice ? (
              <Typography
                component={'span'}
                variant={'BaseM_B'}
                fontWeight={'bold'}
                color={'error'}
              >
                {(product.discountRate * 100).toFixed(0)}%
              </Typography>
            ) : null}
            <Typography component={'span'} variant={'BaseM_B'} fontWeight={'bold'}>
              ₩{product.price.toLocaleString()}
            </Typography>
          </Stack>
          <Stack row gap={'6px'}>
            {isSoldOut && (
              <Badge color={'error'} size={'small'}>
                {i18n.t('component-productItem-soldOut')}
              </Badge>
            )}
            {!isSoldOut && product.best && (
              <Badge color={'warning'} size={'small'}>
                {i18n.t('component-productItem-best')}
              </Badge>
            )}
            {!isSoldOut && product.isInPromotion && (
              <Badge color={'error'} size={'small'}>
                {i18n.t('component-productItem-hotDeal')}
              </Badge>
            )}
            {!isSoldOut && product.delivery && (
              <>
                <Badge color={'primary'} size={'small'}>
                  <Logo height={'12px'} color={'inherit'}>
                    delivery
                  </Logo>
                </Badge>
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

export function ProductSimpleItem(props: ProductProps) {
  const { onClickProductItem, onLikeProductItem, product } = props;
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: '100px',
        position: 'relative',
      }}
      onClick={() => {
        onClickProductItem?.(product);
        navigate(`/shop/product/${product.id}`);
      }}
    >
      <Box sx={{ width: '100px', height: '100px', position: 'relative' }}>
        <ImageView
          src={product.imageUrls[0]}
          width={'100px'}
          height={'100px'}
          borderRadius={'8px'}
        />
        <Box
          sx={{
            position: 'absolute',
            right: '0',
            bottom: '0',
            mr: '3px',
            mb: '3px',
          }}
        >
          <LikeButton
            liked={product.isLiked}
            type={'circle'}
            onClick={() => {
              onLikeProductItem?.(product);
              handleLikeProduct(product);
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          pb: '8px',
        }}
      >
        <Typography
          variant={'Caption'}
          sx={{
            display: 'block',
            maxLines: 2,
            textOverflow: 'ellipsis',
          }}
        >
          {product.name}
        </Typography>
        <Typography
          variant={'FootNote'}
          fontWeight={'bold'}
          sx={{
            display: 'block',
          }}
        >
          ₩{product.price.toLocaleString()}
        </Typography>
      </Box>
    </Box>
  );
}

export function ProductHorizontalItem(props: ProductProps) {
  const { onClickProductItem, onLikeProductItem, product } = props;
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const isSoldOut = !product.stocks.quantity;
  return (
    <Stack
      direction={'row'}
      gap={1.5}
      sx={{ width: '70vw' }}
      onClick={() => {
        onClickProductItem?.(product);
        navigate(`/shop/product/${product.id}`);
      }}
    >
      <Box sx={{ width: '100px', height: '100px', position: 'relative' }}>
        <ImageView
          src={product.imageUrls[0]}
          width={'100px'}
          height={'100px'}
          borderRadius={'8px'}
        />
        <Box
          sx={{
            position: 'absolute',
            right: '0',
            bottom: '0',
            mr: '3px',
            mb: '3px',
          }}
        >
          <LikeButton
            liked={product.isLiked}
            type={'circle'}
            onClick={() => {
              onLikeProductItem?.(product);
              handleLikeProduct(product);
            }}
          />
        </Box>
      </Box>
      <Stack sx={{ pb: '8px' }} justifyContent={'center'} gap={0.3}>
        <Typography
          variant={'Caption'}
          sx={{
            display: 'block',
            maxLines: 2,
            textOverflow: 'ellipsis',
          }}
        >
          {product.brand?.name ?? ''}
        </Typography>
        <Typography
          variant={'Caption'}
          sx={{
            display: 'block',
            maxLines: 2,
            width: '35vw',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {product.name}
        </Typography>
        <Stack direction={'row'} gap={0.3}>
          <Typography variant={'FootNote'} color={'error'}>
            {product.discountRate ? `${(product.discountRate * 100).toFixed(0)}%` : ''}
          </Typography>
          <Typography variant={'FootNote'}>{product.price.toLocaleString()} KRW</Typography>
        </Stack>

        <Stack row gap={'6px'}>
          {isSoldOut && (
            <Badge color={'error'} size={'small'}>
              {i18n.t('component-productItem-soldOut')}
            </Badge>
          )}
          {!isSoldOut && product.best && (
            <Badge color={'warning'} size={'small'}>
              {i18n.t('component-productItem-best')}
            </Badge>
          )}
          {!isSoldOut && product.isInPromotion && (
            <Badge color={'error'} size={'small'}>
              {i18n.t('component-productItem-hotDeal')}
            </Badge>
          )}
          {!isSoldOut && product.delivery && (
            <>
              <Badge color={'primary'} size={'small'}>
                <Logo height={'12px'} color={'inherit'}>
                  delivery
                </Logo>
              </Badge>
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
