// 배열을 주어진 크기만큼 나누는 함수
export function chunkArray<T>(arr: T[], size?: number, limit?: number): T[][] {
  if (!size) return [arr];

  const chunkedArr = [];
  const copyArr = [...arr];

  while (copyArr.length > 0) {
    if (limit && chunkedArr.length >= limit) break;
    chunkedArr.push(copyArr.splice(0, size));
  }

  return chunkedArr;
}
