import { Notification, NotificationResponse } from '~kup/models/Notification';
import kupMessaging from '~kup/firebase/messaging.ts';
import api from '~kup/api';

export async function requestTokenForKup(): Promise<string | null> {
  const token = await kupMessaging.requestToken();
  if (!token) return null;
  await reportToken(token);
  return token;
}

export async function getTokenForKup(): Promise<string | null> {
  const token = await kupMessaging.getToken();
  if (!token) return null;
  await reportToken(token);
  return token;
}

export async function isPermissionGranted(): Promise<boolean> {
  return kupMessaging.isPermissionGranted();
}

export function isSupported(): Promise<boolean> {
  return kupMessaging.isSupported();
}

export async function stopNotification(): Promise<void> {
  await api.patch('/auth/enableNotification', { isEnableNotification: false }).catch(console.error);
}

export async function startNotification(): Promise<void> {
  await api.patch('/auth/enableNotification', { isEnableNotification: true }).catch(console.error);
}

export async function reportToken(token: string): Promise<void> {
  const resp = await api.put('/notifications/token', { token });
  return resp.data?.data;
}

export async function getNotifications(onlyUnread?: boolean): Promise<Notification[]> {
  const resp = await api.get('/notifications', { params: { unread: onlyUnread } });
  const notificationRaws = resp.data?.data as NotificationResponse[];
  return notificationRaws.map((r) => new Notification(r));
}

export async function getNotification(id: string): Promise<Notification> {
  const resp = await api.get(`/notifications/${id}`);
  const notificationRaw = resp.data?.data as NotificationResponse;
  return new Notification(notificationRaw);
}

export async function patchRead(id: string): Promise<void> {
  await api.patch(`/notifications/${id}/read`);
}
