import { useEffect, useState } from 'react';
import { getProductBrandRandomDisplay } from '~/services/kup/controllers/productBrand';
import { getProductCollectionByBrand } from '~/services/kup/controllers/productCollection';
import { ProductBrand } from '~/services/kup/models/ProductBrand';
import { ProductCollection } from '~/services/kup/models/ProductCollection';

type UseBrandPick = {
  pickedBrand: ProductBrand | null;
  pickedBrandCollection: ProductCollection | null;
  setPickedBrand: (brand: ProductBrand) => void;
};

export default function useBrandPick(): UseBrandPick {
  const [pickedBrand, setPickedBrand] = useState<ProductBrand | null>(null);
  const [pickedBrandCollection, setPickedBrandCollection] = useState<ProductCollection | null>(
    null
  );

  useEffect(() => {
    getProductBrandRandomDisplay().then((brand) => {
      setPickedBrand(brand);
    });
  }, []);

  useEffect(() => {
    if (pickedBrand) {
      getProductCollectionByBrand(pickedBrand.id, 'random').then((collections) => {
        setPickedBrandCollection(collections[0]);
      });
    }
  }, [pickedBrand]);

  return { pickedBrand, pickedBrandCollection, setPickedBrand };
}
