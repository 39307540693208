import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import useSyncReservation from '~/hooks/useSyncReservation.ts';
import Page from '~/components/Page';
import styled from '~/components/styled.tsx';
import Container from '~/components/Container.tsx';
import Typography from '~/components/Typography.tsx';
import Button from '~/components/Button';
import useKintStore from '~kint/store';
import Paper from '~/components/Paper';
import ChatListItem from '~/components/ChattingListItem.tsx';
import ReservationPopup from '~/pages/home/partial/ReservationPopup.tsx';
import TourInfoSection from '~/pages/home/partial/TourInfoSection.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import Divider from '~/components/Divider.tsx';
import CommonFooter from '~/components/CommonFooter.tsx';
import { IChatting } from '~kint/models/Chatting.ts';
import useListenChattings from '~/pages/message/chat/hooks/useListenChattings.ts';
import Stack from '~/components/Stack.tsx';

import useScrollIn from '~/hooks/useScrollIn.ts';
import useDeliveryCutOff from '~/pages/shop/hooks/useDeliveryCutOff.ts';
import TagProductSection from '~/pages/home/components/TagProductSection.tsx';
import useTags from '~/hooks/useTags.ts';
import PromotionSection from '~/components/PromotionSection.tsx';
import usePromotions from '~/hooks/usePromotions.ts';
import { useTranslation } from 'react-i18next';
import IconButton from '~/components/IconButton.tsx';
import useKupStore from '~kup/store';
import * as Sentry from '@sentry/react';
import OnTourCouponSection from '../../partials/OnTourCouponSection.tsx';
import MainRankingList from './partial/MainRankingList';
import BrandPickSection from './partial/BrandPickSection';
import { Skeleton } from '@mui/material';
import useBatchLogger from '~/hooks/useBatchLogger';
import BestPriceProducts from './partial/BestPriceProducts';
import BasketIconWithCount from '~/components/BasketIconWithCount.tsx';
import LikeIconWithCount from '~/components/LikeIconWithCount.tsx';
import useSyncShopping from '~/hooks/useSyncShopping.ts';
import InstallPopup from '~/components/InstallPopup.tsx';

const CouponGradientContainer = styled(Container)({
  background:
    'linear-gradient(180deg, rgba(227,238,255,0.05) 0%, #E3EEFF 35%, #E3EEFF 75%, #FFF 100%)',
});

export default function HomeMain() {
  useSyncReservation();
  useSyncShopping();

  const location = useLocation();
  const fromEntry = location?.state?.fromEntry;
  const { i18n } = useTranslation();
  const { reservation, name } = useKintStore((state) => ({
    reservation: state.reservation,
    name: state.name,
  }));
  const { setAuthLocale } = useKupStore((state) => ({
    setAuthLocale: state.setAuthLocale,
    basket: state.basket,
  }));

  const highlightTags = useTags(undefined, true);
  const { promotions } = usePromotions({ isHighlight: true });
  const [openReservationPopup, setOpenReservationPopup] = useState<boolean>(false);
  const reservationDayjs = reservation ? dayjs(reservation.date + ' 09:00') : dayjs();
  const chattings = useListenChattings();
  const sortChats = (aChat: IChatting, bChat: IChatting) => {
    return (
      (bChat.updatedAt ?? bChat.createdAt).toDate().getTime() -
      (aChat.updatedAt ?? aChat.createdAt).toDate().getTime()
    );
  };

  const randomHighlightIndex = useMemo(
    () => Math.round(Math.random() * (highlightTags.length - 1)),
    [highlightTags.length]
  );
  const randomizeTag = highlightTags.at(randomHighlightIndex);

  const handleToggleOpenReservationPopup = () => {
    setOpenReservationPopup((prev) => !prev);
  };

  const handleTranslate = () => {
    const changeLanguage = (lang: string) => {
      i18n
        .changeLanguage(lang, () => {
          setAuthLocale(lang);
          window?.location?.reload();
        })
        .catch((e) => Sentry.captureException(e));
    };

    switch (i18n.language) {
      case 'en-US':
        changeLanguage('zh-CN');
        return;
      case 'zh-CN':
        changeLanguage('zh-TW');
        return;
      case 'zh-TW':
        changeLanguage('en-US');
        return;
      default:
        changeLanguage('en-US');
        return;
    }
  };

  useEffect(() => {
    checkTrackingPermission().catch(console.error);
  }, []);

  if (!reservation) return null;
  let chattingsSection = <Skeleton variant="rectangular" height={126} />;
  if (chattings.length > 0) {
    chattingsSection = (
      <>
        {chattings.sort(sortChats).map((chat) => (
          <Paper
            key={chat?.id}
            sx={{
              animation: fromEntry ? '0.75s 3 vibration' : undefined,
              p: 0,
            }}
          >
            <ChatListItem key={chat?.lastMessage?.id} chat={chat} highlight />
          </Paper>
        ))}
      </>
    );
  }

  return (
    <>
      <Page
        type={'main'}
        backgroundColor={'paper'}
        navigationBarProps={{
          sx: (theme) => ({
            position: 'sticky',
            top: 0,
            left: 0,
            right: 0,
            background: theme.palette.background.paper,
            borderRadius: 0,
          }),
          trail: (
            <Stack height={'20px'} flexDirection={'row'} gap={'12px'}>
              <BasketIconWithCount displayOnlyNeeded />
              <LikeIconWithCount displayOnlyNeeded />
              <IconButton
                iconProps={{
                  width: '24px',
                }}
                color={'typography.secondary'}
                onClick={handleTranslate}
              >
                {i18n.language === 'en-US'
                  ? 'english'
                  : i18n.language === 'zh-TW'
                    ? 'chinese_traditional'
                    : i18n.language === 'zh-CN'
                      ? 'chinese_simplified'
                      : 'translate'}
              </IconButton>
            </Stack>
          ),
        }}
      >
        <Container px={'20px'} pb={'16px'}>
          <Banner />
        </Container>
        <Container pb={'4px'} px={'20px'}>
          <Typography variant={'FootNote'} color={'typography.secondary'}>
            {reservationDayjs.format('YY.MM.DD')}
          </Typography>
          <Container
            stackProps={{
              row: true,
              gap: '4px',
              alignItems: 'flex-end',
            }}
          >
            <Container
              stackProps={{
                column: true,
              }}
            >
              <Typography variant={'BaseS'} component={'p'}>
                {i18n.t('home-main-hiUser', { name })}
              </Typography>
              <Typography variant={'BaseS'} component={'p'}>
                {fromEntry
                  ? i18n.t('home-main-joinTourChat')
                  : reservationDayjs.diff(dayjs(), 'hour') > 0
                    ? i18n.t('home-main-cantWait')
                    : reservationDayjs.diff(dayjs(), 'hour') < -8
                      ? i18n.t('home-main-hopeEnjoyedTour')
                      : i18n.t('home-main-enjoyTour')}
              </Typography>
            </Container>
            <Button
              size={'small'}
              color={'fill'}
              sx={{
                flexShrink: 0,
              }}
              onClick={handleToggleOpenReservationPopup}
            >
              {i18n.t('home-main-myBooking')}
            </Button>
          </Container>
          <Container
            pt={'16px'}
            stackProps={{
              p: 0,
              gap: '8px',
            }}
          >
            {chattingsSection}
          </Container>
        </Container>

        <CouponGradientContainer>
          <OnTourCouponSection />
        </CouponGradientContainer>

        <Container px={'20px'} pt={'12px'} pb={'20px'}>
          <TourInfoSection />
        </Container>

        {(promotions ?? []).map((promotion) => (
          <PromotionSection key={promotion.id} promotion={promotion} />
        ))}
        {randomizeTag && (
          <Container
            py={'20px'}
            stackProps={{
              column: true,
              gap: '12px',
            }}
          >
            {[randomizeTag].map((tag) => {
              if (tag === undefined) return null;
              return <TagProductSection key={tag.id} tag={tag} />;
            })}
          </Container>
        )}
        <Container py={'20px'}>
          <Container py={'12px'}>
            <MainRankingList />
          </Container>

          <Container pb={'20px'}>
            <BrandPickSection />
          </Container>

          <Container pt={'12px'} pb={'20px'}>
            <BestPriceProducts />
          </Container>
        </Container>
        <Divider color={'fill'} type={'box'} />
        <CommonFooter />
      </Page>
      <ReservationPopup open={openReservationPopup} onClose={handleToggleOpenReservationPopup} />
      <InstallPopup postpone agencyCode={reservation.agencyCode} name={name ?? undefined} />
    </>
  );
}

function Banner() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { logAction } = useBatchLogger();
  const [isVisible, setIsVisible] = useState(false);
  const bannerRef = useScrollIn(
    () => setIsVisible(true),
    () => setIsVisible(false),
    1
  );
  const { deliveryDayExpression } = useDeliveryCutOff();

  const handleClickBanner = () => {
    logAction({
      eventType: 'CLICK',
      eventTarget: 'HOME_BANNER',
      eventCategory: 'banner',
    });
    navigate('/shop');
  };

  return (
    <>
      <Button
        fullWidth
        onClick={handleClickBanner}
        sx={{
          background: '#EBE5E5 !important',
          overflow: 'hidden',
        }}
      >
        <Stack gap={'4px'}>
          <Stack row alignItems={'flex-end'} sx={{ width: '100%' }}>
            <Stack
              justifyContent={'flex-start'}
              textAlign={'left'}
              sx={{
                width: '100%',
              }}
            >
              <Typography
                color={'typography.primary'}
                variant={'BaseS_B'}
                textAlign={'left'}
                whiteSpace={'pre-wrap'}
                sx={{ flexGrow: 1 }}
              >
                {i18n.t('home-main-blazingFastDelivery', {
                  deliveryDay: deliveryDayExpression?.toUpperCase(),
                })}
              </Typography>
            </Stack>
            <img
              src={'/rocket.png'}
              alt={i18n.t('home-main-blazingFastDelivery', {
                deliveryDay: deliveryDayExpression?.toUpperCase(),
              })}
              width={'40px'}
              style={{
                flexGrow: 1,
                animation: isVisible ? '1s fly' : undefined,
                position: 'relative',
              }}
            />
          </Stack>
          <Typography variant={'Caption'} color={'typography.secondary'}>
            {i18n.t('home-main-bestServiceVibe')}
          </Typography>
        </Stack>
      </Button>
      <div ref={bannerRef} style={{ position: 'relative', bottom: -120 }} />
    </>
  );
}

async function checkTrackingPermission(): Promise<boolean> {
  if (window?.ReactNativeWebView?.requestJob) {
    const jobResult = await window.ReactNativeWebView?.requestJob<{
      granted: boolean;
    }>('TRACKING_CHECK_PERMISSION');
    return jobResult?.granted ?? false;
  }
  return true;
}
