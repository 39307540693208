import { Product } from '~/services/kup/models/Product';

export function extractProductBadgeInfo(product: Product | null) {
  if (!product) {
    return {
      isBest: false,
      isSoldOut: false,
      isLimitedPurchase: false,
      isHotDeal: false,
      isDelivery: false,
      isInPromotion: false,
    };
  }
  const isBest = product.best;
  const isSoldOut =
    !product?.stocks.quantity || !!(product.purchasableCount && product.purchasableCount < 1);
  const isLimitedPurchase = product.purchasableCount === 0;
  const isHotDeal = !isSoldOut && product.isInPromotion;
  const isDelivery = product.delivery;
  const isInPromotion = product.isInPromotion;

  return {
    isBest,
    isSoldOut,
    isLimitedPurchase,
    isHotDeal,
    isDelivery,
    isInPromotion,
  };
}

type ProductEventSection = {
  id?: string;
  slug?: string;
  type?: string;
  name?: string;
};

type ProductEventData = {
  id: string;
  name: string;
  price: number;
  originalPrice: number;
  discountRate: number;
  image: string;
  label: {
    isBest?: boolean;
    isSoldOut?: boolean;
    isLimitedPurchase?: boolean;
    isHotDeal?: boolean;
    isDelivery?: boolean;
    isInPromotion?: boolean;
  };
  section?: ProductEventSection;
};

export function extractProductEventData(
  product: Product,
  section?: ProductEventSection
): ProductEventData {
  const { isBest, isSoldOut, isLimitedPurchase, isHotDeal, isDelivery, isInPromotion } =
    extractProductBadgeInfo(product);
  return {
    id: product.id,
    name: product.name,
    price: product.price,
    originalPrice: product.originalPrice,
    discountRate: product.discountRate,
    image: product.imageUrls[0],
    label: {
      isBest,
      isSoldOut,
      isLimitedPurchase,
      isHotDeal,
      isDelivery,
      isInPromotion,
    },
    section,
  };
}
