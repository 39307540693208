import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import Cancel from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page from '~/components/Page';
import Stack from '~/components/Stack';
import { TextFieldProps } from '~/components/TextField';
import useProductBrands from '~/hooks/useProductBrands';
import BrandListItem from './components/BrandListItem';
import { ProductBrand } from '~/services/kup/models/ProductBrand';

export default function ShopBrandPage() {
  const { i18n } = useTranslation();
  const { productBrands } = useProductBrands();
  const [searchBrandKeyword, setSearchBrandKeyword] = useState('');
  const [searchBrands, setSearchBrands] = useState<ProductBrand[]>(productBrands);

  const handleInputBrandKeyword: TextFieldProps['onChange'] = (e) => {
    setSearchBrandKeyword(e.target.value ?? '');
  };

  useEffect(() => {
    setSearchBrands(
      productBrands.filter((brand) =>
        brand.name.toLowerCase().includes(searchBrandKeyword.toLowerCase())
      )
    );
  }, [searchBrandKeyword, productBrands]);

  return (
    <Page
      type={'page'}
      name={
        i18n.t('shop-all-brand') // All Brands
      }
    >
      <Stack alignItems={'center'} sx={{ px: '20px' }}>
        <OutlinedInput
          fullWidth
          placeholder={i18n.t('shop-brands-with-count', { brandCount: productBrands.length })}
          value={searchBrandKeyword}
          onChange={handleInputBrandKeyword}
          endAdornment={
            <InputAdornment position="end" sx={{ mr: '8px' }}>
              <IconButton aria-label={'clear'} onClick={() => setSearchBrandKeyword('')} edge="end">
                <Cancel />
              </IconButton>
            </InputAdornment>
          }
        />
      </Stack>
      <Stack gap={1} sx={{ my: '10px' }}>
        {searchBrands.map((brand) => (
          <BrandListItem key={brand.id} brand={brand} />
        ))}
      </Stack>
    </Page>
  );
}
