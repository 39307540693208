import Page from '~/components/Page';
import Container from '~/components/Container';
import Typography from '~/components/Typography';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export default function TermsPage() {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState<string>(i18n.language);
  const transitToEn = () => {
    setLang('en');
  };
  if (lang.toLowerCase() === 'zht') return <TermsPageZht transitToEn={transitToEn} />;
  if (lang.toLowerCase() === 'zh') return <TermsPageZh transitToEn={transitToEn} />;
  return <TermsPageEn />;
}

function TermsPageZh({ transitToEn }: { transitToEn: () => void }) {
  return (
    <Page type={'modal'} name={'条款与条件'}>
      <Container pb={'20px'} px={'20px'}>
        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            1. 简介
          </Typography>
          <p>
            本内容为 AI 翻译，详细内容请参考
            <span onClick={transitToEn} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              <b>英文</b>
            </span>{' '}
            版。
          </p>
          欢迎使用 K Tour Story Co., Ltd.
          的服务。使用我们的一日游服务、商务服务以及相关旅游信息，即表示您同意本条款与条件。请仔细阅读。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            2. 提供的服务
          </Typography>
          K Tour Story Co., Ltd. 提供以下服务：
          <ul>
            <li>
              <b>旅游服务：</b>
              <br />
              我们提供所需的服务来方便您的旅行，包括聊天服务、通知和必要的旅游信息。这些服务旨在确保您的旅行顺利进行，并为您提供及时的旅游资讯。我们会收集并使用通过旅行社或其他平台提交的预订信息，仅为提供我们的服务，信息将保存一年。有关您的旅行的具体详情，请参阅预订平台提供的信息。
            </li>
            <li>
              <b>商务服务：</b>
              <br />
              我们的在线商务服务为在韩国的外国人提供，用户可以在线订购和支付各种产品，并提供多语言的
              AI
              翻译信息。在特定时间前下单的订单可当天交付。客户需提供必要信息，以便于服务，数据将保存一年或按法律要求的期限保存。
            </li>
            <li>
              <b>旅游信息服务：</b>
              <br />
              我们提供多样的旅游相关信息和指南，可根据您的使用情况个性化展示。
            </li>
          </ul>
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            3. 用户责任
          </Typography>
          使用我们的服务即表示您同意：
          <ul>
            <li>提供准确和最新的个人信息。</li>
            <li>仅将服务用于合法用途。</li>
            <li>遵循导游和服务提供者的指示。</li>
            <li>审阅预订平台提供的预订信息。</li>
          </ul>
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            4. 订单与支付
          </Typography>
          通过我们的商务服务所下的所有订单均须根据接受条件和可用性进行。支付须在平台提供的方式进行购买时完成。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            5. 订单取消与退款
          </Typography>
          <ul>
            <li>
              <b>旅行取消：</b>
              <br />
              旅行取消必须通过最初预订的旅行平台或代理进行。取消将遵循该预订平台或代理提供的条款和条件。
            </li>
            <li>
              <b>订单取消：</b>
              <br />
              客户可以在产品准备发货之前通过服务取消订单。超过此阶段后需进入退款流程。
            </li>
            <li>
              <b>退款政策：</b>
              <br />
              购买14天内可以申请退款。退款申请须通过 K Tour Story Co., Ltd.
              提供的邮箱提交。产品必须为未使用且具备商业价值的状态。因产品缺陷或其他问题的退款亦可申请，因个人原因退款可能会有所限制。
            </li>
            <li>
              <b>退货运费：</b>
              <br />
              客户需承担退货运费，费用将基于必要的金额计算。如 K Tour Story Co., Ltd.
              安排取件，将收取5,000韩元的运费。
            </li>
          </ul>
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            6. 数据收集与隐私
          </Typography>
          使用我们的服务将涉及个人信息的收集，具体见隐私政策。您同意本条款即表示您同意隐私政策中的条款。
          <br />
          <b>数据存储：</b>
          <br />
          数据将使用 Google Cloud 服务存储，服务器位于韩国和新加坡，以促进服务提供。
          <br />
          <b>订单与支付信息：</b>
          <br />
          您的订单与支付信息将保存一年或法律要求的时间，超过此期限后信息将被及时删除，除非法律要求进一步保存。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            7. 服务通知与信息内容
          </Typography>
          <ul>
            <li>
              <b>服务通知：</b>
              <br />
              您将收到有关聊天服务和商务功能的推送通知。这些通知严格与服务操作相关，不含营销信息。
            </li>
            <li>
              <b>信息内容：</b>
              <br />
              您可能会收到旅游相关内容，如商店介绍和目的地指南，以杂志格式提供，仅供参考。
            </li>
          </ul>
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            8. 责任限制
          </Typography>
          K Tour Story Co., Ltd.
          对因使用我们的服务而产生的间接、附带或后果性损失不承担责任。我们的责任限制在支付的服务金额内。我们强烈建议客户购买旅游保险以涵盖旅行中的任何潜在风险。参加者须遵循导游指示并查看预订平台提供的信息。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            9. 知识产权
          </Typography>
          K Tour Story Co., Ltd. 提供的所有内容，包括旅游指南和信息资料，均为 K Tour Story Co., Ltd.
          所有。部分通过商务服务提供的产品可能为制造商所有。用户生成的内容如评论或照片，可能会被 K
          Tour Story Co., Ltd. 用于宣传目的和服务相关资料中。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            10. 条款更改
          </Typography>
          我们保留随时修改或终止本条款的权利。任何重要更改将通过我们网站的通知发布。继续使用我们的服务即表示您接受更新的条款。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            11. 适用法律与管辖权
          </Typography>
          本条款与条件受韩国法律管辖并按其解释。因本条款而产生的任何争议将由首尔中央地方法院管辖。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            12. 未成年人使用
          </Typography>
          未成年人可在家长同意和监督下使用我们的服务，且购买行为可能会受到限制。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            13. 联系我们
          </Typography>
          如果您对本条款与条件有任何疑问，请通过 info@ktourstory.com 联系我们，或访问位于韩国首尔
          Supyo-ro 12 的办公室。
        </Container>
      </Container>
    </Page>
  );
}

function TermsPageZht({ transitToEn }: { transitToEn: () => void }) {
  return (
    <Page type={'modal'} name={'條款與條件'}>
      <Container pb={'20px'} px={'20px'}>
        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            1. 簡介
          </Typography>
          <p>
            本內容為 AI 翻譯，詳細內容請參考
            <span onClick={transitToEn} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              <b>英文</b>
            </span>{' '}
            版。
          </p>
          歡迎使用 K Tour Story Co., Ltd.
          的服務。使用我們的一日遊服務、商務服務以及相關旅遊資訊，即表示您同意本條款與條件。請仔細閱讀。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            2. 提供的服務
          </Typography>
          K Tour Story Co., Ltd. 提供以下服務：
          <ul>
            <li>
              <b>旅遊服務：</b>
              <br />
              我們提供必要的服務來促進您的旅行，包括聊天服務、通知和必要的旅遊資訊。這些服務旨在確保您的旅行順利進行，並為您提供及時的旅遊資訊。我們會收集並使用通過旅行社或其他平台提交的預訂資訊，僅為提供服務，資訊將保存一年。關於您的旅行的具體詳細資訊，請參閱預訂平台提供的資訊。
            </li>
            <li>
              <b>商務服務：</b>
              <br />
              我們的在線商務服務為在韓國的外國人提供，客戶可以在線訂購和支付各種產品，並提供多語言的
              AI
              翻譯資訊。在特定時間前下單的訂單可當天交付。客戶需提供必要資訊，以便於服務，數據將保存一年或按法律要求的期限保存。
            </li>
            <li>
              <b>旅遊資訊服務：</b>
              <br />
              我們提供各類旅遊相關資訊和指南，可根據您的使用情況個性化展示。
            </li>
          </ul>
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            3. 用戶責任
          </Typography>
          使用我們的服務即表示您同意：
          <ul>
            <li>提供準確和最新的個人資訊。</li>
            <li>僅將服務用於合法用途。</li>
            <li>遵循導遊和服務提供者的指示。</li>
            <li>審閱預訂平台提供的預訂資訊。</li>
          </ul>
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            4. 訂單與支付
          </Typography>
          通過我們的商務服務所下的所有訂單均須根據接受條件和可用性進行。支付須在平台提供的方式進行購買時完成。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            5. 訂單取消與退款
          </Typography>
          <ul>
            <li>
              <b>旅行取消：</b>
              <br />
              旅行取消必須通過最初預訂的旅行平台或代理進行。取消將遵循該預訂平台或代理提供的條款和條件。
            </li>
            <li>
              <b>訂單取消：</b>
              <br />
              客戶可以在產品準備發貨之前通過服務取消訂單。超過此階段後需進入退款流程。
            </li>
            <li>
              <b>退款政策：</b>
              <br />
              購買14天內可以申請退款。退款申請須通過 K Tour Story Co., Ltd.
              提供的電子郵件提交。產品必須為未使用且具備商業價值的狀態。因產品缺陷或其他問題的退款亦可申請，因個人原因退款可能會有所限制。
            </li>
            <li>
              <b>退貨運費：</b>
              <br />
              客戶需承擔退貨運費，費用將基於必要的金額計算。如 K Tour Story Co., Ltd.
              安排取件，將收取5,000韓元的運費。
            </li>
          </ul>
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            6. 資料收集與隱私
          </Typography>
          使用我們的服務將涉及個人資訊的收集，具體見隱私政策。您同意本條款即表示您同意隱私政策中的條款。
          <br />
          <b>數據存儲：</b>
          <br />
          數據將使用 Google Cloud 服務存儲，伺服器位於韓國和新加坡，以促進服務提供。
          <br />
          <b>訂單與支付資訊：</b>
          <br />
          您的訂單與支付資訊將保存一年或法律要求的時間，超過此期限後資訊將被及時刪除，除非法律要求進一步保存。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            7. 服務通知與資訊內容
          </Typography>
          <ul>
            <li>
              <b>服務通知：</b>
              <br />
              您將收到有關聊天服務和商務功能的推送通知。這些通知嚴格與服務操作相關，不含營銷資訊。
            </li>
            <li>
              <b>資訊內容：</b>
              <br />
              您可能會收到旅遊相關內容，如商店介紹和目的地指南，以雜誌格式提供，僅供參考。
            </li>
          </ul>
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            8. 責任限制
          </Typography>
          K Tour Story Co., Ltd.
          對因使用我們的服務而產生的間接、附帶或後果性損失不承擔責任。我們的責任限制在支付的服務金額內。我們強烈建議客戶購買旅遊保險以涵蓋旅行中的任何潛在風險。參加者須遵循導遊指示並查看預訂平台提供的資訊。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            9. 知識產權
          </Typography>
          K Tour Story Co., Ltd. 提供的所有內容，包括旅遊指南和資訊資料，均為 K Tour Story Co., Ltd.
          所有。部分通過商務服務提供的產品可能為製造商所有。用戶生成的內容如評論或照片，可能會被 K
          Tour Story Co., Ltd. 用於宣傳目的和服務相關資料中。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            10. 條款更改
          </Typography>
          我們保留隨時修改或終止本條款的權利。任何重要更改將通過我們網站的通知發布。繼續使用我們的服務即表示您接受更新的條款。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            11. 適用法律與管轄權
          </Typography>
          本條款與條件受韓國法律管轄並按其解釋。因本條款而產生的任何爭議將由首爾中央地方法院管轄。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            12. 未成年人使用
          </Typography>
          未成年人可在家長同意和監督下使用我們的服務，且購買行為可能會受到限制。
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            13. 聯絡我們
          </Typography>
          如果您對本條款與條件有任何疑問，請通過 info@ktourstory.com 聯絡我們，或訪問位於韓國首爾
          Supyo-ro 12 的辦公室。
        </Container>
      </Container>
    </Page>
  );
}

function TermsPageEn() {
  return (
    <Page type={'modal'} name={'Terms & Condition'}>
      <Container pb={'20px'} px={'20px'}>
        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            1. Introduction
          </Typography>
          Welcome to K Tour Story Co., Ltd. By using our one-day tour services, commerce services,
          and accessing related travel information, you agree to these Terms & Conditions. Please
          read them carefully.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            2. Services Provided
          </Typography>
          K Tour Story Co., Ltd. offers the following services:
          <ul>
            <li>
              <b>Tour Services:</b> <br />
              We provide necessary services to facilitate your tour, including chat services,
              notifications, and essential tour and travel information. These services are designed
              to ensure a smooth and informed experience during your tour. To provide these
              services, we collect and use booking information that is submitted through travel
              agencies or other platforms. This information is processed and stored by K Tour Story
              Co., Ltd. for up to one year solely for the purpose of providing our services. Please
              refer to the information provided by your booking platform for specific details
              regarding your Tour.
            </li>
            <li>
              <b>Commerce Services:</b> <br />
              Our online commerce service is available to foreigners during their stay in Korea.
              Customers can order and pay for various products online, with AI-translated
              information provided in multiple languages. Orders placed before a certain time may be
              delivered the same day. Customers must provide the necessary information for this
              service, which will be stored for up to one year or as required by law.
            </li>
            <li>
              <b>Travel Information Service:</b> <br />
              We provide various travel-related information and guides, which may be personalized
              based on your tour usage and service interaction.
            </li>
          </ul>
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            3. User Responsibilities
          </Typography>
          By using our services, you agree to:
          <ul>
            <li>Provide accurate and up-to-date personal information.</li>
            <li>Use the services for lawful purposes only.</li>
            <li>Follow the instructions provided by tour guides and service providers.</li>
            <li>
              Review the booking information provided by the platform where the tour was reserved.
            </li>
          </ul>
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            4. Commerce Order and Payment
          </Typography>
          All orders made through our commerce service are subject to acceptance and availability.
          Payment must be made at the time of purchase through the methods provided on our platform.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            5. Commerce Order Cancellation and Refunds
          </Typography>
          <ul>
            <li>
              <b>Tour Cancellation:</b> <br />
              Tour cancellations must be processed through the platform or agency where the tour was
              originally booked. Cancellations will be subject to the terms and conditions provided
              by that booking platform or agency.
            </li>
            <li>
              <b>Commerce Order Cancellation:</b> <br />
              Customers can cancel their orders through the service until the product is in the
              process of being prepared for delivery. After this point, a refund procedure is
              necessary.
            </li>
            <li>
              <b>Commerce Refund Policy:</b> <br />
              Refunds are available within 14 days of purchase. Refund requests must be submitted
              via the email provided by K Tour Story Co., Ltd. The product must be unused and in a
              condition that allows for resale, retaining its commercial value. Refunds due to
              product defects or other issues are also possible. However, refunds due to a simple
              change of mind may be restricted.
            </li>
            <li>
              <b>Return Shipping Costs:</b> <br />
              The customer is responsible for return shipping costs, which will be calculated based
              on the required amount. If K Tour Story Co., Ltd. arranges the return pickup, a
              shipping fee of 5,000 KRW will be charged.
            </li>
          </ul>
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            6. Data Collection and Privacy
          </Typography>
          Your use of our services involves the collection of personal information as outlined in
          our Privacy Policy. By agreeing to these Terms & Conditions, you also agree to the terms
          set forth in our Privacy Policy.
          <br />
          <b>Data Storage:</b> <br />
          Data is stored using Google Cloud services and may be stored in servers located in Korea
          and Singapore to facilitate service provision.
          <br />
          <b>Order and Payment Information:</b> <br />
          Your order and payment information will be stored for one year or for the duration
          required by law, whichever is longer. After this period, the information will be promptly
          deleted unless further retention is required by legal obligations.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            7. Service Notifications and Marketing Content
          </Typography>
          <ul>
            <li>
              <b>Service Notifications:</b> <br />
              You will receive push notifications related to your use of the chat service and
              commerce features. These notifications are strictly related to service operations and
              do not include marketing pushes.
            </li>
            <li>
              <b>Informational Content:</b> <br />
              You may receive travel-related content, such as store introductions and destination
              guides, which are provided in a magazine-like format for informational purposes only.
            </li>
          </ul>
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            8. Limitation of Liability
          </Typography>
          K Tour Story Co., Ltd. is not liable for any indirect, incidental, or consequential
          damages arising from the use of our services. Our liability is limited to the amount paid
          for the services. We strongly recommend that customers purchase travel insurance to cover
          any potential risks during the tour. Tour participants must follow the guidance provided
          by their tour guides and review the information provided by the platform where the tour
          was booked.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            9. Intellectual Property
          </Typography>
          All content provided by K Tour Story Co., Ltd., including travel guides and informational
          material, is owned by K Tour Story Co., Ltd. Some products offered through the commerce
          service may be owned by the manufacturer. User-generated content, such as reviews or
          photos, may be used by K Tour Story Co., Ltd. for promotional purposes and in
          service-related materials.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            10. Changes to Terms
          </Typography>
          We reserve the right to modify or discontinue these Terms & Conditions at any time. Any
          significant changes will be communicated through a notice on our website. Continued use of
          our services following such changes will be deemed acceptance of the updated Terms &
          Conditions.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            11. Governing Law and Jurisdiction
          </Typography>
          These Terms & Conditions are governed by and construed in accordance with the laws of the
          Republic of Korea. Any disputes arising from these terms shall be resolved in the
          jurisdiction of the Seoul Central District Court.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            12. Use by Minors
          </Typography>
          Minors may use our services with parental consent and under parental supervision. However,
          there may be restrictions on purchases made by minors.
        </Container>

        <Container py={'8px'}>
          <Typography variant={'TitleS_B'} component={'p'}>
            13. Contact Us
          </Typography>
          If you have any questions regarding these Terms & Conditions, please contact us at
          info@ktourstory.com or visit our office at 12, Supyo-ro, Seoul, South Korea.
        </Container>
      </Container>
    </Page>
  );
}
