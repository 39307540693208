import { useNavigate } from 'react-router-dom';
import Page from '~/components/Page';
import Headline from '~/components/Headline.tsx';
import Container from '~/components/Container.tsx';
import TextField from '~/components/TextField.tsx';
import Typography from '~/components/Typography.tsx';
import Button from '~/components/Button.tsx';
import { useState } from 'react';
import FillBox from '~/components/FillBox.tsx';
import { useTranslation } from 'react-i18next';
import useKupStore from '~/services/kup/store';

export default function ShopAddressPage() {
  const { deliveryAddress, setDeliveryAddress, setInputAddress } = useKupStore((state) => ({
    deliveryAddress: state.deliveryAddress,
    setDeliveryAddress: state.setDeliveryAddress,
    setInputAddress: state.setInputAddress,
  }));
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [searchAddress, setSearchAddress] = useState<string>(deliveryAddress?.searchText ?? '');

  const handleMoveToDetail = async () => {
    setDeliveryAddress({
      ...deliveryAddress,
      placeName: undefined,
      address: undefined,
      googleAddress: undefined,
      googleAddressKr: undefined,
      searchText: searchAddress,
    });
    setInputAddress(searchAddress);
    navigate('/shop/address/detail');
  };

  return (
    <Page
      type={'page'}
      fixedBottom={
        <Container py={'8px'} px={'20px'}>
          <Button disabled={!searchAddress} fullWidth onClick={handleMoveToDetail}>
            {
              i18n.t('shop-address-next') // Next
            }
          </Button>
        </Container>
      }
    >
      <Container py={'8px'}>
        <Container pt={'16px'} pb={'20px'} px={'20px'}>
          <Headline
            headline={
              i18n.t('shop-address-whereToDeliver') // Where should we deliver it?
            }
            description={i18n.t('shop-address-addressDetailsYouCan')}
            descriptionProps={{
              whiteSpace: 'pre-wrap',
            }}
            size={'large'}
          />
        </Container>
        <Container
          p={'8px'}
          px={'20px'}
          stackProps={{
            gap: '16px',
            justifyContent: 'center',
          }}
        >
          <TextField
            fullWidth
            autoFocus
            placeholder={
              i18n.t('shop-address-enterAddressHere') // Enter Address Here (Available in Seoul Only)
            }
            value={searchAddress}
            onChange={(e) => setSearchAddress(e.target.value)}
          />
          <FillBox color={'fill'} display={'flex'} flexDirection={'column'}>
            <Typography variant={'BaseM_B'} component={'p'}>
              {
                i18n.t('shop-address-example') // Example
              }
            </Typography>
            <Typography component={'p'}>110 Sejong-daero, Jung-gu, Seoul</Typography>
            <Typography component={'p'}>12, Supyo-ro</Typography>
            <Typography component={'p'} variant={'FootNote'} pt={'12px'} whiteSpace={'pre-wrap'}>
              {
                i18n.t('shop-address-poweredByGoogleMaps') // This is powered by Google Maps.\nYou can also find a valid address using Google Maps or the search function below.
              }
            </Typography>
            {/* TODO: 주소 검색 기능 일시 제거 */}
            {/* <Link
              color={'fill'}
              to={'/shop/address/search'}
              style={{
                marginTop: '4px',
              }}
            >
              <Typography
                sx={{
                  width: '100%',
                  display: 'flex',
                  textDecoration: 'underline',
                }}
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                {
                  i18n.t('shop-address-findAddressByHotelName') // Find address by hotel
                }
              </Typography>
            </Link> */}
          </FillBox>
        </Container>
      </Container>
    </Page>
  );
}
