import api from '~kup/api';
import {
  ProductCollectionResponse,
  ProductCollectionGroupResponse,
  ProductCollectionGroup,
  ProductCollection,
} from '../models/ProductCollection';

const PRODUCT_COLLECTION_PATH = {
  PRODUCT_COLLECTION_GROUP: (slug: string) => ['product-collections', 'group', slug].join('/'),
  PRODUCT_COLLECTION_PRODUCTS: (slug: string) =>
    ['product-collections', slug, 'products'].join('/'),
  PRODUCT_COLLECTION_BY_BRAND: (brandId: string) =>
    ['product-collections', 'brands', brandId].join('/'),
};

export async function getProductCollectionGroup(slug: string) {
  const resp = await api.get(PRODUCT_COLLECTION_PATH.PRODUCT_COLLECTION_GROUP(slug));
  const productCollectionGroupResponse: ProductCollectionGroupResponse = resp.data.data;
  return new ProductCollectionGroup(productCollectionGroupResponse);
}

export async function getProductCollectionProducts(slug: string) {
  const resp = await api.get(PRODUCT_COLLECTION_PATH.PRODUCT_COLLECTION_PRODUCTS(slug));
  const productCollectionResponse: ProductCollectionResponse = resp.data.data;
  return new ProductCollection(productCollectionResponse);
}

export async function getProductCollectionByBrand(
  brandId: string,
  orderBy?: 'orderWeight' | 'random'
) {
  const resp = await api.get(PRODUCT_COLLECTION_PATH.PRODUCT_COLLECTION_BY_BRAND(brandId), {
    params: { orderBy },
  });
  const productCollectionResponse: ProductCollectionResponse[] = resp.data.data;
  return productCollectionResponse.map((collection) => new ProductCollection(collection));
}
