import Container from '~/components/Container';
import ProductList from '~/components/ProductList';
import { useTranslation } from 'react-i18next';
import useProductsKeepState from '~/hooks/useProductsKeepState';
import { useState } from 'react';
import { PRODUCT_ORDER_BY } from '../constants';
import Tabs from '~/components/Tabs';
import { Product } from '~/services/kup/models/Product';
import { extractProductEventData } from '~/utils/product';
import useBatchLogger from '~/hooks/useBatchLogger';

export default function MustHaveProducts() {
  const { i18n } = useTranslation();
  const { logAction } = useBatchLogger();
  const [selectedOrderBy, setSelectedOrderBy] = useState<string>('recommendation');
  const { products, page, hasNextPage, next } = useProductsKeepState(
    `main:${selectedOrderBy}`,
    {
      orderBy: selectedOrderBy?.split(':')[0] ?? undefined,
      orderMethod: selectedOrderBy?.split(':')[1] ?? undefined,
    },
    { pageSize: 8 }
  );

  const loadNext = () => {
    if (hasNextPage) {
      logAction({
        eventType: 'LOAD',
        eventTarget: 'SHOP_PRODUCT_LIST',
        eventCategory: 'product',
        eventData: {
          page: page + 1,
          orderBy: selectedOrderBy,
        },
      });
      next();
    }
  };

  const handleClickProductItem = (product: Product) => {
    logAction({
      eventType: 'CLICK',
      eventTarget: 'SHOP_PRODUCT',
      eventCategory: 'product',
      eventData: extractProductEventData(product, { type: 'shop' }),
    });
  };

  const handleLikeProduct = (product: Product) => {
    if (!product.isLiked) {
      logAction({
        eventType: 'CLICK',
        eventTarget: 'SHOP_PRODUCT_LIKE',
        eventCategory: 'product',
        eventData: extractProductEventData(product, { type: 'shop' }),
      });
    }
  };

  return (
    <>
      <Container pb={'4px'}>
        <Tabs
          scrollable
          value={selectedOrderBy}
          tabLabelVariant={'BaseS'}
          onChange={(_, tab) => setSelectedOrderBy(tab)}
          sx={{
            px: '20px',
          }}
          tabs={[
            ...PRODUCT_ORDER_BY.map((orderBy) => ({
              label: i18n.t(orderBy.label),
              value: orderBy.value,
            })),
          ]}
        ></Tabs>
      </Container>
      <Container>
        <ProductList
          type={'pair'}
          products={products}
          onInfiniteScroll={loadNext}
          onClickProductItem={handleClickProductItem}
          onLikeProductItem={handleLikeProduct}
        />
      </Container>
    </>
  );
}
