import { SvgIcon, SvgIconProps } from '@mui/material';

interface CustomIconProps extends SvgIconProps {
  size: number | string;
}

export default function IconBorderedStar({ size, ...props }: CustomIconProps) {
  return (
    <SvgIcon {...props} sx={{ width: size, height: size, ...props.sx }}>
      <svg viewBox="0 0 35 33" fill="none">
        <path
          d="M15.7065 1.63397C16.4402 0.14748 18.5598 0.14748 19.2935 1.63397L22.9125 8.96695C23.2038 9.55723 23.767 9.96637 24.4184 10.061L32.5108 11.2369C34.1512 11.4753 34.8063 13.4912 33.6192 14.6483L27.7635 20.3562C27.2921 20.8157 27.077 21.4777 27.1883 22.1265L28.5707 30.1862C28.8509 31.82 27.136 33.066 25.6688 32.2946L18.4307 28.4893C17.848 28.183 17.152 28.183 16.5693 28.4893L9.33124 32.2946C7.86398 33.066 6.14912 31.82 6.42934 30.1862L7.81169 22.1265C7.92297 21.4777 7.70787 20.8157 7.2365 20.3562L1.38077 14.6483C0.193735 13.4912 0.848755 11.4753 2.4892 11.2369L10.5816 10.061C11.233 9.96637 11.7962 9.55723 12.0875 8.96695L15.7065 1.63397Z"
          fill="#FFDE2F"
        />
      </svg>
    </SvgIcon>
  );
}
