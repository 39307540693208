import { useNavigate } from 'react-router-dom';
import Page from '~/components/Page';
import Headline from '~/components/Headline.tsx';
import Container from '~/components/Container.tsx';
import TextField from '~/components/TextField.tsx';
import Button from '~/components/Button.tsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useKupStore from '~/services/kup/store';
import Typography from '~/components/Typography';
import FillBox from '~/components/FillBox';

export default function ShopAddressRequirementPage() {
  const { deliveryAddress, setDeliveryAddress } = useKupStore((state) => ({
    deliveryAddress: state.deliveryAddress,
    setDeliveryAddress: state.setDeliveryAddress,
  }));
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [request, setRequest] = useState<string>(deliveryAddress?.request ?? '');

  const handleMoveToCheck = async () => {
    setDeliveryAddress({ ...deliveryAddress, request });
    navigate('/shop/address/check');
  };

  return (
    <Page
      type={'page'}
      fixedBottom={
        <Container py={'8px'} px={'20px'}>
          <Button fullWidth onClick={handleMoveToCheck}>
            {
              i18n.t('shop-address-next') // Next
            }
          </Button>
        </Container>
      }
    >
      <Container py={'8px'}>
        <Container pt={'16px'} pb={'20px'} px={'20px'}>
          <Headline
            headline={
              i18n.t('shop-address-provideDeliveryInstructions') // Instructions for delivery
            }
            description={i18n.t('shop-delivery-mainEntrancePasscode')}
            descriptionProps={{ color: 'error', variant: 'FootNote_B' }}
            size={'large'}
          />
        </Container>
        <Container
          p={'8px'}
          px={'20px'}
          stackProps={{
            gap: '16px',
            justifyContent: 'center',
          }}
        >
          <TextField
            fullWidth
            autoFocus
            placeholder={i18n.t('shop-delivery-entrancePasswordInfo')}
            value={request}
            onChange={(e) => setRequest(e.target.value)}
          />
        </Container>
        <Container
          p={'8px'}
          px={'20px'}
          stackProps={{
            gap: '16px',
            justifyContent: 'center',
          }}
        >
          <FillBox color={'fill'} display={'flex'} flexDirection={'column'}>
            <Typography variant={'BaseM_B'} component={'p'}>
              {i18n.t('common-notice')}
            </Typography>
            <Typography variant={'FootNote'} component={'p'}>
              • {i18n.t('shop-delivery-deliverySeoulOnly')}
            </Typography>
            <Typography variant={'FootNote'} component={'p'}>
              • {i18n.t('shop-delivery-airbnbPasscode')}
            </Typography>
            <Typography variant={'FootNote'} component={'p'}>
              • {i18n.t('shop-delivery-addressClarification')}
            </Typography>
            <Typography variant={'FootNote'} component={'p'}>
              • {i18n.t('shop-delivery-cancellationIfUndeliverable')}
            </Typography>
          </FillBox>
        </Container>
      </Container>
    </Page>
  );
}
