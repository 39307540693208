import { ReactNode } from 'react';
import { Link, Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import { default as MuiPaper } from '@mui/material/Paper';
import { default as MuiBottomNavigation } from '@mui/material/BottomNavigation';
import { default as MuiBottomNavigationAction } from '@mui/material/BottomNavigationAction';

import { navigations } from '~/router.tsx';
import useSafeArea from '~/hooks/useSafeArea.ts';
import { useTranslation } from 'react-i18next';

export default function MainLayout({
  children,
  restoreScroll = true,
}: {
  children?: ReactNode;
  restoreScroll?: boolean;
  hashPages?: (hash: string) => ReactNode | undefined;
}) {
  const location = useLocation();
  const { i18n } = useTranslation();
  const pathnameSegments = location.pathname.split('/');
  const { bottomSafeArea } = useSafeArea();

  return (
    <>
      {children ?? <Outlet />}
      <MuiPaper
        sx={{
          position: 'sticky',
          bottom: 0,
          zIndex: 500,
          pb: `${bottomSafeArea}px`,
        }}
      >
        <MuiBottomNavigation showLabels value={pathnameSegments[1]}>
          {navigations.map((n) => (
            <MuiBottomNavigationAction
              replace={false}
              key={n.path}
              label={i18n.t(`layout-mainLayout-${n.label.toLowerCase()}`)}
              icon={n.icon}
              component={Link}
              to={n.path}
              value={n.path.split('/')[1]}
            />
          ))}
        </MuiBottomNavigation>
        {restoreScroll && <ScrollRestoration />}
      </MuiPaper>
    </>
  );
}
