import { useLayoutEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useKintStore } from '~kint/store';
import callReservation from '~kint/controllers/callReservation.ts';

import { useSnackBar } from '~/contexts/SnackBarContext.tsx';

import Page from '~/components/Page';
import Container from '~/components/Container';
import Button from '~/components/Button';
import TextField from '~/components/TextField';
import Headline from '~/components/Headline';
import CSPopup from '~/components/CSPopup.tsx';
import useKupAuthWithKint from '~/pages/reservation/hooks/useKupAuthWithKint.tsx';
import Progress from '~/components/Progress.tsx';
import recallChatting from '~kint/controllers/recallChatting.ts';
import useLoading from '~/hooks/useLoading.tsx';
import InstallPopup from '~/components/InstallPopup.tsx';

function ReservationMainPage() {
  const navigate = useNavigate();
  const signIn = useKupAuthWithKint();
  const [searchParams] = useSearchParams();
  const { showSnackBar } = useSnackBar();
  const [openCS, setOpenCS] = useState<boolean>(false);
  const { startLoading, stopLoading, loading } = useLoading();
  const {
    name,
    reservation,
    participant,
    reservationKey,
    setReservationKey,
    setTourChatting,
    setParticipant,
  } = useKintStore((state) => ({
    reservationKey: state.reservationKey,
    setReservationKey: state.setReservationKey,
    reservation: state.reservation,
    participant: state.participant,
    name: state.name,
    setTourChatting: state.setTourChatting,
    setParticipant: state.setParticipant,
  }));

  const { i18n } = useTranslation();
  const givenReservationKey = searchParams.get('reservationId') ?? searchParams.get('agencyCode');
  const givenUserName = searchParams.get('name');
  const [inputKey, setInputKey] = useState<string>(givenReservationKey ?? reservationKey ?? '');
  const hasReservation = reservationKey && reservation;
  const sameReservation =
    reservation?.agencyCode?.toLowerCase() === reservationKey?.toLowerCase() ||
    reservation?.id?.toLowerCase() === reservationKey?.toLowerCase();
  const hasTourChat = participant?.reservationId === reservation?.id;
  const isReApproach =
    hasReservation &&
    sameReservation &&
    hasTourChat &&
    givenReservationKey &&
    givenReservationKey.toLowerCase() === reservationKey.toLowerCase();

  const isRedirectApproach = givenReservationKey && givenUserName;

  useLayoutEffect(() => {
    if (isReApproach) {
      if (!reservation) return;
      if (!name) return;
      (async () => {
        startLoading();
        await signIn(name);
        const { chatting, participant } = await recallChatting(name, reservation);
        setParticipant(participant);
        setTourChatting(chatting);
        if (!chatting) {
          throw 'No Chatting';
        }
        navigate(`/`, { state: { fromEntry: true }, replace: true });
      })()
        .catch(() => {
          showSnackBar({
            message: i18n.t('reservation-main-cannotVerifyReservation'), // Cannot verify your reservation
            lift: true,
          });
          localStorage.clear();
        })
        .finally(() => {
          stopLoading();
        });
    }
  }, [isReApproach, name, reservation]);

  useLayoutEffect(() => {
    if (isRedirectApproach) {
      startLoading();
      setReservationKey(givenReservationKey);
      setInputKey(givenReservationKey);
      callReservation(givenReservationKey)
        .then((reservation) => {
          if (!reservation) {
            throw new Error('Cannot find reservation.');
          }
          navigate('/reservation/name', { state: { givenName: givenUserName } });
        })
        .catch(() => {
          showSnackBar({
            severity: 'warning',
            message: i18n.t('reservation-main-cannotCheckReservation'), // Cannot check your reservation.
            lift: true,
          });
        })
        .finally(() => {
          stopLoading();
        });
    }
  }, [isRedirectApproach, givenReservationKey, givenUserName]);

  const handleToggleCS = () => {
    setOpenCS((prev) => !prev);
  };
  const handleSubmit = () => {
    setReservationKey(inputKey);
    startLoading();
    callReservation(inputKey)
      .then((reservation) => {
        if (!reservation) {
          throw new Error('Cannot find reservation.');
        }
        navigate('/reservation/confirm', { state: { reservation } });
      })
      .catch(() => {
        showSnackBar({
          severity: 'warning',
          message: i18n.t('reservation-main-cannotCheckReservation'), // Cannot check your reservation.
          lift: true,
        });
      })
      .finally(() => {
        stopLoading();
      });
  };

  if (loading) {
    return (
      <Container
        background={'paper'}
        sx={{}}
        stackProps={{
          row: true,
          alignItems: 'center',
          justifyContent: 'center',
          sx: {
            minHeight: '100vh',
            minWidth: '100vw',
          },
        }}
      >
        <Progress type={'circular'} />
      </Container>
    );
  }

  return (
    <>
      <Page
        navigationBarProps={{
          lead: null,
        }}
        fixedBottom={
          <Container bottom py={'8px'} px={'20px'}>
            <Button
              fullWidth
              disabled={!inputKey}
              size={'large'}
              variant={'contained'}
              loading={loading}
              onClick={handleSubmit}
            >
              {
                i18n.t('reservation-main-next') // Next
              }
            </Button>
          </Container>
        }
      >
        <Container py={'8px'}>
          <Container py={'16px'} px={'20px'}>
            <Headline
              size={'large'}
              headline={
                i18n.t('reservation-main-reservationId') // Your Reservation ID
              }
              subHeadline={
                i18n.t('reservation-main-hiLetMeKnow') // Hi, Please let me know
              }
            />
          </Container>
          <Container py={'8px'} px={'20px'}>
            <TextField
              fullWidth
              name={'key'}
              placeholder={i18n.t('reservation-main-enterReservationCode')}
              value={inputKey}
              onChange={(e) => {
                setInputKey(e.target.value);
              }}
              onEnter={handleSubmit}
              onClear={
                inputKey
                  ? () => {
                      setInputKey('');
                    }
                  : undefined
              }
            />
          </Container>
          <Container
            py={'12px'}
            px={'20px'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              size={'small'}
              variant={'contained'}
              color={'fill'}
              sx={{
                minWidth: '126px',
              }}
              onClick={handleToggleCS}
            >
              {
                i18n.t('reservation-main-needHelp') // Do you need help?
              }
            </Button>
          </Container>
        </Container>
      </Page>
      <CSPopup open={openCS} onClose={handleToggleCS} email={'support@ktourstory.com'} />
      <InstallPopup agencyCode={inputKey} name={givenUserName ?? undefined} />
    </>
  );
}

export default ReservationMainPage;
