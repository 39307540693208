import useBrandPick from '../hooks/useBrandPick';
import Container from '~/components/Container';
import ImageView from '~/components/ImageView';
import Typography from '~/components/Typography';
import Divider from '~/components/Divider';
import ProductItem from '~/components/ProductItem';
import Stack from '~/components/Stack';
import Button from '~/components/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import LikeButton from '~/components/LikeButton';
import { likeProductBrand } from '~/services/kup/controllers/productBrand';
import { unlikeProductBrand } from '~/services/kup/controllers/productBrand';
import useBatchLogger from '~/hooks/useBatchLogger';
import { extractProductEventData } from '~/utils/product';
import { Product } from '~/services/kup/models/Product';
import useKupStore from '~kup/store';

export default function BrandPickSection() {
  const { i18n } = useTranslation();
  const { logAction } = useBatchLogger();
  const { pickedBrand, pickedBrandCollection, setPickedBrand } = useBrandPick();
  const navigate = useNavigate();

  const { toggleBrandLike, brandLikeDict } = useKupStore((state) => ({
    toggleBrandLike: state.toggleBrandLike,
    brandLikeDict: state.brandLikeDict,
  }));

  if (pickedBrand) pickedBrand.isLiked = pickedBrand.id in brandLikeDict;

  const handleLike = async (liked: boolean) => {
    if (pickedBrand === null) return;
    if (liked) {
      await unlikeProductBrand(pickedBrand.id).then(() => {
        setPickedBrand({ ...pickedBrand, isLiked: false });
      });
    } else {
      await likeProductBrand(pickedBrand.id).then(() => {
        setPickedBrand({ ...pickedBrand, isLiked: true });
      });
      toggleBrandLike(pickedBrand.id, !liked);
      logAction({
        eventType: 'CLICK',
        eventTarget: 'HOME_BRAND_LIKE',
        eventCategory: 'brand',
        eventData: {
          id: pickedBrand.id,
          slug: pickedBrand.slug,
          name: pickedBrand.name,
        },
      });
    }
  };

  const handleClickProductItem = (product: Product) => {
    logAction({
      eventType: 'CLICK',
      eventTarget: 'HOME_PRODUCT',
      eventCategory: 'product',
      eventData: extractProductEventData(product, {
        type: 'main-brand',
        slug: pickedBrandCollection?.slug ?? '',
        name: pickedBrandCollection?.name ?? '',
      }),
    });
  };

  const handleLikeProduct = (product: Product) => {
    if (!product.isLiked) {
      logAction({
        eventType: 'CLICK',
        eventTarget: 'HOME_PRODUCT_LIKE',
        eventCategory: 'product',
        eventData: extractProductEventData(product, {
          type: 'main-brand',
          slug: pickedBrandCollection?.slug ?? '',
          name: pickedBrandCollection?.name ?? '',
        }),
      });
    }
  };

  return (
    <>
      {pickedBrand && (
        <Container px={'20px'} sx={{ mt: '20px' }}>
          <Typography variant={'h5'} sx={{ py: '10px' }}>
            {i18n.t('home-main-brand-pick')}
          </Typography>
          {pickedBrand.images[0] && (
            <ImageView
              borderRadius={'0px'}
              src={pickedBrand.images[0]}
              height={'100%'}
              width={'100%'}
            />
          )}
          <Stack sx={{ pt: '10px', pb: '20px' }} alignItems={'center'}>
            <Typography variant="TitleM_B">{pickedBrand.name}</Typography>
            <Grid container alignItems={'center'}>
              <Grid item xs={11}>
                <Typography variant={'FootNote'}>{pickedBrand.description}</Typography>
              </Grid>
              <Grid item xs={1}>
                <LikeButton liked={pickedBrand.isLiked} type={'square'} onClick={handleLike} />
              </Grid>
            </Grid>
          </Stack>
          {pickedBrandCollection?.collectionItems &&
            pickedBrandCollection.collectionItems.length > 0 && (
              <>
                <Divider type="line" color="divider" />
                <Stack sx={{ pt: '20px' }} gap={1}>
                  <Typography variant="BaseS_B">{pickedBrandCollection?.name}</Typography>
                  <Stack
                    row
                    justifyContent={'space-evenly'}
                    gap={'16px'}
                    sx={{ overflowX: 'auto' }}
                  >
                    {pickedBrandCollection?.collectionItems?.map((item) => (
                      <ProductItem
                        type="medium"
                        key={item.product?.id}
                        product={item.product!}
                        sx={{ flex: 1 }}
                        onClickProductItem={(product) => handleClickProductItem(product)}
                        onLikeProductItem={(product) => handleLikeProduct(product)}
                      />
                    ))}
                  </Stack>
                </Stack>
              </>
            )}
          <Container sx={{ mt: '10px' }}>
            <Button
              fullWidth
              size={'medium'}
              color={'fill'}
              variant={'contained'}
              onClick={() => navigate(`/shop/brand/${pickedBrand.id}`)}
            >
              {i18n.t('home-main-see-more-specific-brand', { brandName: pickedBrand.name })}
            </Button>
          </Container>
        </Container>
      )}
    </>
  );
}
