import { IIssuedCoupon } from '~kup/models/Coupon.ts';
import { getPossessionCoupons } from '~kup/controllers/coupon.ts';
import { useCallback, useLayoutEffect, useState } from 'react';

type UsePossessionCoupons = {
  possessionCoupons: IIssuedCoupon[];
  callPossessionCoupons: () => void;
};

export default function usePossessionCoupons(): UsePossessionCoupons {
  const [possessionCoupons, setPossessionCoupons] = useState<IIssuedCoupon[]>([]);

  const callPossessionCoupons = useCallback(() => {
    getPossessionCoupons().then((possessionCoupons) => {
      setPossessionCoupons(possessionCoupons);
    });
  }, []);

  useLayoutEffect(() => {
    callPossessionCoupons();
  }, []);

  return {
    possessionCoupons,
    callPossessionCoupons,
  };
}
