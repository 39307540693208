import Button from '~/components/Button';
import Grid from '@mui/material/Grid';
import Popover from '~/components/ModalPopover.tsx';
import { useTranslation } from 'react-i18next';
import Stack from '~/components/Stack';
import Typography from '~/components/Typography';
import Container from '~/components/Container';
import { useNavigate } from 'react-router-dom';

export default function AllDonePopover({
  open,
  onClose,
  onClickReviewAnother,
  isRemainNotReviewed,
}: {
  open: boolean;
  onClose: () => void;
  onClickReviewAnother: () => void;
  isRemainNotReviewed: boolean;
}) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const handleOnClose = () => {
    onClose();
    navigate('/shop/my-review');
  };

  return (
    <Popover
      open={open}
      onClose={onClose}
      width={'90vw'}
      fixedBottom={
        isRemainNotReviewed ? (
          <Grid container sx={{ px: '20px', pb: '16px' }}>
            <Grid item xs={3} sx={{ pr: '4px' }}>
              <Button
                fullWidth
                variant={'contained'}
                color={'secondary'}
                size={'small'}
                onClick={handleOnClose}
              >
                {i18n.t('common-close')}
              </Button>
            </Grid>
            <Grid item xs={9} sx={{ pl: '4px' }}>
              <Button fullWidth variant={'contained'} size={'small'} onClick={onClickReviewAnother}>
                {i18n.t('shop-product-review-another-button')}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Container px={'20px'} pb={'16px'}>
            <Button
              fullWidth
              variant={'contained'}
              color={'secondary'}
              size={'small'}
              onClick={handleOnClose}
            >
              {i18n.t('common-close')}
            </Button>
          </Container>
        )
      }
    >
      <Stack px={'20px'} py={'24px'} gap={'10px'}>
        <Typography variant={'TitleS_B'}>{i18n.t('shop-product-review-all-done')}</Typography>
        <Typography variant={'FootNote'} color={'typography.secondary'}>
          {i18n.t('shop-product-review-all-done-description')}
        </Typography>
      </Stack>
    </Popover>
  );
}
