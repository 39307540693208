import { FavoriteBorder, Favorite } from '@mui/icons-material';
import { Box } from '@mui/material';

export type LikeButtonProps = {
  liked?: boolean;
  type: 'simple' | 'circle' | 'square';
  onClick?: (liked: boolean) => void;
};

export default function LikeButton(props: LikeButtonProps) {
  const { liked = false, type, onClick } = props;
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (onClick) {
      onClick(liked);
    }
  };

  const iconSize = type === 'simple' ? '24px' : '20px';
  const likeButton = liked ? (
    <Favorite sx={{ fontSize: iconSize, color: '#FF4500' }} />
  ) : (
    <FavoriteBorder sx={{ fontSize: iconSize, color: type === 'circle' ? '#ffffff' : '#0b377a' }} />
  );

  const NoneBackgroundButton = (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} onClick={handleClick}>
      {likeButton}
    </Box>
  );

  const CircleBackgroundButton = (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      onClick={handleClick}
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
      }}
    >
      {likeButton}
    </Box>
  );

  const SquareBackgroundButton = (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      onClick={handleClick}
      sx={{
        backgroundColor: '#f5f7fa',
        width: '40px',
        height: '40px',
        borderRadius: '20%',
      }}
    >
      {likeButton}
    </Box>
  );

  switch (type) {
    case 'simple':
      return NoneBackgroundButton;
    case 'circle':
      return CircleBackgroundButton;
    case 'square':
      return SquareBackgroundButton;
  }
}
