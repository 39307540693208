import { Chip } from '@mui/material';
import Stack from './Stack';
import { useEffect, useState } from 'react';

export type ChipButtonGroupProps = {
  defaultSelectedKey?: string;
  buttonInfos: {
    label: string;
    key: string;
  }[];
  onChange?: (key: string) => void;
};

export default function ChipButtonGroup(props: ChipButtonGroupProps) {
  const { defaultSelectedKey, buttonInfos, onChange } = props;
  const [selectedKey, setSelectedKey] = useState<string>(defaultSelectedKey ?? '');

  useEffect(() => {
    onChange && onChange(selectedKey);
  }, [selectedKey]);

  return (
    <Stack
      direction={'row'}
      gap={1}
      justifyContent={'flex-start'}
      alignItems={'center'}
      sx={{ width: '90vw', overflowX: 'auto', flexWrap: 'wrap' }}
    >
      {buttonInfos.map((buttonInfo) => {
        const isSelected = selectedKey === buttonInfo.key;
        return (
          <Chip
            key={buttonInfo.key}
            label={buttonInfo.label}
            onClick={() => setSelectedKey(buttonInfo.key)}
            color={isSelected ? 'primary' : 'default'}
            sx={(theme) => ({
              ...theme.typography.FootNote_B,
              borderColor: theme.palette.typography.quaternary,
              color: isSelected ? 'primary.main' : theme.palette.typography.tertiary,
              backgroundColor: isSelected ? '#e9f3fe' : 'transparent',
            })}
            variant={'outlined'}
          />
        );
      })}
    </Stack>
  );
}
