import { Link } from 'react-router-dom';
import Page from '~/components/Page';
import Spotlight from '~/components/Spotlight.tsx';
import Button from '~/components/Button.tsx';
import Icon from '~/components/Icon';

import { useTranslation } from 'react-i18next';
import useSyncShopping from '~/hooks/useSyncShopping.ts';

export default function ShopOrderCancelPage() {
  useSyncShopping();

  const { i18n } = useTranslation();
  const basketLink = '/shop/basket';

  return (
    <Page
      type={'modal'}
      name={
        i18n.t('shop-order-paymentCanceledTitle') // Order
      }
      backgroundColor={'paper'}
    >
      <Spotlight
        textAlign={'center'}
        lead={
          <Icon
            type={'circle'}
            size={'40px'}
            width={'48px'}
            backgroundColor={'typography.tertiary'}
          >
            priority_high
          </Icon>
        }
        headline={
          i18n.t('shop-order-paymentCanceledDescription') // Your order failed
        }
        headlineProps={{
          variant: 'BaseL_B',
        }}
        description={
          i18n.t('common-tryAgain') // 결제 실패로 주문을 처리할 수 없습니다. 3초뒤 장바구니로 돌아갑니다.
        }
        descriptionProps={{
          whiteSpace: 'pre-wrap',
        }}
        trail={
          <Button
            component={Link}
            to={basketLink}
            size="small"
            variant="contained"
            color="fill"
            sx={{
              minWidth: '126px',
            }}
          >
            {
              i18n.t('shop-order-backToBasket') // Go Back to Order
            }
          </Button>
        }
      />
    </Page>
  );
}
