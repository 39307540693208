import Page from '~/components/Page';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '~/components/Typography';
import Stack from '~/components/Stack';
import { useTranslation } from 'react-i18next';
import { OrderItem } from '~/services/kup/models/Order';
import ImageView from '~/components/ImageView';
import { ProductReview } from '~/services/kup/models/ProductReview';
import { useEffect, useState } from 'react';
import Button from '~/components/Button';
import TextField from '~/components/TextField';
import ImageUploader from './components/ImageUploader';
import { writeProductReview } from '~/services/kup/controllers/productReview';
import BackInReviewPopover from './components/BackInReviewPopover';
import AllDonePopover from './components/AllDonePopover';
import OrderItemReviewSelection from '../myOrder/components/OrderItemReviewSelection';
import Rating from '~/components/Rating';
import { Product } from '~/services/kup/models/Product';

type ProductForReview = {
  productId: string;
  productName?: string;
  productBrand?: string;
  productImageUrls?: string[];
};

function generateDefaultReview(productId: string, orderId: string): ProductReview {
  return {
    productId,
    orderId,
    rating: 0,
    comment: '',
    images: [],
  };
}

function getProductForReview(orderItem?: OrderItem, product?: Product): ProductForReview {
  return {
    productId: (product?.id ?? orderItem?.productId)!,
    productName: product?.name ?? orderItem?.productName,
    productBrand: product?.brand?.name ?? orderItem?.productBrand,
    productImageUrls: product?.imageUrls ?? orderItem?.productImageUrls,
  };
}

export default function ProductReviewWritingForm() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const {
    orderId,
    orderItem,
    orderItems,
    productOptionNames,
    review: passedReview,
  } = useLocation().state as {
    review?: ProductReview;
    orderId: string;
    orderItem: OrderItem;
    orderItems: OrderItem[];
    productOptionNames?: string[];
  };
  const [otherOrderItems, setOtherOrderItems] = useState<OrderItem[]>([]);
  const [productForReview, setProductForReview] = useState<ProductForReview>(
    getProductForReview(orderItem, passedReview?.product)
  );
  const [review, setReview] = useState<ProductReview>(
    passedReview || generateDefaultReview(productForReview.productId, orderId)
  );
  const [cancelReviewPopoverOpen, setCancelReviewPopoverOpen] = useState(false);
  const [allDonePopoverOpen, setAllDonePopoverOpen] = useState(false);
  const [openItemReviewSelection, setOpenItemReviewSelection] = useState(false);
  const isModify = passedReview !== undefined;

  useEffect(() => {
    if (orderItems) {
      setOtherOrderItems(
        orderItems.filter((item) => item.productId !== productForReview.productId)
      );
    }
  }, [orderItems]);

  useEffect(() => {
    if (!passedReview) {
      setProductForReview(getProductForReview(orderItem));
      setReview(generateDefaultReview(orderItem.productId, orderId));
    }
  }, [orderItem]);

  const serializeReviewForSubmit = (review: ProductReview): ProductReview => {
    return {
      id: review.id,
      productId: review.product?.id ?? review.productId,
      orderId: review.order?.id ?? review.orderId,
      rating: review.rating ?? 0,
      comment: review.comment ?? '',
      images: review.images ?? [],
      productOptionNames: review.productOptionNames ?? productOptionNames,
    };
  };

  const handleSubmit = async () => {
    const serializedReview = serializeReviewForSubmit(review);
    await writeProductReview(serializedReview);
    const remainNotReviewed = [
      ...otherOrderItems.filter((item) => item.productId !== serializedReview.productId),
    ];
    setOtherOrderItems(remainNotReviewed);
    setAllDonePopoverOpen(true);
  };

  return (
    <Page
      type={'page'}
      name={i18n.t('shop-product-write-review-page-title')}
      fixedBottom={
        <Button fullWidth variant={'contained'} onClick={handleSubmit}>
          Submit
        </Button>
      }
      fixedBottomProps={{
        sx: {
          px: '20px',
          py: '8px',
        },
      }}
      onBack={() => {
        setCancelReviewPopoverOpen(true);
        return false;
      }}
    >
      <Stack alignItems={'center'} gap={'10px'} my={'60px'} py={'12px'} px={'20px'}>
        {productForReview.productImageUrls?.[0] && (
          <ImageView src={productForReview.productImageUrls?.[0]} width={'80px'} height={'80px'} />
        )}
        <Typography variant={'FootNote'} align={'center'}>
          {productForReview.productBrand}
        </Typography>
        <Stack>
          <Typography variant={'BaseL_B'} align={'center'}>
            {productForReview.productName}
          </Typography>
          <Typography variant={'BaseS'} color={'typography.secondary'} align={'center'}>
            {productOptionNames?.join(', ')}
          </Typography>
        </Stack>
        <Rating
          name="half-rating"
          defaultValue={0}
          value={review.rating}
          precision={0.5}
          iconSize={review.rating === 0 ? '52px' : '40px'}
          bordered
          onChange={(_, value) => {
            setReview({ ...review, rating: value ?? 0 });
          }}
        />
        {review.rating ? (
          <Stack sx={{ width: '100%', mt: '8px' }} gap={'8px'}>
            <ImageUploader
              baseImageUrls={review.images}
              onImagesChange={(imageUrls) => {
                setReview((prev) => ({ ...prev, images: imageUrls }));
              }}
            />
            <TextField
              value={review.comment}
              fullWidth
              multiline
              rows={8}
              placeholder={i18n.t('shop-product-review-comment-placeholder')}
              onChange={(e) => setReview({ ...review, comment: e.target.value })}
            />
          </Stack>
        ) : null}
        <BackInReviewPopover
          open={cancelReviewPopoverOpen}
          onClose={() => setCancelReviewPopoverOpen(false)}
          onClickOption={(isContinue) => {
            if (isContinue) {
              setCancelReviewPopoverOpen(false);
            } else {
              setCancelReviewPopoverOpen(false);
              navigate(isModify ? '/shop/my-review' : '/shop/orders');
            }
          }}
        />
        <AllDonePopover
          open={allDonePopoverOpen}
          isRemainNotReviewed={otherOrderItems.length > 0}
          onClose={() => setAllDonePopoverOpen(false)}
          onClickReviewAnother={() => {
            setAllDonePopoverOpen(false);
            setOpenItemReviewSelection(true);
          }}
        />
        <OrderItemReviewSelection
          open={openItemReviewSelection}
          onClose={() => setOpenItemReviewSelection(false)}
          orderItems={otherOrderItems}
          orderId={orderId}
        />
      </Stack>
    </Page>
  );
}
