import { useTranslation } from 'react-i18next';
import Container from '~/components/Container';
import Stack from '~/components/Stack';
import Typography from '~/components/Typography';
import useProductReview from '~/hooks/useProductReview';
import { useEffect, useState } from 'react';
import Divider from '~/components/Divider';
import ImageView from '~/components/ImageView';
import dayjs from 'dayjs';
import Button from '~/components/Button';
import Rating from '~/components/Rating';

const INITIAL_PAGE_SIZE = 2;
const PAGE_SIZE = 5;

export default function ProductReviews({
  reviewCount,
  reviewAverageRating,
  productId,
}: {
  reviewCount: number;
  reviewAverageRating: number;
  productId: string;
}) {
  const { i18n } = useTranslation();
  const { productReviews, fetchProductReviews, totalProductReviewsCount } = useProductReview();
  const [page, setPage] = useState(0);

  useEffect(() => {
    const offset = page > 0 ? INITIAL_PAGE_SIZE + (page - 1) * PAGE_SIZE : 0;
    const limit = page === 0 ? INITIAL_PAGE_SIZE : PAGE_SIZE;
    console.log(offset, limit);
    fetchProductReviews(productId, offset, limit);
  }, [productId, page]);

  return (
    <Container py={'9px'}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={'10px'}
        justifyContent={'space-between'}
        px={'20px'}
        py={'9px'}
      >
        <Stack direction={'row'} alignItems={'center'} gap={'4px'}>
          <Typography variant={'BaseM_B'}>{i18n.t('common-review')}</Typography>
          <Typography variant={'BaseM_B'} color={'primary.main'}>
            {reviewCount}
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap={'4px'}>
          <Rating
            name="half-rating"
            value={reviewAverageRating}
            precision={0.1}
            iconSize={'16px'}
            spacing={'2px'}
            readOnly
          />
          <Typography variant={'BaseM_B'}>{reviewAverageRating.toFixed(1)}</Typography>
        </Stack>
      </Stack>
      {productReviews.map((review) => (
        <>
          <Divider type={'line'} color={'fill'} />
          <Stack key={review.id} px={'20px'}>
            <Stack gap={'8px'} sx={{ py: '14px' }}>
              <Typography variant={'BaseS_B'}>{review.reviewerName}</Typography>
              <Typography variant={'Caption'} color={'typography.secondary'}>
                {review.productOptionNames?.join(', ')}
              </Typography>
              <Rating
                name="half-rating"
                value={review.rating}
                precision={0.5}
                iconSize={'16px'}
                spacing={'2px'}
                readOnly
              />
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap={'10px'}>
              {review.images?.map((image) => (
                <ImageView key={image} src={image} width={'80px'} height={'80px'} />
              ))}
            </Stack>
            <Stack gap={'4px'} sx={{ py: '8px' }}>
              <Typography variant={'FootNote'}>{review.comment}</Typography>
              <Typography variant={'Caption'} color={'typography.tertiary'}>
                {dayjs(review.createdAt).format('YYYY.MM.DD')}
              </Typography>
            </Stack>
          </Stack>
        </>
      ))}
      {productReviews.length < totalProductReviewsCount && (
        <Stack alignItems={'center'} gap={'10px'} sx={{ px: '20px', py: '8px' }}>
          <Button
            fullWidth
            size={'small'}
            variant={'contained'}
            color={'fill'}
            onClick={() => setPage(page + 1)}
          >
            {i18n.t('common-view-more')}
          </Button>
        </Stack>
      )}
    </Container>
  );
}
