import api from '../api';
import { ProductBrandResponse } from '../models/ProductBrand';
import { ProductBrand } from '../models/ProductBrand';

const PRODUCT_BRAND_PATH = {
  PRODUCT_BRANDS: () => ['product-brands'].join('/'),
  PRODUCT_BRAND_RANDOM: () => ['product-brands', 'random-display'].join('/'),
  PRODUCT_BRAND_DETAIL: (productBrandId: ProductBrand['id']) =>
    ['product-brands', productBrandId].join('/'),
  LIKE_PRODUCT_BRAND: (productBrandId: ProductBrand['id']) =>
    ['product-brands', productBrandId, 'like'].join('/'),
  LIKED_PRODUCT_BRANDS: () => ['product-brands', 'my-likes'].join('/'),
  LIKED_BRAND_IDS: () => '/likes/by-user?targetType=brand',
};

type GetProductBrandsParams = {
  pageSize: number;
  page: number;
  orderBy?: 'latest' | 'random';
};

export async function getProductBrands(params: GetProductBrandsParams) {
  const resp = await api.get(PRODUCT_BRAND_PATH.PRODUCT_BRANDS(), { params });
  const productBrandsResponse: ProductBrandResponse[] = resp.data.data;
  return productBrandsResponse.map((brand) => new ProductBrand(brand));
}

export async function getProductBrandRandomDisplay() {
  const resp = await api.get(PRODUCT_BRAND_PATH.PRODUCT_BRAND_RANDOM());
  const productBrandResponse: ProductBrandResponse = resp.data.data;
  return new ProductBrand(productBrandResponse);
}

export async function getProductBrandDetail(productBrandId: ProductBrand['id']) {
  const resp = await api.get(PRODUCT_BRAND_PATH.PRODUCT_BRAND_DETAIL(productBrandId));
  const productBrandResponse: ProductBrandResponse = resp.data.data;
  return new ProductBrand(productBrandResponse);
}

export async function likeProductBrand(productBrandId: ProductBrand['id']): Promise<void> {
  await api.post(PRODUCT_BRAND_PATH.LIKE_PRODUCT_BRAND(productBrandId));
}

export async function unlikeProductBrand(productBrandId: ProductBrand['id']): Promise<void> {
  await api.delete(PRODUCT_BRAND_PATH.LIKE_PRODUCT_BRAND(productBrandId));
}

export async function getLikedProductBrands(params: GetProductBrandsParams) {
  const resp = await api.get(PRODUCT_BRAND_PATH.LIKED_PRODUCT_BRANDS(), { params });
  const productBrandResponses: ProductBrandResponse[] = resp.data.data;
  return {
    brands: productBrandResponses.map((brand) => new ProductBrand(brand)),
    meta: resp.data.meta,
  };
}

export async function getLikedBrandIds(): Promise<{ targetId: string; likedAt: string }[]> {
  const resp = await api.get(PRODUCT_BRAND_PATH.LIKED_BRAND_IDS());
  return resp.data.data;
}
