import Page from '~/components/Page';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Tab } from '@mui/material';
import Headline from '~/components/Headline';
import i18n from '~/i18n';
import Tabs from '~/components/Tabs';
import { useEffect, useState } from 'react';
import { theme } from '~/theme';
import LikedBrandList from './components/LikedBrandList';
import Stack from '~/components/Stack';
import LikedProductList from './components/LikedProductList';
import BasketIconWithCount from '~/components/BasketIconWithCount.tsx';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function MyLikes() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [selectedTabIndex, setSelectedTabIndex] = useState(tab === 'product' ? 0 : 1);

  useEffect(() => {
    setSelectedTabIndex(tab === 'product' ? 0 : 1);
  }, [tab]);

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
    navigate(`/shop/likes?tab=${newValue === 0 ? 'product' : 'brand'}`, { replace: true });
  };

  return (
    <Page
      type={'page'}
      navigationBarProps={{
        sx: (theme) => ({
          position: 'sticky',
          top: 0,
          left: 0,
          right: 0,
          background: theme.palette.background.paper,
          borderRadius: 0,
        }),
        trail: (
          <Stack height={'20px'} flexDirection={'row'} gap={'12px'}>
            <BasketIconWithCount />
          </Stack>
        ),
        background: 'transparent',
      }}
    >
      <Stack py={'20px'} gap={'12px'}>
        <Stack px={'20px'} gap={'12px'}>
          <Headline size={'large'} headline={i18n.t('common-like')} />
          <Tabs
            tabLabelVariant={'BaseS'}
            type={'toggle'}
            value={selectedTabIndex}
            onChange={handleChangeTab}
            TabIndicatorProps={{ style: { display: 'none' } }}
            sx={{
              p: '5px',
              width: '100%',
              borderRadius: '10px',
              backgroundColor: '#f5f7fa',
              '& .MuiTab-root': {
                // 모든 탭에 적용되는 스타일
                color: theme.palette.text.disabled,
                borderRadius: '10px',
                border: 'none',
                '&.Mui-selected': {
                  // 선택된 탭에만 적용되는 스타일
                  color: theme.palette.text.primary,
                  fontWeight: 'bold',
                  backgroundColor: 'white',
                  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
                },
              },
            }}
            tabs={[
              {
                label: i18n.t('common-product'),
                value: 0,
              },
              {
                label: i18n.t('common-brand'),
                value: 1,
              },
            ]}
          >
            <Tab label={'product'} {...a11yProps(0)} />
            <Tab label={'brand'} {...a11yProps(1)} />
          </Tabs>
        </Stack>
        <CustomTabPanel value={selectedTabIndex} index={0}>
          <LikedProductList />
        </CustomTabPanel>
        <CustomTabPanel value={selectedTabIndex} index={1}>
          <LikedBrandList />
        </CustomTabPanel>
      </Stack>
    </Page>
  );
}
