import { ProductReview } from '~/services/kup/models/ProductReview';
import { useState } from 'react';
import { getProductReviews } from '~/services/kup/controllers/productReview';
import { getMyProductReviews } from '~/services/kup/controllers/productReview';

type UseProductReview = {
  productReviews: ProductReview[];
  myProductReviews: ProductReview[];
  totalProductReviewsCount: number;
  fetchMyReviews: () => Promise<void>;
  fetchProductReviews: (productId: string, offset: number, limit: number) => Promise<void>;
  deleteProductReview: (productReviewId: string) => Promise<void>;
  loading: boolean;
};

export default function useProductReview(): UseProductReview {
  const [productReviews, setProductReviews] = useState<ProductReview[]>([]);
  const [myProductReviews, setMyProductReviews] = useState<ProductReview[]>([]);
  const [totalProductReviewsCount, setTotalProductReviewsCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchMyReviews = async () => {
    setLoading(true);
    const productReviews = await getMyProductReviews();
    setMyProductReviews(productReviews);
    setLoading(false);
  };

  const fetchProductReviews = async (productId: string, offset: number, limit: number) => {
    setLoading(true);
    const { productReviews: fetchedProductReviews, totalCount } = await getProductReviews(
      productId,
      {
        offset,
        limit,
      }
    );
    setTotalProductReviewsCount(totalCount);
    if (offset === 0) {
      setProductReviews(fetchedProductReviews);
    } else {
      setProductReviews([...productReviews, ...fetchedProductReviews]);
    }
    setLoading(false);
  };

  const deleteProductReview = async (productReviewId: string) => {
    await deleteProductReview(productReviewId);
  };

  return {
    productReviews,
    myProductReviews,
    totalProductReviewsCount,
    loading,
    fetchMyReviews,
    fetchProductReviews,
    deleteProductReview,
  };
}
