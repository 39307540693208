import useKupStore from '~kup/store';
import { useLayoutEffect, useState } from 'react';
import { getLikedProductIds } from '~kup/controllers/product.ts';
import { getLikedBrandIds } from '~kup/controllers/productBrand.ts';

export default function useSyncLike(): boolean {
  const [isSynced, setIsSynced] = useState<boolean>(false);
  const { setProductLikeDict, setBrandLikeDict } = useKupStore((state) => ({
    setProductLikeDict: state.setProductLikeDict,
    setBrandLikeDict: state.setBrandLikeDict,
  }));
  useLayoutEffect(() => {
    getLikedProductIds()
      .then((productData) => {
        const productLikeDict = Object.fromEntries(
          productData?.map((item) => {
            const id = item.targetId;
            const date = new Date(item.likedAt ?? Date.now());
            return [id, date];
          })
        );
        setProductLikeDict(productLikeDict);
      })
      .catch(console.error);

    getLikedBrandIds()
      .then((brandData) => {
        const brandLikeDict = Object.fromEntries(
          brandData?.map((item) => {
            const id = item.targetId;
            const date = new Date(item.likedAt ?? Date.now());
            return [id, date];
          })
        );
        setBrandLikeDict(brandLikeDict);
      })
      .catch(console.error);
    setIsSynced(true);
  }, [setProductLikeDict, setBrandLikeDict]);
  return isSynced;
}
