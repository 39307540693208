import Button from '~/components/Button';
import Grid from '@mui/material/Grid';
import Popover from '~/components/ModalPopover.tsx';
import { useTranslation } from 'react-i18next';
import Stack from '~/components/Stack';
import Typography from '~/components/Typography';

export default function BackInReviewPopover({
  open,
  onClose,
  onClickOption,
}: {
  open: boolean;
  onClose: () => void;
  onClickOption: (isContinue: boolean) => void;
}) {
  const { i18n } = useTranslation();

  return (
    <Popover
      open={open}
      onClose={onClose}
      fixedBottom={
        <Grid container sx={{ px: '20px', pb: '16px' }}>
          <Grid item xs={3} sx={{ pr: '4px' }}>
            <Button
              fullWidth
              variant={'contained'}
              color={'fill'}
              size={'small'}
              onClick={() => onClickOption(false)}
            >
              {i18n.t('common-cancel')}
            </Button>
          </Grid>
          <Grid item xs={9} sx={{ pl: '4px' }}>
            <Button
              fullWidth
              variant={'contained'}
              size={'small'}
              onClick={() => onClickOption(true)}
            >
              {i18n.t('common-yes-continue')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Stack px={'20px'} py={'24px'} gap={'10px'}>
        <Typography variant={'TitleS_B'}>
          {i18n.t('shop-product-review-request-continue')}
        </Typography>
        <Typography variant={'FootNote'} color={'typography.secondary'}>
          {i18n.t('shop-product-review-request-continue-description')}
        </Typography>
      </Stack>
    </Popover>
  );
}
