import { Info } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Area from '~/components/Area';
import Button from '~/components/Button';
import Spotlight from '~/components/Spotlight';
import Stack from '~/components/Stack';
import Typography from '~/components/Typography';
import usePossessionCoupons from '~/hooks/usePossessionCoupons';
import i18n from '~/i18n';
import { theme } from '~/theme';

export default function CouponsInPossession() {
  const { possessionCoupons } = usePossessionCoupons();

  const emptyCouponSpotlight = (
    <Spotlight
      textAlign={'center'}
      py={'15vh'}
      lead={<Info sx={{ fontSize: '64px', color: 'typography.tertiary' }} />}
      headline={i18n.t('shop-no-coupon-in-possession')}
      description={i18n.t('shop-no-coupon-in-possession-guide')}
      descriptionProps={{
        whiteSpace: 'pre-wrap',
      }}
      trail={
        <Stack justifyContent={'center'} alignItems={'center'} gap={5} sx={{ px: '20px' }}>
          <Button
            component={Link}
            to={'/shop/coupons?tab=get-it'}
            size="small"
            variant="contained"
            color="fill"
            sx={{ minWidth: '180px' }}
          >
            {i18n.t('common-going-to-get-it')}
          </Button>
        </Stack>
      }
    />
  );

  return (
    <Area
      title={<Typography variant={'FootNote'}>{possessionCoupons.length} coupons</Typography>}
      containerProps={{ stackProps: { gap: '6px', sx: { px: '20px' } } }}
      background={'paper'}
    >
      {possessionCoupons.length === 0
        ? emptyCouponSpotlight
        : possessionCoupons.map((possessedCoupon) => (
            <Stack
              key={possessedCoupon.id}
              gap={2}
              sx={{ py: '12px', border: `1px solid ${theme.palette.divider}`, borderRadius: '8px' }}
            >
              <Stack
                row
                gap={'14px'}
                alignItems={'center'}
                justifyContent={'space-between'}
                sx={{ px: '20px' }}
              >
                <Stack column gap={'4px'} sx={{ width: '100%' }}>
                  <Typography variant={'BaseM_B'}>{possessedCoupon.coupon.name}</Typography>
                  <Typography
                    variant={'FootNote'}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 1,
                    }}
                  >
                    {possessedCoupon.coupon.description}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          ))}
    </Area>
  );
}
