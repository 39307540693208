import Grid from '@mui/material/Grid';
import Stack from '~/components/Stack.tsx';
import ImageView from '~/components/ImageView.tsx';
import Typography from '~/components/Typography.tsx';
import useProductBrands from '~/hooks/useProductBrands.ts';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ProductBrand } from '~kup/models/ProductBrand.ts';
import IconButton from '~/components/IconButton.tsx';
import { Skeleton } from '@mui/material';

export default function BrandCollection() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { productBrands } = useProductBrands(9);

  const moveToBrandDetail = (brand: ProductBrand) => {
    navigate(`/shop/brand/${brand.id}`);
  };

  const moveToBrandList = () => {
    navigate('/shop/brand');
  };

  const brandSkeleton = (
    <Grid container spacing={2}>
      {new Array(9).fill(0).map((_, idx) => (
        <Grid item xs={4} key={idx}>
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <Skeleton variant="circular" width={100} height={100} />
            <Skeleton variant="text" width={100} height={28} />
          </Stack>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <>
      <Stack px={'20px'} py={'20px'} gap={'20px'}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="BaseM_B">{i18n.t('home-main-k-beauty-leading-brands')}</Typography>
          <IconButton
            iconProps={{ size: '2rem', color: 'typography.tertiary' }}
            onClick={moveToBrandList}
          >
            chevron_right
          </IconButton>
        </Stack>
        {productBrands.length > 0 ? (
          <Grid container spacing={2}>
            {productBrands.map((productBrand) => (
              <Grid
                item
                xs={4}
                key={productBrand.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <ImageView
                  src={productBrand.logo}
                  width={'100%'}
                  borderRadius={'50%'}
                  onClick={() => moveToBrandDetail(productBrand)}
                />
                <Typography variant="BaseM">{productBrand.name}</Typography>
              </Grid>
            ))}
          </Grid>
        ) : (
          brandSkeleton
        )}
      </Stack>
    </>
  );
}
