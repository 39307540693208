import { useEffect, useState } from 'react';
import { getProducts } from '~/services/kup/controllers/product';
import { getLikedProductBrands } from '~/services/kup/controllers/productBrand';
import { ProductBrand } from '~/services/kup/models/ProductBrand';

type UseProductBrands = {
  productBrands: ProductBrand[];
  loading: boolean;
  fetchLikedProductBrands: () => void;
};

export default function useLikedProductBrands(size: number = 100): UseProductBrands {
  const [productBrands, setProductBrands] = useState<ProductBrand[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchLikedProductBrands = () => {
    getLikedProductBrands({ pageSize: size, page: 1 })
      .then(({ brands }) => {
        Promise.all(
          brands.map(async (productBrand) => {
            const { products } = await getProducts({
              pageSize: 100,
              page: 1,
              brand: productBrand.slug,
            });
            return { ...productBrand, products: products };
          })
        ).then((productBrands) => {
          setProductBrands(productBrands);
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchLikedProductBrands();
  }, []);

  return { productBrands, loading, fetchLikedProductBrands };
}
