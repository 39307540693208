import api from '../api';
import { ProductReview, ProductReviewResponse } from '../models/ProductReview';

const PRODUCT_REVIEW_PATH = {
  PRODUCT_REVIEWS: () => ['product-reviews'].join('/'),
  PRODUCT_REVIEW: (productReviewId: string) => ['product-reviews', productReviewId].join('/'),
  PRODUCT_REVIEW_MINE: () => ['product-reviews', 'mine'].join('/'),
  PRODUCT_REVIEW_BY_PRODUCT: (productId: string) =>
    ['product-reviews', 'by-product', productId].join('/'),
};

type GetProductReviewsParams = {
  offset: number;
  limit: number;
};

export async function getProductReviews(
  productId: string,
  params: GetProductReviewsParams
): Promise<{ productReviews: ProductReview[]; totalCount: number }> {
  const resp = await api.get(PRODUCT_REVIEW_PATH.PRODUCT_REVIEW_BY_PRODUCT(productId), {
    params,
  });
  return {
    productReviews: resp.data.data.map((raw: ProductReviewResponse) => new ProductReview(raw)),
    totalCount: resp.data.meta.totalCount,
  };
}

export async function getMyProductReviews(): Promise<ProductReview[]> {
  const resp = await api.get(PRODUCT_REVIEW_PATH.PRODUCT_REVIEW_MINE(), {
    params: { offset: 0, limit: 500 },
  });
  return resp.data.data.map((raw: ProductReviewResponse) => new ProductReview(raw));
}

export async function writeProductReview(productReview: ProductReview): Promise<void> {
  await api.put(PRODUCT_REVIEW_PATH.PRODUCT_REVIEWS(), productReview);
}

export async function deleteProductReview(productReviewId: string): Promise<void> {
  await api.delete(PRODUCT_REVIEW_PATH.PRODUCT_REVIEW(productReviewId));
}
