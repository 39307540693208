import { useState } from 'react';
import useKupStore from '~kup/store';
import { Address } from '~kup/models/types.ts';
import Page from '~/components/Page';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from '~/components/Container.tsx';
import Headline from '~/components/Headline.tsx';
import TextField, { TextFieldProps } from '~/components/TextField.tsx';
import Typography from '~/components/Typography.tsx';
import Button from '~/components/Button.tsx';
import Slide from '~/components/Slide';
import NaverMap from '~/components/NaverMap.tsx';
import { useTranslation } from 'react-i18next';
import FillBox from '~/components/FillBox';

export default function ShopAddressSearchDetailPage() {
  const { deliveryAddress, setDeliveryAddress, setInputAddress } = useKupStore((state) => ({
    deliveryAddress: state.deliveryAddress,
    setDeliveryAddress: state.setDeliveryAddress,
    setInputAddress: state.setInputAddress,
  }));
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  const input = location?.state?.inputAddress as string;
  const address = location?.state?.address as Address;
  const [detail, setDetail] = useState<string>('');
  const [request, setRequest] = useState<string>('');
  const [step, setStep] = useState<0 | 1>(0);
  const center = {
    lat: Array.isArray(address.location) ? address.location[0].latitude : address.location.latitude,
    lng: Array.isArray(address.location)
      ? address.location[0].longitude
      : address.location.longitude,
  };

  const handleChangeDetail: TextFieldProps['onChange'] = (e) => {
    setDetail(e.target.value);
  };

  const handleChangeRequest: TextFieldProps['onChange'] = (e) => {
    setRequest(e.target.value);
  };

  const handleNext = () => {
    setStep(1);
  };

  const handleConfirm = () => {
    setDeliveryAddress({
      ...deliveryAddress,
      detail,
      request,
    });
    setInputAddress(input);
    navigate(-3);
  };

  return (
    <Page
      type={'modal'}
      fixedBottom={
        <Container
          bottom
          background={'paper'}
          pt={'8px'}
          sx={{
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
          }}
        >
          {step === 0 && (
            <Slide in={step === 0} direction={'left'} unmountOnExit mountOnEnter>
              <div style={{ zIndex: 200 }}>
                <Container py={'12px'} px={'20px'}>
                  <Headline
                    headline={address.displayName.text}
                    description={address.formattedAddress}
                  />
                </Container>
                <Container
                  stackProps={{
                    column: true,
                    gap: '8px',
                  }}
                  py={'8px'}
                  px={'20px'}
                >
                  <TextField
                    fullWidth
                    placeholder={i18n.t('shop-address-enterHere')}
                    onChange={handleChangeDetail}
                  />
                </Container>
                <Container
                  p={'8px'}
                  px={'20px'}
                  stackProps={{
                    gap: '16px',
                    justifyContent: 'center',
                  }}
                >
                  <FillBox color={'fill'} display={'flex'} flexDirection={'column'}>
                    <Typography variant={'BaseM_B'} component={'p'}>
                      {i18n.t('common-notice')}
                    </Typography>
                    <Typography variant={'FootNote'} component={'p'}>
                      • {i18n.t('shop-delivery-confirmReservationName')}
                    </Typography>
                    <Typography variant={'FootNote'} component={'p'}>
                      • {i18n.t('shop-delivery-hotelReceptionDelivery')}
                    </Typography>
                  </FillBox>
                </Container>
                <Container py={'8px'} px={'20px'}>
                  <Button fullWidth onClick={handleNext}>
                    {
                      i18n.t('shop-address-next') // Next
                    }
                  </Button>
                </Container>
              </div>
            </Slide>
          )}
          <Slide in={step > 0} direction={'left'} unmountOnExit mountOnEnter>
            <div style={{ zIndex: 200 }}>
              <Container py={'12px'} px={'20px'}>
                <Headline
                  headline={
                    i18n.t('shop-address-provideDeliveryInstructions') // Instructions for delivery
                  }
                  description={i18n.t('shop-delivery-mainEntrancePasscode')}
                  descriptionProps={{ color: 'error', variant: 'FootNote_B' }}
                />
              </Container>
              <Container
                stackProps={{
                  column: true,
                  gap: '8px',
                }}
                py={'8px'}
                px={'20px'}
              >
                <TextField
                  fullWidth
                  placeholder={i18n.t('shop-delivery-entrancePasswordInfo')}
                  onChange={handleChangeRequest}
                />
              </Container>
              <Container
                p={'8px'}
                px={'20px'}
                stackProps={{
                  gap: '16px',
                  justifyContent: 'center',
                }}
              >
                <FillBox color={'fill'} display={'flex'} flexDirection={'column'}>
                  <Typography variant={'BaseM_B'} component={'p'}>
                    {i18n.t('common-notice')}
                  </Typography>
                  <Typography variant={'FootNote'} component={'p'}>
                    • {i18n.t('shop-delivery-deliverySeoulOnly')}
                  </Typography>
                  <Typography variant={'FootNote'} component={'p'}>
                    • {i18n.t('shop-delivery-airbnbPasscode')}
                  </Typography>
                  <Typography variant={'FootNote'} component={'p'}>
                    • {i18n.t('shop-delivery-addressClarification')}
                  </Typography>
                  <Typography variant={'FootNote'} component={'p'}>
                    • {i18n.t('shop-delivery-cancellationIfUndeliverable')}
                  </Typography>
                </FillBox>
              </Container>
              <Container py={'8px'} px={'20px'}>
                <Button fullWidth onClick={handleConfirm}>
                  {
                    i18n.t('shop-address-confirm') // Confirm
                  }
                </Button>
              </Container>
            </div>
          </Slide>
        </Container>
      }
    >
      <Container>
        <NaverMap
          center={center}
          zoom={13}
          placeName={address.displayName.text}
          containerStyles={{ width: '100%', height: 'calc(100vh - 250px)' }}
        />
      </Container>
    </Page>
  );
}
