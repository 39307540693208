import { ReactNode, createContext, useContext } from 'react';
import {
  Product,
  ProductOption,
  ProductStock,
  ProductOptionSelection,
  ProductOptionItem,
} from '~kup/models/Product.ts';

export interface ProductContext {
  product: Product | null;
  productOptions: ProductOption[];
  productStock: ProductStock | null;
  productSelections: ProductOptionSelection[];
  total: number;
  onProductOptionSelectionsCommit: (productOptionSelections: ProductOptionItem[]) => void;
  setProductSelections: (
    productSelectionsOrProductSelectionsSetter:
      | ProductOptionSelection[]
      | ((productSelections: ProductOptionSelection[]) => ProductOptionSelection[])
  ) => void;
}

export type ProductContextProviderProps = {
  children: ReactNode;
};

export const ProductContext = createContext<ProductContext | null>(null);

export function useProductContext(): ProductContext {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error('useProductContext must be used within a ProductContext Provider');
  }
  return context;
}
