import ProductList from '~/components/ProductList';
import useProductsKeepState from '~/hooks/useProductsKeepState';
import i18n from '~/i18n';
import useBatchLogger from '~/hooks/useBatchLogger';
import { extractProductEventData } from '~/utils/product';
import { useNavigate } from 'react-router-dom';
import { Product } from '~/services/kup/models/Product';
import { PRODUCT_ORDER_BY } from '~/pages/shop/constants';
import { useState } from 'react';

export default function BestPriceProducts() {
  const { logAction } = useBatchLogger();
  const navigate = useNavigate();

  const [orderBy] = useState(
    PRODUCT_ORDER_BY.filter(
      (orderBy) => orderBy.value === 'discountRate:DESC' || orderBy.value === 'price:ASC'
    )[Math.floor(Math.random() * 2)]
  );

  const { products, page, hasNextPage, next } = useProductsKeepState(
    'home:products',
    {
      category: 0,
      orderBy: orderBy.value.split(':')[0] ?? undefined,
      orderMethod: orderBy.value.split(':')[1] ?? undefined,
    },
    {
      startPage: 1,
      pageSize: 8,
      continuous: true,
    }
  );

  const loadNext = () => {
    if (hasNextPage && page < 7) {
      logAction({
        eventType: 'LOAD',
        eventTarget: 'HOME_PRODUCT_LIST',
        eventCategory: 'product',
        eventData: {
          name: i18n.t('home-main-grabBestPrice'),
          page: page + 1,
          orderBy: 'recommendation',
        },
      });
      next();
    }
  };

  const handleClickProductItem = (product: Product) => {
    logAction({
      eventType: 'CLICK',
      eventTarget: 'HOME_PRODUCT',
      eventCategory: 'product',
      eventData: extractProductEventData(product, {
        type: 'main',
        name: i18n.t('home-main-grabBestPrice'),
      }),
    });
  };

  const handleLikeProduct = (product: Product) => {
    if (!product.isLiked) {
      logAction({
        eventType: 'CLICK',
        eventTarget: 'HOME_PRODUCT_LIKE',
        eventCategory: 'product',
        eventData: extractProductEventData(product, {
          type: 'main',
          name: i18n.t('home-main-grabBestPrice'),
        }),
      });
    }
  };

  return (
    <ProductList
      type={'pair'}
      title={i18n.t('home-main-grabBestPrice')}
      products={products}
      onInfiniteScroll={loadNext}
      onClickLinkMore={() => navigate('/shop')}
      onClickProductItem={handleClickProductItem}
      onLikeProductItem={handleLikeProduct}
    />
  );
}
