import { useCallback, useLayoutEffect, useState } from 'react';
import { getLikedProducts } from '~kup/controllers/product.ts';
import { Product } from '~kup/models/Product.ts';
import throttle from 'lodash/throttle';

type PageNumber = number; // 1 보다 크거나 같은 정수;

type UseProducts = {
  products: Product[];
  hasNextPage: boolean;
  page: number;
  next: (targetPage?: number) => void;
  prev: () => void;
  total: number;
};

type ProductsPagination = {
  startPage?: PageNumber;
  pageSize?: number;
  continuous?: boolean;
  throttleDuration?: number;
};

export default function useLikedProducts(pagination: ProductsPagination = {}): UseProducts {
  const { startPage = 1, pageSize = 8, continuous = true, throttleDuration = 600 } = pagination;
  const [currentPage, setCurrentPage] = useState<PageNumber>(startPage);
  const [products, setProducts] = useState<Product[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);

  const callProducts = useCallback(
    (page: PageNumber, pageSize: number, continuous: boolean) => {
      getLikedProducts({ pageSize, page }).then(({ products, hasNextPage, meta }) => {
        setProducts((prev) => {
          if (continuous && page !== startPage) {
            return [...prev, ...products];
          }
          return [...products];
        });
        setHasNextPage(hasNextPage);
        setCurrentPage(page);
        setTotal(meta.totalCount);
      });
    },
    [startPage]
  );

  const next = useCallback(
    throttle((targetPage?: number) => {
      if (targetPage) {
        callProducts(targetPage, pageSize, continuous);
        return;
      }
      if (!hasNextPage) return;
      callProducts(currentPage + 1, pageSize, continuous);
    }, throttleDuration),
    [hasNextPage, callProducts, currentPage, pageSize, continuous]
  );

  const prev = useCallback(
    throttle(() => {
      const hasPrev = currentPage - 1 > 1;
      if (!hasPrev) return;
      callProducts(currentPage - 1, pageSize, continuous);
    }, throttleDuration),
    [currentPage, callProducts, pageSize, continuous]
  );

  useLayoutEffect(() => {
    if (currentPage === startPage)
      //최초 초기화 콜 무조건 실행할 경우 keepState가 무의미함
      callProducts(startPage, pageSize, continuous);
  }, [startPage, pageSize, continuous, callProducts, currentPage]);

  return {
    products,
    hasNextPage,
    page: currentPage,
    total,
    next,
    prev,
  };
}
